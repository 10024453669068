import React from "react";
import WhySection from "../Home/WhySection";
import HowSection from "../Home/HowSection";
import WhatSection from "../Home/WhatSection";
import Buttons from "../Home/Buttons";
import { Helmet } from "react-helmet-async";

const About = () => {
  return (
    <div>
      <Helmet>
        <title>À propos | JULES Agence Digitale</title>
        <meta
          name="description"
          content="Apprenez à connaître Jules Digital, une agence à Sutton, dédiée à la réussite de votre entreprise en ligne. Découvrez notre passion pour les expériences digitales."
        />
        <link rel="canonical" href="https://www.julesdigital.com/about" />
      </Helmet>
      <WhySection />

      <HowSection />
      <WhatSection />
      <Buttons />
    </div>
  );
};

export default About;
