import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
    

}
html{
    scroll-behavior: smooth;
}
body{
    font-family: "League Spartan", serif;
    font-weight: 300;
    overflow-x: hidden;
    font-size: 18px;
    line-height: 24px;
    background: #f7f7f7;
    --main-color: #C38D5E;
    --second-color: #C0A998;
    --third-color: #ffffff;
    --fourth-color: #5A5855;
    --fifth-color: #155f2c;
    --sixth-color: #0045FA;
    --text-color: #000000;
    --second-text-color: #5B412F;
    --small: 18px;
    @media (max-width: 992px) {
        --small: 16px;
    }
    --medium: calc(var(--small) * 1.618);
    --large: calc(var(--medium) * 1.618);
}
a {
    text-decoration: none;
    color: inherit;
}
h1{
    font-size: calc(1.2em + 0.8vw);
    padding: 0 5vw;
    padding-bottom: 6vh;
    font-family: "Josefin Sans", serif;
    font-weight: 400;
}
h2{
    font-size: var(--large);
}
h3{
    color: var(--text-color);
    font-family: "Josefin Sans", serif;
    font-size: var(--medium);
    font-weight: 400;
}
p{
    font-size: var(--small);
      line-height: 150%;
      @media (max-width: 992px) {
        text-align: start;
      }
}
li{
    font-size: var(--small);
    line-height: calc(16px + 0.8vw);

}
span{
    color: var(--fifth-color);
}
button{
    font-family: "Josefin Sans", serif;
    font-size: calc(1em + 0.4vw);
    width: 15rem;
    height: 5rem;
    border-radius: 2.5rem;
    border: none;
    color: white;
    background: var(--fifth-color);
    margin: 0 auto;
    cursor: pointer;
    :hover{
        background: #d09764;
        transition: 0.2s;
        color: var(--text-color);
    }
}
`;

export default GlobalStyles;
