import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Flowers from "../components/Flowers";
import { useStateContext } from "../useGlobalContext";

const Buttons = () => {
  const { setFlowerAnim, french } = useStateContext();
  return (
    <ButtonsWrapper>
      <StickyWrapper
        whileInView={() => setFlowerAnim(true)}
        onViewportLeave={() => setFlowerAnim(false)}
        viewport={{ amount: 0.6 }}
      >
        <Link to="/contact">
          <motion.button className="smaller-button">
            <span>{french ? "Contactez nous!" : "Contact us!"}</span>
          </motion.button>
        </Link>
        <div className="flowers">
          <Flowers />
        </div>
      </StickyWrapper>
    </ButtonsWrapper>
  );
};

export default Buttons;

const ButtonsWrapper = styled.section`
  width: 100vw;
  height: 100vh;
`;
const StickyWrapper = styled(motion.div)`
  position: sticky;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .smaller-button {
    @media (max-width: 992px) {
      width: 10rem;
      height: 4rem;
    }
    width: 15rem;
    padding: 0;
  }
  .flowers {
    position: absolute;
    z-index: -1;
  }
  button {
    margin: 0 auto;
    position: relative;
    padding: 8px 64px;
    border-radius: 999px;
    color: white;
    font-size: var(--small);
    overflow: hidden;
    background: var(--sixth-color);
    span {
      z-index: 10;
      color: white;
      position: relative;
    }

    :hover:after {
      scale: 1 1;
      transform-origin: right;
      transition-delay: 0s;
      transition: scale 0.3s ease;
    }
    :after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: var(--main-color);
      z-index: 0;
      transition: scale 1s ease;
      transition-delay: 0.3s;
      scale: 0 1;
      transform-origin: left;
    }
  }
`;
