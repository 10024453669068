import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useStateContext } from "../useGlobalContext";

const Flowers = () => {
  const { flowerAnim } = useStateContext();
  const staggerAnim = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: { delay: 5, staggerChildren: 0.15 },
    },
  };
  const containerAnim = {
    hidden: { opacity: 1 },
    show: { opacity: 1, transition: { duration: 1 } },
  };
  const flowersAnim = {
    hidden: { scale: 0 },
    show: {
      scale: [null, 0, 0, 0, 0, 0, 0, 0, 0, 1],
      transition: { duration: 3.2, type: "spring", bounce: 0.5 },
    },
  };
  const linesAnim = {
    hidden: { pathLength: 0 },
    show: {
      pathLength: [null, 1],
      transition: { duration: 3 },
    },
  };
  return (
    <FlowerWrapper>
      <motion.svg
        id="Calque_1"
        data-name="Calque 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 688.51 688.97"
      >
        <motion.g
          id="branche"
          initial="hidden"
          animate={flowerAnim ? "show" : ""}
          variants={containerAnim}
        >
          <motion.path
            className="cls-2"
            d="m348.91,345.68c-10.65-36.11-31.48-69.16-59.46-94.35-13.49-12.14-28.63-22.54-40.74-36.06s-21.13-31.29-18.87-49.3c2.55-20.34,18.8-36.41,36.45-46.83s37.45-16.93,54.69-28.03c17.24-11.09,32.37-28.67,32.58-49.17"
            variants={linesAnim}
          />
          <motion.path
            className="cls-2"
            d="m348.84,347.66c34.73-56.32,35.7-126.62,31.93-192.67-2.11-36.99-4.42-77.51,17.53-107.36"
            variants={linesAnim}
          />
          <motion.path
            className="cls-2"
            d="m350.34,345.81c31.08-9.86,57.98-32.28,73.27-61.09,15.29-28.81,18.78-63.65,9.52-94.92-4.45-15.03-11.68-29.16-15.48-44.37s-3.65-32.51,5.61-45.16c8.15-11.13,21.87-16.7,35.3-19.91s27.39-4.62,40.11-9.95"
            variants={linesAnim}
          />
          <motion.path
            className="cls-2"
            d="m350.31,352.62c16.11-10.59,37.47-4.49,56.6-6.87,23.98-2.99,44.2-19.64,59.11-38.66,14.91-19.02,25.87-40.84,40.42-60.14,14.55-19.3,33.98-36.62,57.75-40.95"
            variants={linesAnim}
          />
          <motion.path
            className="cls-2"
            d="m353.55,352.21c55.39-6.97,110.59,31.68,164.92,18.83,27.22-6.44,49.66-24.93,73.07-40.26,23.41-15.32,51.45-28.24,78.61-21.53"
            variants={linesAnim}
          />
          <motion.path
            className="cls-2"
            d="m353.04,354.11c25.08-3.81,48.22,14.33,63.96,34.23,15.73,19.89,28.35,43.23,49.46,57.29,24.18,16.1,55.98,17.07,83.98,9.34,28-7.74,53.13-23.24,77.82-38.56"
            variants={linesAnim}
          />
          <motion.path
            className="cls-2"
            d="m358.81,344.67c31.71-15.39,63.93-31.13,89.81-55.06,21.37-19.77,37.57-44.36,53.54-68.7,20.07-30.56,40.13-61.12,60.2-91.68"
            variants={linesAnim}
          />
          <motion.path
            className="cls-2"
            d="m348.84,353.6c35.69,6.46,58.99,43.18,75.66,75.39,16.67,32.22,30.56,67.54,47.99,99.35,17.43,31.81,46.05,60.91,82.12,64.81"
            variants={linesAnim}
          />
          <motion.path
            className="cls-2"
            d="m351.18,355.86c20.45,9.04,35.84,28.69,39.73,50.7s-3.85,45.75-19.96,61.24c-15.06,14.49-36.32,21.72-49.89,37.61-15.03,17.6-17.75,43.75-9.86,65.51s25.19,39.23,45.24,50.78c20.05,11.55,42.79,17.66,65.57,21.71"
            variants={linesAnim}
          />
          <motion.path
            className="cls-2"
            d="m310.91,522.99c-4.02,10.18,3.53,22.61,11.83,29.74s19.43,10.07,30.32,11.2,21.9.66,32.76,2.04,21.91,4.87,29.69,12.56"
            variants={linesAnim}
          />
          <motion.path
            className="cls-2"
            d="m349.51,353.77c-27.46,27.45-55.35,55.49-73.2,89.97-36.09,69.74-22.6,161.83,31.97,218.29"
            variants={linesAnim}
          />
          <motion.path
            className="cls-2"
            d="m346.1,354.11c-30.16,6.94-61.23,14.29-86.31,32.42-25.08,18.14-42.88,50.04-35.09,80,2.12,8.14,5.99,15.76,7.77,23.98s1.05,17.71-4.86,23.69c-5.87,5.94-15.44,6.81-23.41,4.32s-14.73-7.75-21.29-12.92c-22.35-17.59-44.71-35.19-67.06-52.78"
            variants={linesAnim}
          />
          <motion.path
            className="cls-2"
            d="m342.85,349.61c-49.08,6.03-97.85-9.95-145.16-24.35-47.31-14.4-97.6-27.5-145.66-15.83-15.07,3.66-31.49,11.89-34.81,27.04"
            variants={linesAnim}
          />
          <motion.path
            className="cls-2"
            d="m346.01,350.03c-24.22-26.81-57.73-44-92.62-53.42-34.88-9.42-71.31-11.56-107.44-11.96-18.12-.2-39.19-1.41-49.67-16.2"
            variants={linesAnim}
          />
          <motion.path
            className="cls-2"
            d="m343.58,351.57c-18.4-36.43-48.11-67.05-83.97-86.54-19.76-10.74-41.26-18.16-60.37-30.03s-36.28-29.54-40.19-51.68"
            variants={linesAnim}
          />
        </motion.g>
        <motion.g
          initial="hidden"
          animate={flowerAnim ? "show" : ""}
          variants={staggerAnim}
        >
          <motion.g id="fleur_2" data-name="fleur 2" variants={flowersAnim}>
            <path
              className="cls-4"
              d="m379.48,119.56c1.04-3.66,3.57-7.01,6.07-9.25.34-.31.73-.61,1.19-.62.6-.01,1.1.5,1.29,1.06s.14,1.18.1,1.78c-.29,4.63.49,9.33,2.26,13.62.33.81,1.01,1.77,1.85,1.53.71-.2.91-1.08,1-1.81.54-4.75,1.08-9.49,1.63-14.24.3,4.66.87,9.86,4.46,12.84.95.78,2.3,1.37,3.39.8,1.08-.56,1.38-1.97,1.33-3.19-.11-2.48-1.22-4.9-3.03-6.59,1.27,2.32,2.95,4.41,4.93,6.15.91.8,2.04,1.57,3.24,1.39,1.7-.25,2.57-2.36,2.16-4.02s-1.7-2.95-2.93-4.14c-2.28-2.2-4.55-4.41-6.83-6.61,1,.98,2.65,1.02,3.91.42s2.19-1.73,2.95-2.9c.42-.65.81-1.34.9-2.11s-.18-1.62-.83-2.03c-.6-.38-1.37-.3-2.08-.22-2.25.27-4.51.55-6.76.82,3.72-2.54,6.74-6.09,8.65-10.17.29-.62.56-1.28.53-1.96s-.43-1.4-1.09-1.58c-.77-.21-1.52.33-2.13.84-1.61,1.34-3.22,2.69-4.82,4.03,1.53-5.68,2.17-11.59,1.88-17.47-.03-.6-.1-1.28-.56-1.67-.84-.73-2.15.07-2.81.97-1.94,2.62-2.31,6.08-2.12,9.33s.84,6.48.68,9.74c-.06,1.28-.73,2.94-1.99,2.74-1.03-.16-1.4-1.43-1.54-2.47-.46-3.33-.92-6.66-1.38-9.99-.15-1.11-.67-2.54-1.79-2.46-.49.04-.9.39-1.21.77-1.82,2.24-1.33,5.55-.33,8.25s2.45,5.4,2.24,8.28c-.19,2.53-1.64,4.79-3.31,6.7s-3.59,3.62-5.02,5.71-3.92,5.24-4.09,7.72Z"
            />
            <circle className="cls-5" cx="393.44" cy="105.51" r="1.53" />
            <g>
              <g>
                <g>
                  <path
                    className="cls-2"
                    d="m331.61,211.46l-19.12-15.62c-1.04-.85-1.71-2.11-1.68-3.45.05-2.54,2.44-3.16,4.22-2.56,1.25.42,2.27,1.31,3.25,2.19,4.48,3.98,8.96,7.96,13.43,11.93-.53-5.42.1-10.96,1.86-16.12.24-.72.77-1.33,1.47-1.6,1.83-.7,3.01,1.24,3.34,2.9.8,3.99-.31,8.08-.53,12.14-.2,3.74.69,8.01,3.63,10.16.18.13.4-.09.28-.27-1.09-1.7-2.05-3.57-1.7-5.52.4-2.24,3.64-3.82,5.02-2.01l-1.87-27.57c-.11-1.59.8-3.14,2.32-3.65,2.63-.88,4.24,2.21,4.69,4.71,1.14,6.3.66,12.87-1.36,18.94-.63,1.88-1.41,3.77-1.36,5.75s1.26,4.11,3.21,4.46c-.42-2.63-.75-5.65,1.71-7.39,1.47-1.05,3.35-.63,3.95,1.08.52,1.48-.6,2.68-1.49,3.79-2.3,2.9-3.37,6.73-2.97,10.4.06.53.71.75,1.08.38,3.07-3.09,5.15-7.48,8.88-9.95.61-.4,1.35-.58,2.05-.38,1.75.5,1.59,2.62,1.06,4.14-2.04,5.89-7.35,10.6-12,13.76-.2.14-.25.42-.08.61,1.59,1.75,4.13,2.39,6.51,2.67,2.92.35,6.01.38,8.44,2.19,1.12.84,1.94,2.07,2.13,3.46.41,3.04-2.22,4.63-4.65,4.61-1.7,0-3.32-.67-4.85-1.42-5.18-2.55-9.79-6.25-13.39-10.76-.14-.18-.43-.04-.37.18.59,2.21,3.88,7.51,3.86,11.02,0,1.24-1.58,1.79-2.32.8-2.59-3.45-4.25-7.46-5.87-11.46-1.6-3.95,3.73,15.88,2.26,23.83-.16.87-.8,1.63-1.66,1.84-1.64.39-2.52-1.41-2.94-2.88-1.29-4.41-2.57-8.83-3.86-13.24-4.96,5.48-10.03,10.86-15.22,16.13-.8.81-1.66,1.66-2.88,1.68-.59.01-1.19-.27-1.49-.78-.55-.97.16-1.95.83-2.72l16.83-19.4c.16-.18,0-.46-.24-.42l-12.21,1.98c-.78.13-1.75.17-2.06-.67-.26-.7.18-1.48.88-1.75,5.71-2.19,11.44-4.31,17.21-6.34.88-.31.89-1.55.01-1.87-3.1-1.12-6.76-.77-10.15-.65-4.22.14-9.17-.62-11.24-4.3,5.21-.66,10.38-1.59,15.12-2.95Z"
                  />
                  <circle className="cls-5" cx="346.58" cy="177.92" r=".84" />
                  <circle className="cls-5" cx="346.99" cy="189.1" r=".84" />
                  <circle className="cls-5" cx="332.51" cy="215.3" r=".84" />
                  <circle className="cls-5" cx="328.62" cy="216.04" r=".84" />
                  <circle className="cls-5" cx="340.38" cy="233.65" r=".84" />
                  <circle className="cls-5" cx="341.12" cy="237.54" r=".84" />
                  <circle className="cls-5" cx="341.98" cy="242.08" r=".84" />
                  <circle className="cls-5" cx="346.79" cy="183.51" r=".84" />
                  <circle className="cls-5" cx="324.22" cy="201.9" r=".84" />
                  <circle className="cls-5" cx="320.57" cy="197.65" r=".84" />
                  <circle className="cls-5" cx="316.27" cy="194.07" r=".84" />
                </g>
                <path
                  className="cls-3"
                  d="m335.77,236.26c-1.76,15.29,5.63,42.51,14.72,52.05,3.3,3.46,7.21,6.3,10.56,9.72s6.21,7.63,6.65,12.4"
                />
                <path
                  className="cls-3"
                  d="m397.58,48.62c-5.22-.03-7.01-5.08-7.16-10.3s2.92-10.08,6.86-13.5,8.74-5.65,13.36-8.08,9.24-5.16,12.47-9.26c-.7,6.99-1.4,14.01-3.2,20.8-1.8,6.79-4.76,13.39-9.5,18.58-1.63,1.79-3.55,3.7-5.96,3.95-3.05.32-4.84-.39-6.88-2.2Z"
                />
              </g>
              <path
                className="cls-3"
                d="m406.5,19.32c2.2,4.82,2.4,10.79.47,15.72s-5.15,9.24-8.32,13.49"
              />
              <path
                className="cls-3"
                d="m419.27,29.15c-1.51-1.04-2.9-.92-4.5-.92-1.82,0-4.22.71-5.35,1.84-5.23,5.23-9.47,11.44-12.43,18.22"
              />
            </g>
          </motion.g>
          <motion.g id="fleur_5" data-name="fleur 5" variants={flowersAnim}>
            <path
              className="cls-3"
              d="m590.52,174.07c-.04.11.1.2.18.1,1.34-1.68,2.9-3.29,4.97-3.76.19-.04.4-.07.56.03.32.2.22.68.05,1.01-1.02,1.99-2.74,2.74-4.32,4.32-.83.83,2.23-.34,3.64-.38.93-.03,1.95.04,2.63.67.14.13.27.29.27.48,0,.14-.06.26-.14.38-.63.92-1.94.97-3.06.94-1.26-.04-.34-.13-2.52-.13-1.88,0,2.54.34,4.19,1.24.38.21.78.49.84.91.05.34-.14.68-.39.9-.76.68-1.97.52-2.88.05-.78-.4-1.45-.99-2.22-1.41-.14-.08-.3.07-.23.21.27.51.72.93,1.16,1.31.56.48,1.15.95,1.46,1.62s.23,1.58-.38,1.98c-.21.14-.47.2-.72.22-1.11.08-2.16-.67-2.68-1.65-.43-.8-.55-1.73-.53-2.64,0-.08-.1-.1-.13-.02-.26.84-.58,1.66-.95,2.45-.22.45-.5.94-.99,1.08-.25.07-.53.02-.78-.03-.41-.09-.8-.29-1.08-.61-.79-.93-.2-2.13.6-2.85.63-.58,1.41-1.01,1.96-1.66.03-.04-.01-.1-.06-.08-1.62.61-2.8,1.57-4.39,1.55-.16,0-.33,0-.47-.09-.08-.04-.14-.12-.17-.2-.66-1.77,2.41-2.67,3.75-2.95.11-.02.1-.18,0-.2-.97-.16-1.89-.33-2.88-.51-.53-.1-1.08-.21-1.37-.77-.16-.3-.17-.67-.04-.99.36-.88,1.56-.97,2.41-.6.65.29,1.2.76,1.81,1.13.09.05.18-.06.11-.13-.83-.86-1.85-1.8-2.19-3.01-.22-.77-.27-1.64.28-2.3.23-.27.56-.45.91-.46.77-.02,1.15.63,1.41,1.23.5,1.16.53,2.45.88,3.66.12.42.07-1.09.1-1.63.07-1.18.31-2.6,1.47-2.98.27-.09.57-.07.81.08.36.24.32.57.27.88-.22,1.28-.73,2.52-1.16,3.6Z"
            />
            <path
              className="cls-3"
              d="m548.43,197.69c-.04.11.1.2.18.1,1.34-1.68,2.9-3.29,4.97-3.76.19-.04.4-.07.56.03.32.2.22.68.05,1.01-1.02,1.99-2.74,2.74-4.32,4.32-.83.83,2.23-.34,3.64-.38.93-.03,1.95.04,2.63.67.14.13.27.29.27.48,0,.14-.06.26-.14.38-.63.92-1.94.97-3.06.94-1.26-.04-.34-.13-2.52-.13-1.88,0,2.54.34,4.19,1.24.38.21.78.49.84.91.05.34-.14.68-.39.9-.76.68-1.97.52-2.88.05-.78-.4-1.45-.99-2.22-1.41-.14-.08-.3.07-.23.21.27.51.72.93,1.16,1.31.56.48,1.15.95,1.46,1.62s.23,1.58-.38,1.98c-.21.14-.47.2-.72.22-1.11.08-2.16-.67-2.68-1.65-.43-.8-.55-1.73-.53-2.64,0-.08-.1-.1-.13-.02-.26.84-.58,1.66-.95,2.45-.22.45-.5.94-.99,1.08-.25.07-.53.02-.78-.03-.41-.09-.8-.29-1.08-.61-.79-.93-.2-2.13.6-2.85.63-.58,1.41-1.01,1.96-1.66.03-.04-.01-.1-.06-.08-1.62.61-2.8,1.57-4.39,1.55-.16,0-.33,0-.47-.09-.08-.04-.14-.12-.17-.2-.66-1.77,2.41-2.67,3.75-2.95.11-.02.1-.18,0-.2-.97-.16-1.89-.33-2.88-.51-.53-.1-1.08-.21-1.37-.77-.16-.3-.17-.67-.04-.99.36-.88,1.56-.97,2.41-.6.65.29,1.2.76,1.81,1.13.09.05.18-.06.11-.13-.83-.86-1.85-1.8-2.19-3.01-.22-.77-.27-1.64.28-2.3.23-.27.56-.45.91-.46.77-.02,1.15.63,1.41,1.23.5,1.16.53,2.45.88,3.66.12.42.07-1.09.1-1.63.07-1.18.31-2.6,1.47-2.98.27-.09.57-.07.81.08.36.24.32.57.27.88-.22,1.28-.73,2.52-1.16,3.6Z"
            />
            <path
              className="cls-3"
              d="m611.58,159.13c-.14-.09-.29.09-.18.22,1.93,2.21,3.7,4.72,3.81,7.61,0,.26,0,.55-.18.74-.34.38-.97.12-1.37-.19-2.42-1.86-3-4.37-4.73-6.88-.91-1.32-.1,3.08-.39,4.98-.19,1.26-.54,2.61-1.56,3.37-.21.15-.46.28-.71.24-.18-.03-.34-.15-.47-.28-1.08-1.08-.83-2.85-.5-4.34.37-1.68.25-.42.8-3.36.47-2.53-1.09,3.32-2.71,5.32-.37.45-.85.92-1.43.9-.47-.02-.87-.35-1.12-.75-.72-1.19-.21-2.78.64-3.88.73-.95,1.69-1.71,2.45-2.63.14-.16-.02-.42-.22-.36-.76.23-1.43.73-2.05,1.24-.78.63-1.57,1.31-2.54,1.57-.97.25-2.18-.08-2.56-1.01-.13-.32-.15-.68-.12-1.02.16-1.51,1.43-2.73,2.88-3.19,1.19-.38,2.46-.32,3.68-.05.1.02.15-.12.06-.17-1.06-.55-2.08-1.18-3.05-1.89-.56-.4-1.14-.91-1.2-1.6-.03-.36.1-.72.24-1.04.23-.53.59-1.01,1.09-1.3,1.44-.83,2.91.26,3.68,1.51.62.99,1,2.15,1.74,3.05.04.05.13,0,.12-.06-.42-2.33-1.41-4.15-1-6.28.04-.22.09-.44.24-.61.08-.09.2-.16.32-.18,2.55-.44,2.99,3.9,3.03,5.78,0,.15.21.18.27.04.45-1.26.91-2.46,1.4-3.74.26-.68.55-1.4,1.37-1.65.45-.14.94-.07,1.34.18,1.09.71.92,2.34.21,3.38-.55.81-1.33,1.43-1.97,2.16-.09.1.04.25.15.18,1.36-.91,2.87-2.04,4.58-2.2,1.09-.1,2.28.05,3.02.95.31.37.47.86.39,1.34-.16,1.04-1.13,1.39-2.01,1.59-1.68.38-3.42.11-5.13.28-.6.06,1.44.36,2.17.54,1.57.39,3.42,1.07,3.64,2.71.05.38-.04.79-.31,1.07-.41.42-.85.29-1.25.14-1.67-.61-3.2-1.61-4.55-2.45Z"
            />
            <path
              className="cls-3"
              d="m634.42,207.33c-.13-.1-.3.06-.21.2,1.68,2.43,3.16,5.14,2.95,8.05-.02.26-.07.55-.26.72-.39.34-.98,0-1.35-.35-2.21-2.14-2.5-4.71-3.94-7.42-.76-1.42-.45,3.07-.96,4.94-.34,1.23-.84,2.55-1.94,3.19-.22.13-.49.23-.74.16-.18-.05-.32-.19-.44-.34-.95-1.2-.5-2.95,0-4.4.56-1.64.3-.39,1.18-3.27.76-2.48-1.47,3.2-3.31,5.01-.42.41-.95.83-1.53.74-.47-.07-.83-.45-1.03-.88-.59-1.28.11-2.8,1.08-3.81.84-.86,1.88-1.51,2.75-2.35.15-.15.03-.42-.18-.38-.78.14-1.51.57-2.19,1-.85.54-1.72,1.13-2.71,1.28s-2.17-.33-2.45-1.3c-.1-.34-.08-.7,0-1.04.34-1.49,1.75-2.57,3.24-2.86,1.23-.24,2.5-.04,3.69.37.1.03.17-.1.08-.16-1-.67-1.95-1.42-2.84-2.23-.51-.47-1.04-1.04-1.02-1.74.01-.36.18-.7.35-1.01.29-.5.71-.94,1.24-1.17,1.53-.67,2.88.6,3.51,1.93.51,1.06.76,2.26,1.39,3.24.04.06.13.02.13-.04-.15-2.38-.94-4.31-.28-6.39.07-.21.14-.43.31-.58.09-.09.21-.13.34-.14,2.6-.15,2.54,4.24,2.37,6.12-.01.15.19.21.26.07.6-1.21,1.19-2.36,1.83-3.58.34-.65.71-1.34,1.56-1.49.46-.08.95.04,1.31.34,1.01.83.65,2.45-.18,3.41-.64.74-1.49,1.28-2.21,1.93-.1.09.01.26.13.19,1.46-.75,3.11-1.71,4.83-1.67,1.1.02,2.27.31,2.91,1.3.26.41.37.92.24,1.38-.28,1.02-1.29,1.27-2.19,1.37-1.72.19-3.43-.28-5.16-.31-.61,0,1.4.53,2.1.79,1.52.57,3.3,1.46,3.33,3.13,0,.39-.13.78-.43,1.03-.45.37-.88.19-1.26,0-1.6-.8-3.02-1.97-4.27-2.96Z"
            />
            <path
              className="cls-3"
              d="m566.01,237.86c-.13-.1-.3.06-.21.2,1.68,2.43,3.16,5.14,2.95,8.05-.02.26-.07.55-.26.72-.39.34-.98,0-1.35-.35-2.21-2.14-2.5-4.71-3.94-7.42-.76-1.42-.45,3.07-.96,4.94-.34,1.23-.84,2.55-1.94,3.19-.22.13-.49.23-.74.16-.18-.05-.32-.19-.44-.34-.95-1.2-.5-2.95,0-4.4.56-1.64.3-.39,1.18-3.27.76-2.48-1.47,3.2-3.31,5.01-.42.41-.95.83-1.53.74-.47-.07-.83-.45-1.03-.88-.59-1.28.11-2.8,1.08-3.81.84-.86,1.88-1.51,2.75-2.35.15-.15.03-.42-.18-.38-.78.14-1.51.57-2.19,1-.85.54-1.72,1.13-2.71,1.28s-2.17-.33-2.45-1.3c-.1-.34-.08-.7,0-1.04.34-1.49,1.75-2.57,3.24-2.86,1.23-.24,2.5-.04,3.69.37.1.03.17-.1.08-.16-1-.67-1.95-1.42-2.84-2.23-.51-.47-1.04-1.04-1.02-1.74.01-.36.18-.7.35-1.01.29-.5.71-.94,1.24-1.17,1.53-.67,2.88.6,3.51,1.93.51,1.06.76,2.26,1.39,3.24.04.06.13.02.13-.04-.15-2.38-.94-4.31-.28-6.39.07-.21.14-.43.31-.58.09-.09.21-.13.34-.14,2.6-.15,2.54,4.24,2.37,6.12-.01.15.19.21.26.07.6-1.21,1.19-2.36,1.83-3.58.34-.65.71-1.34,1.56-1.49.46-.08.95.04,1.31.34,1.01.83.65,2.45-.18,3.41-.64.74-1.49,1.28-2.21,1.93-.1.09.01.26.13.19,1.46-.75,3.11-1.71,4.83-1.67,1.1.02,2.27.31,2.91,1.3.26.41.37.92.24,1.38-.28,1.02-1.29,1.27-2.19,1.37-1.72.19-3.43-.28-5.16-.31-.61,0,1.4.53,2.1.79,1.52.57,3.3,1.46,3.33,3.13,0,.39-.13.78-.43,1.03-.45.37-.88.19-1.26,0-1.6-.8-3.02-1.97-4.27-2.96Z"
            />
            <path
              className="cls-3"
              d="m645.12,237.34c-.03-.12-.21-.11-.21.02-.1,2.26-.45,4.58-1.91,6.22-.13.15-.29.3-.49.31-.39.02-.6-.46-.66-.85-.35-2.33.65-4.01,1.02-6.32.19-1.21-1.68,1.62-2.85,2.5-.77.58-1.68,1.12-2.64.98-.2-.03-.4-.1-.52-.26-.08-.12-.11-.27-.11-.41-.03-1.17,1.05-2,2.01-2.63,1.09-.71.36-.09,2.21-1.39,1.59-1.12-2.35,1.21-4.3,1.42-.44.05-.95.04-1.27-.29-.25-.26-.3-.67-.22-1.02.23-1.04,1.35-1.63,2.4-1.76.9-.12,1.83,0,2.74-.09.16-.02.21-.24.06-.32-.54-.29-1.17-.38-1.78-.45-.76-.08-1.56-.14-2.23-.53s-1.16-1.23-.88-1.94c.1-.25.27-.45.48-.62.89-.73,2.23-.7,3.27-.16.85.44,1.52,1.17,2.06,1.98.05.07.15.02.12-.06-.29-.88-.52-1.78-.69-2.69-.09-.52-.15-1.12.18-1.52.17-.21.43-.34.68-.44.41-.16.86-.22,1.29-.11,1.23.33,1.46,1.73,1.24,2.83-.18.88-.58,1.71-.65,2.61,0,.05.07.08.1.03,1-1.5,1.41-3.02,2.76-3.96.14-.09.28-.19.45-.2.09,0,.19.02.27.08,1.64,1.15-.41,3.75-1.38,4.79-.08.08.02.22.12.17.92-.44,1.8-.84,2.75-1.27.5-.23,1.04-.46,1.63-.15.32.17.56.48.64.83.23.98-.73,1.77-1.67,1.95-.73.14-1.48.05-2.22.1-.1,0-.11.16,0,.18,1.23.25,2.66.46,3.69,1.31.65.54,1.23,1.27,1.16,2.16-.03.37-.2.72-.49.94-.64.48-1.36.14-1.95-.23-1.13-.71-1.94-1.81-2.98-2.65-.36-.3.61.98.91,1.48.66,1.07,1.32,2.45.58,3.46-.17.24-.44.41-.73.41-.44,0-.62-.3-.76-.6-.6-1.25-.92-2.62-1.22-3.81Z"
            />
            <path
              className="cls-3"
              d="m568.55,171.22c-.03-.12-.21-.11-.21.02-.1,2.26-.45,4.58-1.91,6.22-.13.15-.29.3-.49.31-.39.02-.6-.46-.66-.85-.35-2.33.65-4.01,1.02-6.32.19-1.21-1.68,1.62-2.85,2.5-.77.58-1.68,1.12-2.64.98-.2-.03-.4-.1-.52-.26-.08-.12-.11-.27-.11-.41-.03-1.17,1.05-2,2.01-2.63,1.09-.71.36-.09,2.21-1.39,1.59-1.12-2.35,1.21-4.3,1.42-.44.05-.95.04-1.27-.29-.25-.26-.3-.67-.22-1.02.23-1.04,1.35-1.63,2.4-1.76.9-.12,1.83,0,2.74-.09.16-.02.21-.24.06-.32-.54-.29-1.17-.38-1.78-.45-.76-.08-1.56-.14-2.23-.53s-1.16-1.23-.88-1.94c.1-.25.27-.45.48-.62.89-.73,2.23-.7,3.27-.16.85.44,1.52,1.17,2.06,1.98.05.07.15.02.12-.06-.29-.88-.52-1.78-.69-2.69-.09-.52-.15-1.12.18-1.52.17-.21.43-.34.68-.44.41-.16.86-.22,1.29-.11,1.23.33,1.46,1.73,1.24,2.83-.18.88-.58,1.71-.65,2.61,0,.05.07.08.1.03,1-1.5,1.41-3.02,2.76-3.96.14-.09.28-.19.45-.2.09,0,.19.02.27.08,1.64,1.15-.41,3.75-1.38,4.79-.08.08.02.22.12.17.92-.44,1.8-.84,2.75-1.27.5-.23,1.04-.46,1.63-.15.32.17.56.48.64.83.23.98-.73,1.77-1.67,1.95-.73.14-1.48.05-2.22.1-.1,0-.11.16,0,.18,1.23.25,2.66.46,3.69,1.31.65.54,1.23,1.27,1.16,2.16-.03.37-.2.72-.49.94-.64.48-1.36.14-1.95-.23-1.13-.71-1.94-1.81-2.98-2.65-.36-.3.61.98.91,1.48.66,1.07,1.32,2.45.58,3.46-.17.24-.44.41-.73.41-.44,0-.62-.3-.76-.6-.6-1.25-.92-2.62-1.22-3.81Z"
            />
            <path
              className="cls-3"
              d="m634.67,171.28c-.06.14.13.25.23.13,1.68-2.12,3.66-4.15,6.26-4.73.24-.05.5-.09.7.04.4.25.27.86.06,1.28-1.28,2.51-3.46,3.45-5.45,5.44-1.04,1.04,2.81-.43,4.58-.48,1.17-.03,2.46.05,3.32.85.17.16.33.37.34.61,0,.17-.08.33-.18.47-.8,1.16-2.45,1.23-3.85,1.18-1.58-.05-.42-.16-3.18-.16-2.37,0,3.19.43,5.28,1.56.47.26.98.61,1.06,1.15.06.43-.17.85-.5,1.14-.96.85-2.48.66-3.63.07-.98-.5-1.83-1.24-2.79-1.78-.17-.1-.37.09-.28.26.34.65.9,1.17,1.46,1.65.7.6,1.45,1.2,1.84,2.04.39.84.29,1.99-.48,2.49-.27.17-.59.25-.91.28-1.39.1-2.72-.84-3.37-2.07-.54-1.01-.7-2.18-.66-3.33,0-.1-.13-.12-.16-.03-.32,1.05-.72,2.09-1.2,3.08-.27.57-.64,1.19-1.25,1.35-.32.09-.67.03-.98-.04-.52-.12-1.01-.37-1.36-.77-.99-1.17-.25-2.68.75-3.59.8-.73,1.78-1.27,2.47-2.09.04-.05-.02-.12-.07-.1-2.04.77-3.52,1.97-5.52,1.96-.2,0-.41-.01-.59-.11-.1-.06-.17-.15-.21-.26-.83-2.24,3.04-3.36,4.73-3.72.14-.03.13-.22,0-.25-1.22-.2-2.38-.42-3.63-.65-.66-.12-1.36-.27-1.72-.97-.2-.38-.22-.85-.06-1.24.46-1.11,1.97-1.22,3.03-.76.82.36,1.52.96,2.28,1.42.11.06.22-.08.13-.17-1.05-1.08-2.33-2.26-2.76-3.79-.27-.97-.34-2.07.36-2.89.29-.34.7-.57,1.15-.58.97-.03,1.45.79,1.78,1.55.63,1.46.67,3.08,1.11,4.61.16.53.09-1.37.13-2.05.09-1.48.39-3.28,1.85-3.76.34-.11.72-.09,1.02.1.45.3.4.72.34,1.11-.28,1.62-.92,3.17-1.46,4.53Z"
            />
            <path
              className="cls-3"
              d="m621.39,222.83c-.11-.01-.14.14-.04.18,1.77.74,3.54,1.69,4.44,3.35.08.15.16.32.11.49-.09.32-.53.35-.86.29-1.96-.39-3.02-1.69-4.77-2.66-.91-.51.84,1.84,1.22,3.03.25.79.44,1.69.06,2.43-.08.15-.19.3-.35.35-.12.03-.24,0-.36-.03-.94-.32-1.31-1.43-1.55-2.4-.27-1.09.03-.32-.51-2.2-.46-1.62.33,2.26-.04,3.9-.08.37-.23.79-.58.94-.28.13-.62.05-.87-.12-.77-.49-.93-1.57-.75-2.46.15-.77.49-1.49.67-2.25.03-.14-.13-.24-.23-.14-.38.35-.63.84-.84,1.32-.27.6-.54,1.22-1.03,1.65-.49.43-1.3.58-1.79.15-.17-.15-.29-.35-.37-.57-.34-.93.05-2.02.76-2.7.58-.56,1.35-.9,2.14-1.1.07-.02.06-.11-.01-.11-.78-.02-1.56-.09-2.34-.22-.44-.07-.94-.2-1.17-.59-.12-.2-.15-.45-.16-.68-.02-.38.05-.76.26-1.08.6-.9,1.78-.69,2.6-.19.65.4,1.21.97,1.9,1.28.04.02.08-.03.05-.07-.92-1.25-2.03-2.02-2.41-3.39-.04-.14-.07-.28-.04-.42.02-.08.07-.15.13-.2,1.36-1,2.88,1.42,3.45,2.5.05.09.18.05.17-.05-.1-.87-.18-1.71-.26-2.6-.04-.48-.08-.98.32-1.36.22-.21.53-.31.84-.28.84.1,1.22,1.1,1.11,1.92-.09.63-.36,1.22-.53,1.83-.02.09.1.14.14.06.53-.92,1.09-2.03,2.05-2.62.61-.38,1.35-.63,2.04-.32.29.13.52.37.62.67.21.65-.26,1.14-.71,1.52-.87.71-1.97,1.06-2.93,1.65-.33.21.95-.21,1.43-.31,1.03-.22,2.31-.37,2.92.53.14.21.2.47.13.71-.12.36-.41.42-.69.45-1.16.13-2.34-.01-3.37-.11Z"
            />
            <path
              className="cls-3"
              d="m630.48,267.34c-.09-.1-.25.01-.19.13,1.06,2.19,1.94,4.58,1.4,6.92-.05.21-.12.44-.31.56-.36.23-.8-.11-1.06-.45-1.53-2.02-1.45-4.15-2.29-6.53-.44-1.25-.75,2.45-1.4,3.9-.43.96-1,1.97-1.98,2.36-.2.08-.43.13-.62.04-.14-.07-.24-.2-.32-.33-.63-1.1-.04-2.46.54-3.58.66-1.26.29-.28,1.37-2.52.92-1.92-1.59,2.42-3.32,3.67-.39.28-.88.55-1.34.41-.37-.11-.62-.47-.73-.85-.32-1.11.43-2.27,1.36-2.97.79-.6,1.72-1,2.53-1.57.14-.1.07-.34-.1-.33-.65.02-1.3.27-1.91.54-.76.34-1.54.71-2.37.7-.83,0-1.73-.54-1.83-1.36-.04-.29.02-.58.13-.84.46-1.17,1.74-1.87,3-1.93,1.03-.04,2.04.28,2.96.76.08.04.15-.06.08-.12-.73-.67-1.41-1.4-2.03-2.17-.36-.44-.72-.98-.61-1.54.06-.29.23-.55.41-.78.3-.38.69-.68,1.16-.8,1.33-.35,2.27.84,2.62,2.01.28.93.34,1.94.73,2.82.02.05.1.03.11-.02.17-1.96-.23-3.62.56-5.24.08-.16.17-.33.32-.44.08-.06.19-.08.29-.07,2.13.2,1.54,3.77,1.17,5.28-.03.12.13.19.2.09.64-.91,1.27-1.77,1.94-2.69.36-.49.75-1,1.45-1.02.39-.01.77.15,1.03.44.72.8.23,2.07-.57,2.75-.61.53-1.37.85-2.04,1.3-.09.06-.02.21.08.17,1.28-.43,2.74-1.01,4.15-.76.89.16,1.81.53,2.21,1.42.16.36.19.79.02,1.16-.35.79-1.2.87-1.95.84-1.43-.06-2.76-.66-4.17-.89-.49-.08,1.08.6,1.62.9,1.17.65,2.5,1.6,2.33,2.96-.04.32-.2.62-.48.78-.42.25-.74.05-1.03-.16-1.2-.85-2.21-1.98-3.11-2.95Z"
            />
            <path
              className="cls-3"
              d="m556.2,183.21c-.02.06.06.11.1.05.71-.9,1.55-1.76,2.66-2.01.1-.02.21-.04.3.02.17.1.12.36.02.54-.54,1.06-1.47,1.46-2.31,2.31-.44.44,1.19-.18,1.94-.2.5-.01,1.04.02,1.41.36.07.07.14.16.14.26,0,.07-.03.14-.08.2-.34.49-1.04.52-1.63.5-.67-.02-.18-.07-1.35-.07-1.01,0,1.36.18,2.24.66.2.11.41.26.45.49.03.18-.07.36-.21.48-.41.36-1.05.28-1.54.03-.42-.21-.78-.53-1.18-.75-.07-.04-.16.04-.12.11.14.27.38.5.62.7.3.25.62.51.78.86.17.35.12.84-.21,1.06-.11.07-.25.11-.39.12-.59.04-1.15-.36-1.43-.88-.23-.43-.3-.92-.28-1.41,0-.04-.06-.05-.07-.01-.14.45-.31.89-.51,1.31-.12.24-.27.5-.53.57-.14.04-.28.01-.42-.02-.22-.05-.43-.16-.58-.33-.42-.49-.1-1.14.32-1.52.34-.31.75-.54,1.05-.89.02-.02,0-.05-.03-.04-.87.33-1.49.84-2.34.83-.09,0-.18,0-.25-.05-.04-.02-.07-.06-.09-.11-.35-.95,1.29-1.43,2.01-1.58.06-.01.06-.1,0-.1-.52-.09-1.01-.18-1.54-.27-.28-.05-.58-.11-.73-.41-.08-.16-.09-.36-.02-.53.19-.47.84-.52,1.29-.32.35.15.64.41.97.6.05.03.09-.03.06-.07-.44-.46-.99-.96-1.17-1.61-.12-.41-.14-.88.15-1.23.12-.14.3-.24.49-.25.41-.01.62.33.76.66.27.62.28,1.31.47,1.95.07.23.04-.58.05-.87.04-.63.17-1.39.78-1.59.14-.05.31-.04.43.04.19.13.17.31.14.47-.12.69-.39,1.35-.62,1.92Z"
            />
            <path
              className="cls-3"
              d="m605.7,203.21c-.02.06.06.11.1.05.71-.9,1.55-1.76,2.66-2.01.1-.02.21-.04.3.02.17.1.12.36.02.54-.54,1.06-1.47,1.46-2.31,2.31-.44.44,1.19-.18,1.94-.2.5-.01,1.04.02,1.41.36.07.07.14.16.14.26,0,.07-.03.14-.08.2-.34.49-1.04.52-1.63.5-.67-.02-.18-.07-1.35-.07-1.01,0,1.36.18,2.24.66.2.11.41.26.45.49.03.18-.07.36-.21.48-.41.36-1.05.28-1.54.03-.42-.21-.78-.53-1.18-.75-.07-.04-.16.04-.12.11.14.27.38.5.62.7.3.25.62.51.78.86.17.35.12.84-.21,1.06-.11.07-.25.11-.39.12-.59.04-1.15-.36-1.43-.88-.23-.43-.3-.92-.28-1.41,0-.04-.06-.05-.07-.01-.14.45-.31.89-.51,1.31-.12.24-.27.5-.53.57-.14.04-.28.01-.42-.02-.22-.05-.43-.16-.58-.33-.42-.49-.1-1.14.32-1.52.34-.31.75-.54,1.05-.89.02-.02,0-.05-.03-.04-.87.33-1.49.84-2.34.83-.09,0-.18,0-.25-.05-.04-.02-.07-.06-.09-.11-.35-.95,1.29-1.43,2.01-1.58.06-.01.06-.1,0-.1-.52-.09-1.01-.18-1.54-.27-.28-.05-.58-.11-.73-.41-.08-.16-.09-.36-.02-.53.19-.47.84-.52,1.29-.32.35.15.64.41.97.6.05.03.09-.03.06-.07-.44-.46-.99-.96-1.17-1.61-.12-.41-.14-.88.15-1.23.12-.14.3-.24.49-.25.41-.01.62.33.76.66.27.62.28,1.31.47,1.95.07.23.04-.58.05-.87.04-.63.17-1.39.78-1.59.14-.05.31-.04.43.04.19.13.17.31.14.47-.12.69-.39,1.35-.62,1.92Z"
            />
            <path
              className="cls-4"
              d="m581.64,254.46c-.02.06.06.11.1.05.71-.9,1.55-1.76,2.66-2.01.1-.02.21-.04.3.02.17.1.12.36.02.54-.54,1.06-1.47,1.46-2.31,2.31-.44.44,1.19-.18,1.94-.2.5-.01,1.04.02,1.41.36.07.07.14.16.14.26,0,.07-.03.14-.08.2-.34.49-1.04.52-1.63.5-.67-.02-.18-.07-1.35-.07-1.01,0,1.36.18,2.24.66.2.11.41.26.45.49.03.18-.07.36-.21.48-.41.36-1.05.28-1.54.03-.42-.21-.78-.53-1.18-.75-.07-.04-.16.04-.12.11.14.27.38.5.62.7.3.25.62.51.78.86.17.35.12.84-.21,1.06-.11.07-.25.11-.39.12-.59.04-1.15-.36-1.43-.88-.23-.43-.3-.92-.28-1.41,0-.04-.06-.05-.07-.01-.14.45-.31.89-.51,1.31-.12.24-.27.5-.53.57-.14.04-.28.01-.42-.02-.22-.05-.43-.16-.58-.33-.42-.49-.1-1.14.32-1.52.34-.31.75-.54,1.05-.89.02-.02,0-.05-.03-.04-.87.33-1.49.84-2.34.83-.09,0-.18,0-.25-.05-.04-.02-.07-.06-.09-.11-.35-.95,1.29-1.43,2.01-1.58.06-.01.06-.1,0-.1-.52-.09-1.01-.18-1.54-.27-.28-.05-.58-.11-.73-.41-.08-.16-.09-.36-.02-.53.19-.47.84-.52,1.29-.32.35.15.64.41.97.6.05.03.09-.03.06-.07-.44-.46-.99-.96-1.17-1.61-.12-.41-.14-.88.15-1.23.12-.14.3-.24.49-.25.41-.01.62.33.76.66.27.62.28,1.31.47,1.95.07.23.04-.58.05-.87.04-.63.17-1.39.78-1.59.14-.05.31-.04.43.04.19.13.17.31.14.47-.12.69-.39,1.35-.62,1.92Z"
            />
            <path
              className="cls-3"
              d="m628.52,174.99c-22.47,3.05-44.76,18.23-64.79,30.17-.16.09-.31-.23-.14-.3"
            />
            <path
              className="cls-3"
              d="m623.21,203.71c-7.74-4.72-19.87-7.15-28.83-5.77s-17.51,4.58-26.23,7.05c-1.71.48-3.48.94-5.23.68"
            />
            <path
              className="cls-3"
              d="m603.04,205.17c-5.61-2.47-25.53-1.18-31.64-.61"
            />
            <path
              className="cls-3"
              d="m613.66,220.14c-5.8-5.99-14.86-9.48-22.92-11.65s-16.42-2.87-24.73-3.56"
            />
            <path
              className="cls-3"
              d="m635.05,236.37c-5.31,3.56-12.19,4.2-18.46,2.94s-12.06-4.25-17.58-7.48c-2.67-1.57-5.32-3.21-7.64-5.26-6.53-5.76-10.46-14.66-18.39-18.28-2.98-1.36-6.27-1.83-9.51-2.28"
            />
            <path
              className="cls-3"
              d="m620.71,261.94c-10.92-2.15-22.77-10.07-30.02-18.52-7.25-8.45-12.39-18.48-16.98-28.62-1.53-3.39-3.21-7.02-6.44-8.87"
            />
            <path
              className="cls-3"
              d="m580.78,254.38c.6-9.92-2.79-26.18-1.86-28.29"
            />
            <path
              className="cls-3"
              d="m564.17,206.56c1.79,1.01,3.53,2.11,5.2,3.31.43.31.87.64,1.09,1.11.24.52.19,1.12.13,1.69-.22,2.16-.44,4.32-.66,6.48-.32,3.14-.67,6.38-2.18,9.15"
            />
            <path
              className="cls-3"
              d="m596.2,161.87c-6.42,2.62-12.44,6.21-17.78,10.62-2.67,2.21-5.21,4.66-6.92,7.67-3.84,6.77-2.94,15.07-2.94,22.85,0,.41-.04.89-.39,1.1s-.91-.29-.59-.55"
            />
            <path
              className="cls-3"
              d="m572.71,200.13c-.74-3.97.02-8.19,2.09-11.65s9.13-4.08,11.06-7.85c.03-.07-1.6.54-1.56.61"
            />
            <path
              className="cls-3"
              d="m564.17,204.78c-2.59-2.51-8.26-7.37-11.83-6.83"
            />
            <path
              className="cls-3"
              d="m559.43,187.92c4.03,2.89,7.11,9.81,9.02,14.4.28.67-.85,1.22-1.17,1.87s-2.17.97-2.37.27"
            />
            <path
              className="cls-3"
              d="m564.01,174.08c-1.99,7.91-.32,16.67,4.44,23.29"
            />
          </motion.g>
          <motion.g id="fleur_3" data-name="fleur 3" variants={flowersAnim}>
            <g>
              <path
                className="cls-3"
                d="m461.74,68.17c3.51-1.32,6.58-.49,9.84-1.29,1.86-.45,3.3-.82,4.59-1.55s2.2-3.1,2.32-4.58c.14-1.75-.02-3.52-1.16-4.85-1.96-2.29-3.46-2.46-8.76-1.2-3.27.78-6.9-.79-7.32.16"
              />
              <path
                className="cls-3"
                d="m461.74,68.17c-.58.32,2.12-1.58,3.55-3.2.49-.56.21-1.43-.5-1.64-.82-.24-1.63-.51-2.42-.84-.35-.15-.29-.67.08-.74.86-.14,1.77-.33,2.48-.81.28-.19.21-.63-.11-.75-.76-.28-1.89-.71-1.89-.71-.61-.23-.59-.77-.19-.92.62-.24,1.19-.59,1.68-1.04.15-.14.3-.31.32-.52.04-.46-.52-.7-.97-.84-1.05-.33-2.77-.87-2.53-1.3"
              />
            </g>
            <g>
              <path
                className="cls-3"
                d="m491.45,107.71c1.52.41,3.57-4.91,4.83-7.41.72-1.43,1.26-2.55,1.49-3.76s-.79-3.08-1.67-3.95c-1.04-1.03-2.25-1.89-3.72-1.88-2.51.01-3.44.88-5.5,4.93-1.27,2.5-4.45,4.69-3.96,4.92"
              />
              <path
                className="cls-3"
                d="m491.45,107.71c-.26.37.05-1.66-.06-3.37-.06-.9-1.05-1.39-1.81-.91s-1.47.88-2.27,1.2c-.02,0-.05-.02-.03-.04.34-.62.84-1.32,1.12-2.07.2-.55-.41-1.06-.93-.77l-1.94,1.08c-.06.03-.11-.03-.08-.08.48-.74.71-1.63.67-2.51,0-.17-.04-.36-.16-.49-.27-.28-.73-.04-1.06.16-.77.49-1.32,1.04-1.97.63"
              />
            </g>
            <g>
              <path
                className="cls-3"
                d="m419.46,243.71c1.52.41,3.57-4.91,4.83-7.41.72-1.43,1.26-2.55,1.49-3.76s-.79-3.08-1.67-3.95c-1.04-1.03-2.25-1.89-3.72-1.88-2.51.01-3.44.88-5.5,4.93-1.27,2.5-4.45,4.69-3.96,4.92"
              />
              <path
                className="cls-3"
                d="m419.46,243.71c-.26.37.05-1.66-.06-3.37-.06-.9-1.05-1.39-1.81-.91s-1.47.88-2.27,1.2c-.02,0-.05-.02-.03-.04.34-.62.84-1.32,1.12-2.07.2-.55-.41-1.06-.93-.77l-1.94,1.08c-.06.03-.11-.03-.08-.08.48-.74.71-1.63.67-2.51,0-.17-.04-.36-.16-.49-.27-.28-.73-.04-1.06.16-.77.49-1.32,1.04-1.97.63"
              />
              <path
                className="cls-3"
                d="m494.66,72.25c3.5-1.62,9.44,1.57,9.79,4.34.18,1.46-3.92,5.02-4.19,6.46-.41,2.22-1.12,4.39-2.1,6.43-.48,1-1.06,2-2.01,2.58"
              />
              <path
                className="cls-3"
                d="m498.66,70.43c1.39,0-8.42-2.57-9.69-4.38s-2.95-3.31-4.68-4.7c-.38-.3-.76-.61-1.18-.85-1.3-.77-2.93-.95-4.37-.48"
              />
              <path
                className="cls-3"
                d="m463.84,80.04c1.45,4.16.37,9.1-2.67,12.28"
              />
              <path
                className="cls-3"
                d="m440.41,86.91c4.12,2.87,5.92,8.66,4.15,13.36"
              />
              <path
                className="cls-3"
                d="m436.64,87.45c.81-3.07.62-6.4-.52-9.36-.15-.4-.34-.81-.66-1.09-.35-.31-.82-.43-1.27-.55-1.21-.31-2.54-.61-3.67-.08-.66.31-1.16.87-1.68,1.38-1.45,1.41-3.19,2.52-5.08,3.24"
              />
              <path
                className="cls-3"
                d="m415.43,130.29c2.09-3.04,4.81-5.64,7.94-7.59,1.78-1.11,3.95-2.03,5.93-1.33.67.24,1.27.65,1.83,1.1,3.17,2.57,5.14,6.58,5.24,10.66"
              />
              <path
                className="cls-3"
                d="m414.48,127.9c-1.19-.65-4.78,2.97-5.74,4.83-1.1,2.1-1.57,4.47-2.06,6.79-.4,1.89-1.57,4.38-1.98,6.27"
              />
              <path
                className="cls-3"
                d="m416.12,143.12c-2.68.66-4.14,3.53-4.91,6.17-1.07,3.68-2.12,8.75-1.86,12.58"
              />
              <path
                className="cls-3"
                d="m423.11,161.82c1.38-2.43,3.39-4.51,5.82-5.87,1.49-.84,3.13-1.4,4.82-1.65,1.06-.16,2.16-.18,3.21.04,3.2.67,5.52,3.55,6.68,6.61s1.41,6.37,2.09,9.57"
              />
              <path
                className="cls-3"
                d="m428.12,179.59c-4.32,1.61-8.36,8.21-7,12.62"
              />
              <path
                className="cls-3"
                d="m436.66,201.26c.31-3.05,3.48-4.84,6.27-6.12.4-.18.84-.37,1.26-.25.4.12.67.48.91.83,3.97,5.92,6.24,12.97,6.45,20.09"
              />
              <path
                className="cls-3"
                d="m437.23,213.52s-.02-.03-.02-.05c-1.51-3.08-5.83-3.39-7.87-.64-1.02,1.37-1.8,2.97-2.4,4.55-1.31,3.51-2.05,7.3-4.2,10.37"
              />
              <path
                className="cls-3"
                d="m434.96,254.08c-1.77-.96-5.35-2.86-7.04-1.76-.67.44-1.12,1.13-1.54,1.81-2.91,4.73-5.3,9.79-7.09,15.05"
              />
            </g>
            <g>
              <path
                className="cls-3"
                d="m448.17,230.49c1.32.15,3.84-4.56,5.28-6.82.82-1.29,1.45-2.29,1.81-3.35s-.34-2.49-1.02-3.11c-.81-.74-1.81-1.31-3.13-1.12-2.28.32-3.22,1.16-5.58,4.82-1.46,2.26-4.61,4.49-4.19,4.62"
              />
              <path
                className="cls-3"
                d="m448.17,230.49c-.28.34.25-1.4.36-2.82.06-.75-.78-1.04-1.53-.54s-1.44.92-2.21,1.29c-.02.01-.04,0-.03-.03.39-.57.92-1.22,1.27-1.88.25-.49-.24-.84-.74-.53l-1.89,1.15c-.05.03-.1-.01-.06-.06.52-.68.85-1.46.91-2.19.01-.15.01-.3-.08-.39-.21-.2-.65.05-.98.27-.76.51-1.32,1.04-1.86.77"
              />
            </g>
            <g>
              <path
                className="cls-3"
                d="m421.22,99.96c.77-3.54,3.06-6.1,4.13-9.16.61-1.75,1.06-3.11,1.15-4.54s-1.36-3.42-2.5-4.29c-1.35-1.03-2.86-1.83-4.54-1.61-2.88.39-3.82,1.51-5.58,6.47-1.09,3.06-4.04,5.4-3.67,5.74"
              />
              <path
                className="cls-3"
                d="m421.22,99.46c-.24.45-.19-1.76-.53-3.67-.2-1.1-1.51-1.56-2.38-.86-.72.59-1.48,1.15-2.3,1.61-.02.01-.06-.01-.05-.04.33-.85.88-1.84,1.03-2.84.09-.56-.56-.95-1.02-.61l-2.45,1.82s-.03,0-.02-.02c.51-.96.69-2.1.49-3.17-.04-.2-.09-.41-.25-.54-.35-.28-.84.06-1.19.34-.82.68-2.57,1.51-2.33,1.09"
              />
            </g>
            <g>
              <path
                className="cls-3"
                d="m429.33,205.18c-1.33-2.75-.91-5.33-1.8-7.92-.51-1.48-.92-2.62-1.6-3.61-.68-.99-2.65-1.53-3.84-1.5-1.41.04-2.81.31-3.78,1.36-1.67,1.79-1.69,3.03-.27,7.25.88,2.6.17,5.72.59,5.75"
              />
              <path
                className="cls-3"
                d="m429.33,205.18c.08.42-1.04-1.06-2.25-2.14-.7-.62-1.79-.23-1.99.7-.16.78-.37,1.54-.66,2.29,0,.02-.04.02-.05,0-.23-.74-.38-1.68-.8-2.43-.23-.42-.86-.33-.98.14l-.65,2.51s-.02.01-.03,0c-.17-.91-.64-1.75-1.32-2.36-.13-.11-.27-.22-.44-.22-.37,0-.52.49-.59.86-.18.88-.88,2.37-.95,1.97"
              />
            </g>
            <g>
              <path
                className="cls-3"
                d="m445.17,114.41c.61-2.04,1.52-4.6,1.89-7.04.21-1.39.37-2.47.24-3.55s-1.49-2.37-2.46-2.86c-1.15-.58-2.39-.98-3.62-.58-2.1.68-2.64,1.66-3.28,5.6-.39,2.44-2.28,4.59-1.95,4.8"
              />
              <path
                className="cls-3"
                d="m445.17,114.41c-.12.37-.38-1.29-.9-2.67-.3-.79-1.35-.96-1.89-.31-.46.54-.95,1.06-1.5,1.52-.02.01-.04,0-.04-.02.13-.68.4-1.49.38-2.26-.01-.43-.55-.63-.85-.31l-1.58,1.69s-.02,0-.02-.01c.25-.79.23-1.66-.07-2.44-.05-.14-.13-.29-.26-.37-.3-.16-.62.16-.84.42-.52.62-1.71,1.48-1.59,1.14"
              />
            </g>
            <g>
              <path
                className="cls-3"
                d="m442.67,147.66c-.22-2.39-.38-5.45-.97-8.12-.34-1.53-.62-2.9-1.16-3.95s-2.29-1.7-3.39-1.74c-1.29-.04-2.6.16-3.58,1.16-1.68,1.72-1.81,2.97-.87,7.32.58,2.68-.33,5.8.05,5.85"
              />
              <path
                className="cls-3"
                d="m442.67,147.46c.03.43-.86-1.14-1.87-2.3-.58-.67-1.62-.33-1.88.6-.22.78-.47,1.54-.8,2.28,0,.02-.04.02-.04,0-.14-.76-.21-1.72-.52-2.5-.18-.44-.76-.38-.91.09l-.81,2.5s-.02.01-.02,0c-.07-.93-.43-1.81-1.01-2.46-.11-.12-.23-.24-.39-.25-.34-.02-.51.46-.62.84-.24.88-1.01,2.35-1.04,1.94"
              />
            </g>
            <g>
              <path
                className="cls-3"
                d="m404.37,160.64c.73-2.29,1.78-5.16,2.28-7.85.28-1.54.57-2.91.48-4.09s-1.45-2.46-2.44-2.92c-1.17-.54-2.45-.87-3.75-.33-2.22.92-2.83,2.03-3.66,6.4-.52,2.7-2.58,5.21-2.24,5.41"
              />
              <path
                className="cls-3"
                d="m404.45,160.46c-.14.41-.34-1.38-.83-2.84-.28-.84-1.36-.94-1.96-.19-.5.63-1.03,1.24-1.63,1.78-.02.02-.05,0-.04-.02.17-.76.48-1.67.5-2.51,0-.47-.55-.64-.87-.27l-1.73,1.98s-.03,0-.02-.01c.29-.88.31-1.84.04-2.66-.05-.15-.12-.31-.26-.38-.31-.15-.65.22-.89.53-.57.72-1.85,1.76-1.71,1.38"
              />
            </g>
            <g>
              <path
                className="cls-3"
                d="m413.03,176.27c.09-2.4.33-5.45.08-8.18-.14-1.56-.24-2.96-.64-4.06s-2.06-1.98-3.13-2.16c-1.27-.21-2.6-.18-3.7.69-1.89,1.49-2.18,2.72-1.8,7.15.23,2.74-1.08,5.71-.7,5.81"
              />
              <path
                className="cls-3"
                d="m413.06,176.07c-.02.43-.7-1.24-1.56-2.52-.49-.74-1.56-.54-1.94.35-.31.74-.66,1.47-1.09,2.16-.01.02-.04.02-.04,0-.04-.77.02-1.74-.2-2.55-.12-.46-.7-.47-.91-.03l-1.13,2.38s-.02.01-.02,0c.05-.93-.2-1.85-.68-2.57-.09-.13-.2-.27-.35-.3-.34-.06-.57.39-.72.75-.35.84-1.31,2.2-1.28,1.79"
              />
            </g>
            <g>
              <path
                className="cls-3"
                d="m454.7,182.1c-.81-2.26-1.72-5.18-2.97-7.62-.71-1.39-1.32-2.66-2.1-3.53s-2.65-1.08-3.71-.84c-1.26.28-2.48.8-3.18,2.01-1.2,2.08-1.01,3.33.99,7.31,1.23,2.45,1.12,5.7,1.51,5.66"
              />
              <path
                className="cls-3"
                d="m454.65,181.91c.14.41-1.11-.89-2.39-1.76-.73-.5-1.65.08-1.67,1.05-.01.81-.07,1.61-.21,2.41,0,.02-.03.03-.04,0-.33-.7-.63-1.62-1.13-2.29-.28-.38-.83-.18-.86.31l-.16,2.63s-.02.02-.02,0c-.3-.88-.87-1.65-1.59-2.13-.13-.09-.28-.17-.44-.14-.33.07-.38.58-.39.96-.01.91-.39,2.53-.52,2.13"
              />
            </g>
            <g>
              <path
                className="cls-3"
                d="m417.64,284.67c.89-2.23,2.15-5.02,2.84-7.67.39-1.51.78-2.87.77-4.04s-1.27-2.56-2.22-3.09c-1.13-.63-2.38-1.04-3.71-.6-2.28.76-2.97,1.82-4.11,6.12-.71,2.65-2.95,5.01-2.63,5.23"
              />
              <path
                className="cls-3"
                d="m417.73,284.49c-.17.4-.24-1.4-.62-2.9-.22-.86-1.29-1.03-1.94-.33-.55.59-1.12,1.16-1.75,1.66-.02.01-.05,0-.04-.02.22-.74.6-1.63.68-2.46.04-.47-.5-.68-.85-.33l-1.86,1.86s-.03,0-.02-.01c.36-.86.44-1.81.23-2.65-.04-.16-.1-.32-.23-.4-.3-.17-.67.18-.93.46-.62.68-1.97,1.63-1.8,1.25"
              />
            </g>
            <g>
              <path
                className="cls-3"
                d="m460.76,105.98c.62-2.02,1.53-4.57,1.93-6.95.23-1.36.4-2.42.3-3.46-.1-1.04-1.34-2.2-2.23-2.62-1.06-.49-2.22-.8-3.37-.33-1.98.8-2.51,1.77-3.2,5.65-.42,2.39-2.24,4.6-1.93,4.78"
              />
              <path
                className="cls-3"
                d="m460.76,105.98c-.12.36-.33-1.23-.78-2.53-.26-.75-1.24-.85-1.77-.18-.44.56-.91,1.09-1.44,1.57-.02.01-.04,0-.04-.02.14-.67.41-1.48.41-2.22,0-.42-.5-.58-.79-.25l-1.52,1.75s-.02,0-.02-.01c.25-.78.25-1.63,0-2.36-.05-.14-.11-.28-.24-.34-.28-.14-.59.19-.8.46-.5.63-1.64,1.55-1.52,1.21"
              />
            </g>
          </motion.g>
          <motion.g id="fleur_4" data-name="fleur 4" variants={flowersAnim}>
            <path
              className="cls-3"
              d="m427.14,305.99c-1.26-6.27-.33-12.97,2.59-18.66.41-.8.88-1.6,1.6-2.13s1.77-.72,2.52-.23c.87.57,1.04,1.77,1.05,2.81.08,5.7-1.75,11.42-5.12,16.02-.27.37-.88.74-2.65,2.18Z"
            />
            <path
              className="cls-3"
              d="m438.79,297.36c-.81-3.56-.61-7.25-.4-10.9.2-3.57.53-7.45,2.96-10.07.17-.18.35-.36.59-.42.42-.12.85.16,1.13.49.97,1.11.98,2.74.88,4.2-.29,4.52-1.02,9.01-5.16,16.7Z"
            />
            <path
              className="cls-3"
              d="m446.42,290.83c.58-3.63.11-7.32-.02-10.99s.12-7.51,1.96-10.69c.34-.59.79-1.19,1.45-1.38.76-.22,1.6.21,2.07.85s.66,1.44.79,2.22c1,6.04-.59,12.47-6.24,19.99Z"
            />
            <path
              className="cls-3"
              d="m454.09,282.97l.16-14.33c.03-2.66.13-5.53,1.76-7.64.49-.63,1.16-1.18,1.95-1.23.61-.03,1.19.24,1.67.62,1.39,1.08,2,2.93,1.97,4.68s-.61,3.45-1.24,5.09c-1.39,3.62-3.03,7.15-6.27,12.81Z"
            />
            <path
              className="cls-3"
              d="m462.49,273.95c.89-6.02-.17-12.24,1.06-18.2.44-2.15,1.23-4.32,2.83-5.82.2-.19.43-.37.71-.4.26-.02.51.11.73.25,1.8,1.17,2.59,3.46,2.51,5.6s-.88,4.18-1.67,6.17c-1.31,3.3-2.63,6.6-6.16,12.4Z"
            />
            <path
              className="cls-3"
              d="m472.53,259.81c-.75-4.73-.87-9.55-.37-14.31.22-2.13.67-4.44,2.28-5.86.21-.19.45-.36.73-.4.31-.04.62.08.89.23,1.84,1.02,2.79,3.23,2.82,5.34s-.69,4.15-1.49,6.1c-1.62,3.98-3.57,7.82-4.86,8.9Z"
            />
            <path
              className="cls-3"
              d="m482.85,248.52c-1.96-3.22-3.95-8.55-4.99-12.17-.47-1.63-.73-3.38-.27-5.02s1.79-3.11,3.47-3.32c2.51-.31,4.58,2.28,4.79,4.79s-.87,4.94-1.73,7.31c-.99,2.73-1.73,7.63-1.27,8.4Z"
            />
            <path
              className="cls-3"
              d="m489.33,239.37c-1.53-4.09-2.35-8.45-2.42-12.81-.04-2.39.28-5.07,2.13-6.59.27-.22.59-.42.94-.44.28-.01.54.09.79.22,1.64.85,2.59,2.69,2.79,4.52s-.25,3.68-.8,5.44c-.93,2.94-2.16,5.78-3.42,9.66Z"
            />
            <path
              className="cls-3"
              d="m496.26,228.37c-1.64-4.74-2.29-9.81-1.9-14.81.11-1.42.33-2.9,1.18-4.04s2.49-1.82,3.74-1.14c1.17.64,1.55,2.13,1.67,3.45.41,4.38-.7,8.89-4.7,16.54Z"
            />
            <path
              className="cls-3"
              d="m505.62,215.23c-.23-2.37-1.17-4.61-2.08-6.82s-1.79-4.48-1.88-6.86.75-4.93,2.7-6.31,5.07-1.1,6.31.94c.98,1.6.59,3.65.11,5.46-1.17,4.36-2.77,8.6-4.77,12.64.2-.25.39-.5-.38.95Z"
            />
            <path
              className="cls-3"
              d="m514.16,201.86c-2-3.31-2.92-7.26-2.57-11.1.09-.97.29-2.01,1.03-2.65,1.37-1.19,3.69-.06,4.33,1.64s.14,3.59-.36,5.33c-.65,2.26-1.3,4.52-1.96,6.77.11-.3.22-.59-.46,0Z"
            />
            <path
              className="cls-3"
              d="m520.41,192.51c-3.97-4.77-4.42-12.15-1.07-17.38.29-.46.65-.93,1.17-1.09.56-.18,1.17.05,1.65.37,1.52,1.01,2.2,2.95,2.18,4.77s-.64,3.58-1.26,5.3c-.67,1.87-1.33,3.74-2.68,8.03Z"
            />
            <path
              className="cls-3"
              d="m527.88,181.24c-1.41-2.62-2.63-5.39-3.08-8.33s-.09-6.08,1.46-8.62c.44-.72,1.03-1.42,1.85-1.61.54-.12,1.11,0,1.6.23,1.64.77,2.51,2.66,2.58,4.47s-.52,3.57-1.11,5.28c-.84,2.48-1.69,4.95-3.3,8.57Z"
            />
            <path
              className="cls-3"
              d="m534.11,169.85c-1.16-2.41-2.33-4.85-2.76-7.49s-.05-5.53,1.64-7.59c.3-.37.69-.73,1.17-.76.41-.03.8.21,1.12.46,2.12,1.67,2.81,4.68,2.41,7.35s-1.72,5.11-3.01,7.48c.16-.34.33-.67-.57.56Z"
            />
            <path
              className="cls-3"
              d="m540.12,161.4c-3.46-4.43-3.87-11.02-.98-15.85.26-.43.57-.88,1.04-1.05.72-.28,1.56.21,1.94.88s.41,1.48.42,2.26c.07,4.05-.18,8.11-.75,12.12-.08.56-.64,1.29-1.67,1.64Z"
            />
            <path
              className="cls-3"
              d="m544.5,155.21c-1.43-4.33-1.72-9.03-.83-13.51.19-.98.48-2,1.22-2.66s2.08-.73,2.63.1c.25.38.29.86.31,1.32.19,4.42-.6,8.88-2.27,12.97-.06.14-.16.31-.31.29s-.04-.33-.74,1.5Z"
            />
            <path
              className="cls-3"
              d="m550.17,146.57c-.84-2.93-1.69-6-1.1-9,.23-1.17.73-2.36,1.73-3.01s2.56-.48,3.12.57c.22.41.25.89.27,1.36.08,3.31-1.08,6.64-3.2,9.19-.22-.15-.13-.56-.81.89Z"
            />
            <path
              className="cls-3"
              d="m553.85,139.85c.35-2.21.05-4.49.5-6.68s1.98-4.44,4.21-4.6c.26-.02.56,0,.75.19.28.27.21.72.12,1.09-.72,2.86-1.79,5.63-5.58,9.99Z"
            />
            <path
              className="cls-3"
              d="m560.82,131.19c.95-1.84,1.48-3.88,2.32-5.77s2.11-3.73,4-4.58,4.49-.34,5.43,1.51c.6,1.18.42,2.64-.21,3.8s-1.65,2.06-2.73,2.82c-3.03,2.13-6.61,3.31-8.82,2.23Z"
            />
            <path
              className="cls-3"
              d="m557.49,139.6c2.62-3.88,6.83-6.65,11.44-7.52.46-.09,1.01-.13,1.35.2.35.34.3.91.18,1.39-.66,2.61-2.78,4.8-5.36,5.54-1.62.47-3.34.39-5.01.59s-3.3.85-2.59-.19Z"
            />
            <path
              className="cls-3"
              d="m551.72,149.13c.25-2.09,1.68-3.9,3.45-5.03s3.85-1.68,5.92-2.07c1.23-.23,2.83-.22,3.32.92.33.77-.06,1.65-.57,2.31-1.89,2.49-5.16,3.42-8.2,4.2-2.14.55-4.1,1.13-3.93-.34Z"
            />
            <path
              className="cls-3"
              d="m542.27,161.33c2.66-2.31,4.64-5.31,7.27-7.65s6.36-3.98,9.66-2.76c.11.04.23.09.29.19.1.16,0,.37-.1.54-1.98,3.21-5.68,4.85-9.16,6.32-2.48,1.04-4.96,2.09-7.97,3.37Z"
            />
            <path
              className="cls-3"
              d="m537.84,168.77c2.75-3.39,6.39-6.06,10.45-7.68.77-.31,1.65-.57,2.4-.22.93.44,1.23,1.71.89,2.68s-1.14,1.71-1.94,2.36c-1.75,1.41-3.66,2.61-5.69,3.58-1.04.49-2.13.93-3.27,1.01-2,.13-4.09-.18-2.83-1.73Z"
            />
            <path
              className="cls-3"
              d="m529.9,181.33c2.14-2.74,4.41-5.41,7.18-7.5s6.1-3.58,9.57-3.68c.37-.01.81.04.97.37.11.22.04.48-.05.71-.87,2.32-3.1,3.83-5.29,5-3.88,2.07-8.06,3.59-12.38,5.1Z"
            />
            <path
              className="cls-3"
              d="m522.96,191.85c2.11-3.39,5.26-6.12,8.92-7.72,1.38-.61,2.92-1.06,4.38-.7s2.75,1.78,2.48,3.26c-.27,1.5-1.86,2.34-3.3,2.81-1.3.42-2.64.73-3.99.92-2.68.37-5.52.29-8.48,1.43Z"
            />
            <path
              className="cls-3"
              d="m431.34,305.18c2.97-1.27,5.4-3.5,7.97-5.45s5.52-3.71,8.74-3.75c.61,0,1.25.05,1.79.34,1.54.81,1.67,3.14.68,4.57s-2.69,2.18-4.32,2.79c-1.96.74-3.97,1.4-6.05,1.63-1.38.15-2.77.11-4.16.06-2.05-.06-4.1-.13-4.66-.19Z"
            />
            <path
              className="cls-3"
              d="m444.16,295.45c4.47-3.5,9.05-7.07,14.42-8.89,1.23-.42,2.55-.74,3.84-.51s2.51,1.17,2.73,2.46c.3,1.83-1.45,3.35-3.17,4.04-3.54,1.42-7.49,1.08-11.29,1.39-2.45.2-4.87.68-6.52,1.51Z"
            />
            <path
              className="cls-3"
              d="m455.41,285.28c4.47-4.55,9.12-9.22,15.01-11.67,1.56-.65,3.42-1.11,4.86-.24,1.92,1.16,1.93,4.16.54,5.92s-3.68,2.53-5.87,3.02c-4.83,1.08-9.86,1.26-14.54,2.88-1.45.5-2.17,2.28,0,.08Z"
            />
            <path
              className="cls-3"
              d="m467.05,270.89c3.53-3.8,8.2-6.53,13.24-7.73,1-.24,2.14-.39,2.97.21,1.3.95.9,3.05-.1,4.32-1.39,1.77-3.54,2.78-5.7,3.41-2.32.67-4.75.98-7.17.89-2.03-.07-4.07-.22-3.25-1.1Z"
            />
            <path
              className="cls-3"
              d="m473.38,263.46c2.49-1.8,4.52-4.16,6.87-6.13s5.21-3.62,8.28-3.56c.8.02,1.67.2,2.15.84.49.65.39,1.62-.05,2.31s-1.15,1.16-1.86,1.56c-5.3,2.97-11.83,3.01-15.39,4.98Z"
            />
            <path
              className="cls-3"
              d="m480.31,254.98c2.06-4.86,5.82-8.98,10.47-11.49,2.17-1.17,4.81-1.99,7.05-.98.67.3,1.3.8,1.52,1.5.2.64.02,1.34-.26,1.95-1.46,3.19-5.23,4.46-8.61,5.41-2.58.72-5.15,1.44-7.73,2.16-1.64.46-3.42,1.01-2.45,1.45Z"
            />
            <path
              className="cls-3"
              d="m488.92,243c1.59-3.06,3.72-5.9,6.58-7.82s6.49-2.84,9.82-1.97c.34.09.7.22.88.53.11.19.12.43.11.65-.1,2.51-2.13,4.59-4.42,5.61s-4.86,1.19-7.35,1.49-5.07.8-5.62,1.52Z"
            />
            <path
              className="cls-3"
              d="m494.91,233.1c2.4-2.6,4.83-5.22,7.8-7.15s6.55-3.13,10.04-2.56c.71.12,1.44.32,1.97.81,1.2,1.12.73,3.27-.52,4.34s-2.97,1.35-4.6,1.6c-5.47.82-10.95,1.63-14.68,2.97Z"
            />
            <path
              className="cls-3"
              d="m501.13,223.89c2.35-6.04,7.99-10.67,14.37-11.79,1.26-.22,2.57-.31,3.79.04s2.36,1.21,2.77,2.42c.67,1.96-.78,4.13-2.64,5.04s-4.01.94-6.08,1.08c-4.91.35-9.76,1.53-12.21,3.2Z"
            />
            <path
              className="cls-3"
              d="m509.02,212.21c3.09-4.79,7.8-8.51,13.17-10.4,3.31-1.17,7.38-1.5,9.96.87.24.22.47.48.54.8.06.29-.04.59-.15.87-1.01,2.44-3.59,3.9-6.17,4.44s-5.25.35-7.89.5c-3.99.23-7.93,1.27-9.46,2.92Z"
            />
            <path
              className="cls-3"
              d="m515.31,202.42c3.03-5.95,9.38-10.06,16.05-10.39,1.34-.07,2.75.02,3.93.67s2.08,1.97,1.87,3.3c-.24,1.56-1.8,2.54-3.27,3.12-7.05,2.81-15.62,1.56-18.57,3.29Z"
            />
          </motion.g>
          <motion.g id="fleur_12" data-name="fleur 12" variants={flowersAnim}>
            <path
              className="cls-2"
              d="m307.17,351.12c-13.26.05-24.58,10.36-37.78,11.58-12.13,1.12-23.42-5.53-34.28-11.07-10.96-5.59-22.4-10.26-34.14-13.94-5.67-1.78-11.62-3.33-17.5-2.42-4.37.68-8.42,2.7-12.69,3.85-23.92,6.47-49.62-14.64-72.94-6.24-9.18,3.3-16.71,10.93-26.37,12.32-8.03,1.16-15.92-2.24-23.87-3.88-14.29-2.96-29.71-.04-41.93,7.93,3.55-5.08,4.16-12.27,5.98-18.2,1.52-4.97,4.72-8.25,5.81-13.34,2.61-12.18,11.37-23.57,23.45-26.6,19.47-4.88,39.87,12.32,59,6.26,3.76-1.19,7.24-3.25,11.08-4.16,9.67-2.29,19.32,2.98,28.15,7.54s19.51,8.61,28.37,4.1c2.23-1.13,4.18-2.75,6.42-3.85,3.6-1.77,7.72-2.11,11.72-2.11,17.8-.03,35.54,6.12,49.51,17.14,4.94,3.9,10.08,8.64,16.37,8.53,2.5-.05,4.9-.88,7.3-1.56,15.5-4.43,34.75-1.38,43.39,12.23,3.98,6.26,6.97,15.9,4.95,15.9Z"
            />
            <path
              className="cls-5"
              d="m83.01,306.54c-13.47,2.43-26.06,8.71-35.47,18.69-1.38,1.4-2.73,2.8-3.32,4.73-.09-2.05,1.07-4,2.16-5.65,7.8-11.81,22.45-19.25,36.62-17.77h0Z"
            />
            <path
              className="cls-5"
              d="m65.42,306.8c-11.76,2.22-23.11,7.47-34.89,2.26,2.94-.28,5.83.15,8.74-.13,2.83-.24,5.63-.7,8.48-1.2,5.73-.97,11.84-2.22,17.67-.93h0Z"
            />
            <path
              className="cls-5"
              d="m131.89,310.51c-11.46-1.96-23.18-1.2-34.11,2.77-3.62,1.31-7.12,2.94-10.62,4.83,11.16-11.39,30.43-14.63,44.73-7.6h0Z"
            />
            <path
              className="cls-5"
              d="m135.45,309.6c-12.52.08-25.13-3.03-36.16-8.96,12.32,1.97,24.34,4.97,36.16,8.96h0Z"
            />
            <path
              className="cls-5"
              d="m197.43,326.63c-8.19-2.68-17.7-1.84-26.19-1.86-14.57.05-29.71.19-44.25-.52,14.65-2.21,29.49-2.64,44.28-2.31,8.79.4,18.27.21,26.16,4.69h0Z"
            />
            <path
              className="cls-5"
              d="m231.93,334.41c-21.59-3.11-45.77-10.48-57.77-30.14,15.36,16.84,37.03,22.8,57.77,30.14h0Z"
            />
            <path
              className="cls-5"
              d="m272.84,346.42c-5.11-.07-12.36-.2-17.38.16-5,.24-12.24,1.22-17.27,1.89,10.36-5.84,23.68-6.61,34.65-2.04h0Z"
            />
            <path
              className="cls-5"
              d="m268.42,343.07c-10.97-.91-22.3-3.33-31.41-9.9-5.37-3.97-9.5-9.13-14.38-13.61,7.78,4.01,13.57,10.77,21.5,14.44,7.73,3.96,16.13,6.04,24.29,9.07h0Z"
            />
          </motion.g>
          <motion.g id="fleur_6" data-name="fleur 6" variants={flowersAnim}>
            <path
              className="cls-3"
              d="m454.91,366.96c3.14-8.53,8.37-16.28,15.12-22.36,2.65-2.39,5.64-4.58,9.1-5.45s7.5-.19,9.9,2.45c3.21,3.52,2.45,9.38-.49,13.13s-7.48,5.84-11.92,7.54c-6.43,2.47-13.06,4.42-21.71,4.69Z"
            />
            <path
              className="cls-3"
              d="m494.51,374.69c1.9-1.44,4.47-1.59,6.86-1.5,7.27.26,14.69,2.27,20.3,6.91,2.19,1.82,4.2,4.41,3.8,7.23-.51,3.55-4.8,5.5-8.32,4.84s-6.4-3.13-9.09-5.51c-4.55-4.01-9.09-8.03-13.54-11.97Z"
            />
            <path
              className="cls-3"
              d="m572.15,343.95c9.74-4.07,20.32-6.59,30.65-4.44,1.22.25,2.46.61,3.43,1.39s1.62,2.07,1.34,3.28c-.31,1.34-1.61,2.19-2.87,2.74-6.05,2.66-13.01,1.48-19.51.25-2.99-.56-5.98-1.13-8.97-1.69-2.05-.39-4.32-2.39-4.07-1.53Z"
            />
            <path
              className="cls-3"
              d="m573.53,342.65c1.66-1.29,1.05-4.43.95-6.53-.15-3.11-.52-6.23-.3-9.33s1.1-6.28,3.14-8.63c1.66-1.91,4.62-3.16,6.72-1.74,1.93,1.31,2.07,4.09,1.65,6.38-1.4,7.63-5.89,14.61-12.16,19.84Z"
            />
            <path
              className="cls-3"
              d="m603.9,323.09c-1.38-6.32-.49-12.91.92-19.22.95-4.27,2.42-8.89,6.13-11.22.91-.57,1.96-.98,3.03-.87,1.41.14,2.6,1.17,3.39,2.35,2.63,3.95,1.56,9.31-.51,13.57-2.96,6.1-7.67,11.16-12.96,15.39Z"
            />
            <path
              className="cls-3"
              d="m487.25,372.24c16.58-4.6,31.78-14.06,43.21-26.92,2.28-2.57,4.42-5.26,6.55-7.96,1.76-2.22,3.52-4.45,5.29-6.67"
            />
            <path
              className="cls-3"
              d="m532.75,342.65c4.25-2.49,12.33-.67,16.03-2.83"
            />
            <path
              className="cls-3"
              d="m532.75,323.56c2.49,1.77,3.99,4.83,3.88,7.88-.05,1.47-.46,2.91-.86,4.33-.56,1.99-1.13,3.97-1.69,5.96"
            />
            <ellipse
              className="cls-3"
              cx="549.75"
              cy="321.33"
              rx="11.55"
              ry="4.15"
              transform="translate(-56.9 514.88) rotate(-48)"
            />
            <ellipse
              className="cls-3"
              cx="533.47"
              cy="314.78"
              rx="7.95"
              ry="8.78"
            />
            <ellipse
              className="cls-3"
              cx="559.04"
              cy="335.38"
              rx="10.5"
              ry="4.69"
              transform="translate(-77.35 193.9) rotate(-18.43)"
            />
            <path
              className="cls-3"
              d="m649.06,297.29c.41.69.51,1.53.43,2.32s-.32,1.57-.57,2.33c-.27.82-.54,1.65-.97,2.4-.49.85-1.17,1.58-1.84,2.3-.05.05-.1.11-.17.1s-.13-.1-.07-.14"
            />
            <path
              className="cls-3"
              d="m628.54,312.05c4.48-.09,8.32,2.01,12.55,3.51s8.13,4.1,10.74,7.75c.29.4.57.87.48,1.35"
            />
            <ellipse
              className="cls-3"
              cx="652.77"
              cy="288.61"
              rx="10.13"
              ry="9.87"
            />
            <ellipse
              className="cls-3"
              cx="654.81"
              cy="330.7"
              rx="7.12"
              ry="7.06"
            />
            <ellipse
              className="cls-3"
              cx="677.4"
              cy="309.25"
              rx="7.25"
              ry="6.62"
            />
            <path
              className="cls-5"
              d="m456.76,367.15c5.2-9.27,13.95-19.3,25.53-18.68-10.95,2.25-18.03,11.16-25.53,18.68h0Z"
            />
            <path
              className="cls-5"
              d="m496.36,375.21c9.03-2.65,15.9,3.72,18.91,11.73-1.43-1.43-2.7-2.95-4.02-4.36-2.58-2.76-5.48-5.25-9.13-6.31-1.79-.52-3.77-.69-5.76-1.06h0Z"
            />
            <path
              className="cls-5"
              d="m604.45,321.58c-.61-6.72,1.16-15.12,7.84-18.24.2-.1.65-.13.63.17-.1-.22-.37-.03-.46.08-4.86,4.85-5.83,11.75-8.01,17.99h0Z"
            />
            <path
              className="cls-5"
              d="m575.57,344.09c6.69-4.86,16.77-4.85,23.44.05-7.74-2.17-15.7-2.19-23.44-.05h0Z"
            />
            <path
              className="cls-5"
              d="m575.59,338.95c.52-5.45,2.39-10.79,3.19-16.19,2,5.44-.05,11.6-3.19,16.19h0Z"
            />
            <circle className="cls-5" cx="533.47" cy="314.78" r="2.5" />
            <circle className="cls-5" cx="548.5" cy="322.58" r="1.25" />
            <circle className="cls-5" cx="558.66" cy="335.83" r="2" />
            <ellipse
              className="cls-5"
              cx="655.41"
              cy="331.04"
              rx="2.25"
              ry="1.81"
            />
            <ellipse
              className="cls-5"
              cx="649.52"
              cy="290.18"
              rx="2.25"
              ry="1.81"
            />
            <ellipse
              className="cls-5"
              cx="675.15"
              cy="306.75"
              rx="2.25"
              ry="1.81"
            />
          </motion.g>
          <motion.g id="fleur_1" data-name="fleur 1" variants={flowersAnim}>
            <path
              className="cls-2"
              d="m349.95,13.43l-3.03,18.52c-.33,2.05,1.39,3.84,3.45,3.59l3.83-.46c2.37-.29,3.8-2.76,2.86-4.95l-7.11-16.69Z"
            />
            <path
              className="cls-2"
              d="m346.6,27.99c-8.29.68-16.8-2.2-22.98-7.76-1.29-1.16-4.52-2.47-5.12-4.11s-.45-3.64.76-4.89c1.55-1.61,4.15-1.46,6.32-.93s4.45,1.32,6.57.62c3.42-1.13,4.59-5.43,7.44-7.64,3.11-2.41,7.51-1.88,11.39-1.24,2.17.36,4.6.89,5.77,2.76.86,1.38.78,3.12.68,4.73-.37,5.64.24,16.11-1.1,16.92"
            />
            <path
              className="cls-2"
              d="m357.09,23.64c2.35-2.63,2.43-6.5,3.04-9.97s3.43-6.64,6.94-6.95c3.7-.33,6.48,4,5.84,7.66-.26,1.46-.89,2.91-.62,4.36.47,2.47,3.22,3.66,5.05,5.38,3.18,2.97,3.58,8.44.87,11.84-1.5,1.88-3.8,3.18-4.72,5.41-.83,2-.34,4.36-1.13,6.38-1.4,3.62-6.48,4.62-9.92,2.82s-6.15-7.56-7.85-7.1"
            />
            <path
              className="cls-3"
              d="m344.74,29.07c1.12,3.04-8.63-.17-10.86,2.84-1.06,1.42-1.74,3.43-3.46,3.85-1.81.44-3.75-1.24-5.45-.49-1.04.45-1.54,1.7-1.46,2.83s.63,2.17,1.19,3.15c1.63,2.81,3.59,5.54,6.3,7.33s6.32,2.5,9.27,1.13c2.2-1.02,3.76-3.01,5.53-4.67s4.38-2.1,6.75-1.58"
            />
            <path
              className="cls-3"
              d="m352.79,42.15c-1.77.1-3.57-.69-4.7-2.05s-1.57-3.27-1.15-5"
            />
            <path
              className="cls-3"
              d="m357.51,33.91c.53.91,1.03,2.53.82,3.56s-.72,1.99-1.33,2.85c-.46.65-.99,1.27-1.69,1.65s-1.58.48-2.27.4c.09-2.02-.16-4.05-.74-5.98"
            />
            <path
              className="cls-5"
              d="m359.25,36.49c2.17-2.89,3.55-5.77,4.47-9.26,2.1,3.45-.46,8.75-4.47,9.26h0Z"
            />
            <path
              className="cls-5"
              d="m359.37,37.7c.98-2,2.7-3.47,4.61-4.52,1.93-1.02,4.11-1.64,6.31-1.35-.92.65-1.82,1.15-2.71,1.66-2.57,1.37-5.5,3.09-8.21,4.21h0Z"
            />
            <path
              className="cls-5"
              d="m358.95,38.22c2.12-2.17,8.94-4.32,9.66.06-1.48-1.13-2.77-.72-4.43-.44-1.74.31-3.41.55-5.23.38h0Z"
            />
            <path
              className="cls-5"
              d="m339.3,22.34c1.62-.38,3.43.06,4.72,1.18.98.97,1.52,2.17,2.31,3.09-.17-.19-.74-.19-.96.03-.14.11-.19.2-.24.3,0,0,.08.65.08.65-2.02-3.79-1.72-8.96,1.19-12.15-.58,1.99-.86,3.93-.75,5.84.08,2.04.61,3.99,1.49,5.79-.07.2-.09.39-.18.6-.18.57-.95.9-1.43.68-.24-.09-.35-.19-.43-.25-1.02-1.06-1.37-2.53-2.14-3.63-.87-1.1-2.24-1.8-3.65-2.13h0Z"
            />
            <path
              className="cls-5"
              d="m333.87,38.33c-.09.27.04.53.21.7.6.54,1.36.15,2.11,0,3.17-.85,6.08-2.2,9.18-3.35,0,0,.7,1.87.7,1.87-3.83,1.44-6.53,5.03-7.32,9.13-.86-4.56,2.27-9.39,6.62-11,0,0,.7,1.87.7,1.87-3.09,1.13-6.37,2.32-9.73,2.45-.56-.01-1.15.07-1.78-.16-.64-.2-1.06-1.01-.68-1.5h0Z"
            />
            <circle className="cls-5" cx="336.84" cy="20.59" r=".72" />
            <circle className="cls-5" cx="336.84" cy="20.59" r=".72" />
            <circle className="cls-5" cx="364.83" cy="24.36" r=".72" />
            <circle className="cls-5" cx="337.84" cy="47.39" r=".72" />
            <circle className="cls-5" cx="332.34" cy="38.63" r=".72" />
            <circle className="cls-5" cx="344.61" cy="12.83" r=".72" />
            <circle className="cls-5" cx="370.29" cy="37.91" r=".72" />
            <circle className="cls-5" cx="373.11" cy="29.07" r=".72" />
            <g id="Fleur_1" data-name="Fleur 1">
              <path
                className="cls-2"
                d="m263.91,120.71c9.98-15.76,14.04-38.09,3.05-53.41"
              />
              <path
                className="cls-2"
                d="m266.27,116.98c-1.96-3.99-3.97-8.05-7.04-11.27s-7.44-5.51-11.86-5.02"
              />
              <path
                className="cls-2"
                d="m250.81,47.7c1.39-.12-.03-10.53,1.8-15.5.7-1.91,1.73-3.77,3.34-5.02s3.9-1.76,5.74-.88c3.81,1.81,3.83,8.12,7.78,9.61,3.71,1.4,7.89-2.68,11.57-1.21,3.21,1.28,3.54,5.73,2.71,9.09-1.35,5.42-2.49,5.91-8.56,14.13-4.48,4.78,11.59-9.34,18.85-9.23,2.68.04,5.46.6,7.58,2.25s3.39,4.57,2.52,7.11c-1.45,4.22-7.43,5.13-9.52,9.07-.71,1.33-.89,2.88-1.45,4.28-1.93,4.82-8.41,6.74-13.15,4.63s-9.92-5.51-11.3-10.05"
              />
              <path
                className="cls-2"
                d="m261.87,48.96l-2.42,14.83c-.36,2.2,1.65,4.06,3.82,3.52l5.22-1.28c2.49-.61,3.55-3.57,2-5.63l-8.61-11.45Z"
              />
              <path
                className="cls-2"
                d="m259.63,56.97c-3.57-1.14-6.71-3.56-8.73-6.72-.84-1.32-1.5-2.78-2.59-3.91s-2.77-1.88-4.23-1.31c-2.23.86-2.44,3.98-1.71,6.26.53,1.62,1.33,3.15,1.73,4.81s.31,3.56-.8,4.86c-2,2.35-6.02,1.52-8.46,3.4-1.51,1.17-2.14,3.3-1.67,5.15s1.94,3.38,3.71,4.1c2.55,1.05,5.45.5,8.15-.05,4.24-.88,8.49-1.75,12.73-2.63,2.05-.42,4.63-1.48,4.48-3.57"
              />
              <path
                className="cls-3"
                d="m268.81,71.99c-2.68.6-5.67-.63-7.15-2.94"
              />
              <path className="cls-3" d="m272.8,70.24c.3-2-.06-4.1-1.02-5.88" />
              <path
                className="cls-5"
                d="m269.71,52.42c-.47-1.26-.83-2.64-1.2-3.86-.35-1.23-.79-2.58-1.07-3.9,3,.99,4.25,5.3,2.27,7.76h0Z"
              />
              <path
                className="cls-5"
                d="m272.69,52.9c-1.72-3.83,1.08-9.04,5.24-9.7-2.36,3.03-4,6.05-5.24,9.7h0Z"
              />
              <path
                className="cls-5"
                d="m275.35,63.8c1.45-1.03,3.53-1.06,5-.08"
              />
              <path
                className="cls-5"
                d="m276.21,62.5c3.23-2.15,6.74-4.38,10.61-4.23"
              />
              <ellipse
                className="cls-5"
                cx="238.62"
                cy="102.48"
                rx="1.85"
                ry="1.84"
              />
              <ellipse
                className="cls-5"
                cx="245.47"
                cy="100.63"
                rx="1.85"
                ry="1.84"
              />
              <ellipse
                className="cls-5"
                cx="240.47"
                cy="92.16"
                rx="1.85"
                ry="1.84"
              />
              <circle className="cls-5" cx="277.93" cy="41.92" r=".75" />
              <circle className="cls-5" cx="247.37" cy="67.3" r=".75" />
              <circle className="cls-5" cx="250.13" cy="60.72" r=".75" />
              <circle className="cls-5" cx="265.23" cy="41.92" r=".75" />
              <path
                className="cls-5"
                d="m256.73,63.44c-2.18,1.11-4.16,1.66-6.61,1.83.9-2.55,4.53-3.54,6.61-1.83h0Z"
              />
              <circle className="cls-5" cx="290.12" cy="58.26" r=".75" />
              <circle className="cls-5" cx="286.07" cy="66.55" r=".75" />
            </g>
            <g>
              <path
                className="cls-2"
                d="m282.24,159.11l-9.71,11.47c-1.44,1.7-.67,4.33,1.46,4.99l5.13,1.58c2.45.76,4.88-1.24,4.61-3.79l-1.5-14.25Z"
              />
              <path
                className="cls-2"
                d="m298.6,103.65c11.32-2.26,23.67,1.48,31.84,9.63"
              />
              <circle className="cls-5" cx="328.17" cy="121.52" r="2.27" />
              <circle className="cls-5" cx="291.89" cy="196.66" r="1" />
              <circle className="cls-5" cx="264.25" cy="188.84" r="1" />
              <circle className="cls-5" cx="297.6" cy="187.84" r="1" />
              <circle className="cls-5" cx="273.7" cy="152.79" r="1" />
              <circle className="cls-5" cx="286.44" cy="154.71" r="1" />
              <circle className="cls-5" cx="282.1" cy="150.79" r="1" />
              <path
                className="cls-3"
                d="m270.88,172.72c-2.27-.17-6.91-12.78-9.1-19.05-.67-1.93-1.36-3.93-1.15-5.96s1.57-4.1,3.58-4.42c2.41-.39,4.47,1.71,6.85,2.28,2.6.62,5.29-.69,7.38-2.35s3.88-3.73,6.19-5.07,5.45-1.79,7.54-.12c1.93,1.54,2.32,4.29,2.27,6.76s-.4,5.04.55,7.31c.9,2.14,2.82,3.68,4,5.67,1.9,3.2,1.62,7.56-.69,10.49s-11.34,6.47-14.62,4.58"
              />
              <path
                className="cls-3"
                d="m276.98,188.36c-5.01,5.78-9.58,12.14-12.03,19.39s-2.59,15.54.98,22.32"
              />
              <circle className="cls-5" cx="343.13" cy="121.52" r="2.27" />
              <circle className="cls-5" cx="337.56" cy="113.28" r="2.27" />
              <path
                className="cls-3"
                d="m295.22,171.74c.9,2.09,7.24.96,9.94,3.31s4.3,6.02,3.8,9.55-3.29,6.74-6.8,7.38c-1.4.25-2.99.18-4.02,1.16-1.51,1.43-.74,3.93-1.1,5.98-.51,2.91-3.59,4.88-6.54,4.83s-5.67-1.73-7.7-3.87c-3.94-4.13-5.78-10.17-4.81-15.8"
              />
              <path
                className="cls-3"
                d="m266.86,168.21c-1.94,1.19-5.96.42-7.57,1.72s-2.58,3.44-2.18,5.46,2.34,3.71,4.4,3.54c.76-.06,1.62-.34,2.24.11.61.44.62,1.4.26,2.06s-1,1.13-1.56,1.64c-1.9,1.73-3.08,4.24-3.2,6.81-.08,1.66.35,3.46,1.62,4.53,1.87,1.57,4.71.97,6.91-.08,4.28-2.05,7.86-5.55,10.01-9.79"
              />
              <path
                className="cls-5"
                d="m282.1,182.13c4.66-.26,8.59,5.43,6.67,9.69-1.15-3.99-3.34-7.2-6.67-9.69h0Z"
              />
              <path
                className="cls-5"
                d="m283.17,180.99c4.47-1.62,10.16,1.5,11.17,6.15-3.38-2.85-6.94-4.83-11.17-6.15h0Z"
              />
              <path
                className="cls-5"
                d="m283.69,180.36c1.5-5.42,9.92-2.43,13.6-1.15,1.52.59,3,1.29,4.38,2.21-1.66-.13-3.24-.41-4.82-.71-1.57-.3-3.13-.62-4.67-.97-2.9-.56-6.29-2.04-8.49.62h0Z"
              />
              <path
                className="cls-5"
                d="m277.02,161.77c-1.81-1.9-2.87-4.13-3.17-6.74,2.56,1.01,4.01,4.13,3.17,6.74h0Z"
              />
              <path
                className="cls-5"
                d="m271.37,181.41c-1.36,3.25-4.06,6.57-7.82,6.8,2.34-2.63,4.47-5.46,7.82-6.8h0Z"
              />
              <path
                className="cls-3"
                d="m271.03,174.59c-1.42-.55-.16,5.06.33,6.82s4.04,2.44,5.65,3.32"
              />
              <path
                className="cls-3"
                d="m283.11,177.12c.25,1.73-.2,3.56-1.21,4.98s-2.6,2.44-4.32,2.76"
              />
            </g>
            <circle className="cls-5" cx="302.25" cy="183.81" r="1" />
          </motion.g>
          <motion.g id="fleur_7" data-name="fleur 7" variants={flowersAnim}>
            <path
              className="cls-2"
              d="m514.24,459.63c7.5,3.61,11.04,6.67,16.89,11.3,9.32,7.38,21.65,10.06,33.54,9.83,4.07-.08,8.25-.46,12.14.75,7.82,2.43,12.44,10.5,19.56,14.55"
            />
            <path
              className="cls-2"
              d="m583.32,442.21c.92-.23,9.05-1.81,13.44-.54s8.64,3.43,13.21,3.48"
            />
            <path
              className="cls-2"
              d="m598.12,434.44c2.18-1.41,3.85-6.15,4.3-9.27s-.24-5.86,1.57-8.44"
            />
            <path
              className="cls-2"
              d="m443.61,424.09c4.44,7.76,15.31,7.5,24.02,5.46s16.41-7.04,24.82-10.08c9.41-3.4,19.72-4.28,29.57-2.53"
            />
            <path
              className="cls-3"
              d="m540.72,476.11c2.59-3.15,6.12-5.51,10.01-6.7,1.32-.4,2.89-.64,3.98.2,1.44,1.1,1.16,3.52-.13,4.8s-3.19,1.69-4.99,1.91c-2.97.38-5.98.42-8.88-.22Z"
            />
            <path
              className="cls-3"
              d="m542.79,478.86c3.26.81,6.82,1.81,8.72,4.58.23.33.43.69.45,1.09.05.9-.83,1.6-1.72,1.78-2.31.46-4.38-1.51-5.64-3.5s-2.46-4.11-1.81-3.95Z"
            />
            <path
              className="cls-3"
              d="m427.9,401.85c4.45-.91,9.29-1.78,13.38.19,1.08.52,2.16,1.37,2.31,2.57.17,1.43-1.17,2.7-2.59,2.93s-2.85-.3-4.15-.92c-3.19-1.52-6.1-3.61-8.95-4.77Z"
            />
            <path
              className="cls-3"
              d="m607.75,445c2.47-1.16,5.18-2.07,7.88-1.69s5.32,2.39,5.63,5.1c.04.35.03.72-.16,1.01-.13.2-.34.33-.54.44-1.45.77-3.25.58-4.77-.05s-2.83-1.66-4.18-2.58c-1.22-.83-2.48-1.59-3.86-2.23Z"
            />
            <path
              className="cls-3"
              d="m603.44,418.61c-.68-3.47.07-7.2,2.03-10.15.67-1,1.54-1.96,2.7-2.25s2.62.37,2.78,1.56c.12.9-.48,1.72-1.04,2.43-2.28,2.84-4.55,5.69-6.47,8.41Z"
            />
            <circle className="cls-5" cx="549.89" cy="405.91" r="1.69" />
            <circle className="cls-5" cx="562.9" cy="402.97" r="1.56" />
            <circle className="cls-5" cx="548.01" cy="399.13" r=".81" />
            <circle className="cls-5" cx="548.81" cy="392.56" r="1.08" />
            <circle className="cls-5" cx="557.59" cy="409.29" r="1" />
            <circle className="cls-5" cx="565.36" cy="417.94" r="1.31" />
            <ellipse
              className="cls-5"
              cx="546.07"
              cy="415.66"
              rx="2.75"
              ry="2.29"
            />
            <circle className="cls-5" cx="554.69" cy="424.83" r=".99" />
            <circle className="cls-5" cx="566.61" cy="424.67" r="1.25" />
            <circle className="cls-5" cx="561.31" cy="431.76" r="1.58" />
            <circle className="cls-5" cx="549.35" cy="436.75" r="1.62" />
            <circle className="cls-5" cx="542.02" cy="428.28" r="1.3" />
            <circle className="cls-5" cx="536.95" cy="435.18" r="2.38" />
            <circle className="cls-5" cx="536.86" cy="417.94" r="1.31" />
            <circle className="cls-5" cx="529.32" cy="424.26" r=".42" />
            <circle className="cls-5" cx="538.15" cy="408.8" r="1.2" />
            <circle className="cls-5" cx="536.3" cy="396.94" r="1.38" />
            <circle className="cls-5" cx="536.21" cy="402.95" r=".66" />
            <circle className="cls-5" cx="527.87" cy="402.66" r="1.87" />
            <circle className="cls-5" cx="525.75" cy="410.73" r=".73" />
            <circle className="cls-5" cx="524.62" cy="416.63" r="1.37" />
            <circle className="cls-5" cx="648.77" cy="382.41" r="4" />
            <circle className="cls-5" cx="678.69" cy="381.41" r="3" />
            <circle className="cls-5" cx="676.77" cy="396.94" r="3.15" />
            <circle className="cls-5" cx="658" cy="376.5" r=".75" />
            <circle className="cls-5" cx="669.51" cy="377.41" r="1" />
            <circle className="cls-5" cx="666.79" cy="384.41" r="1.72" />
            <circle className="cls-5" cx="686.05" cy="413.2" r="2.46" />
            <circle className="cls-5" cx="673.45" cy="416.31" r="2.94" />
            <circle className="cls-5" cx="660.19" cy="397.75" r="2.19" />
            <circle className="cls-5" cx="680.21" cy="424.54" r=".29" />
            <circle className="cls-5" cx="684.25" cy="387.07" r=".66" />
            <circle className="cls-5" cx="674.45" cy="434.34" r="1" />
            <circle className="cls-5" cx="662.19" cy="422.26" r="2" />
            <circle className="cls-5" cx="631.77" cy="379.91" r="1.5" />
            <circle className="cls-5" cx="652.25" cy="418.44" r="1.81" />
            <circle className="cls-5" cx="666.79" cy="431.62" r="1.72" />
            <circle className="cls-5" cx="662.88" cy="405.91" r="1.69" />
            <circle className="cls-5" cx="627.39" cy="425.41" r="2.88" />
            <circle className="cls-5" cx="648.24" cy="428.58" r="1.6" />
            <circle className="cls-5" cx="632.31" cy="393.52" r="2.05" />
            <circle className="cls-5" cx="640.95" cy="369.57" r="2.25" />
            <circle className="cls-5" cx="651.78" cy="410.13" r="1.33" />
            <circle className="cls-5" cx="644.77" cy="395.37" r="1.58" />
            <circle className="cls-5" cx="681.36" cy="426.84" r="1.44" />
            <circle className="cls-5" cx="682.97" cy="402.95" r="1.28" />
            <circle className="cls-5" cx="636.73" cy="415.57" r="2.37" />
            <circle className="cls-5" cx="624.91" cy="404.1" r="1.81" />
            <circle className="cls-5" cx="642.14" cy="407.1" r="1.19" />
            <circle className="cls-5" cx="620.72" cy="391.06" r="1.5" />
            <circle className="cls-5" cx="638.82" cy="384.89" r="1.25" />
            <circle className="cls-5" cx="657.04" cy="404.32" r=".22" />
            <circle className="cls-5" cx="652.25" cy="438.29" r="1.81" />
            <ellipse
              className="cls-5"
              cx="652.36"
              cy="404.1"
              rx="1.7"
              ry="1.81"
            />
            <circle className="cls-5" cx="523.24" cy="425.83" r="2.67" />
            <circle className="cls-5" cx="597.23" cy="507.45" r="1.87" />
            <circle className="cls-5" cx="610.37" cy="488.02" r="2.63" />
            <circle className="cls-5" cx="617.14" cy="513.69" r="2.63" />
            <circle className="cls-5" cx="629.22" cy="497.94" r="1.87" />
            <circle className="cls-5" cx="632.96" cy="489.33" r="1.31" />
            <circle className="cls-5" cx="642.64" cy="509.94" r="1.12" />
            <circle className="cls-5" cx="638.88" cy="495.98" r="1.25" />
            <circle className="cls-5" cx="631.09" cy="523.61" r="1.87" />
            <circle className="cls-5" cx="622.05" cy="498.26" r="2.28" />
            <circle className="cls-5" cx="617.53" cy="487.42" r="1.38" />
            <circle className="cls-5" cx="604.23" cy="520.36" r="1.38" />
            <circle className="cls-5" cx="607.02" cy="505.57" r="1.88" />
            <circle className="cls-5" cx="638.25" cy="519.61" r="1.25" />
            <circle className="cls-5" cx="632.4" cy="505.57" r="1.87" />
            <circle className="cls-5" cx="619.03" cy="525.58" r="1.5" />
            <circle className="cls-5" cx="621.87" cy="534.38" r="1.38" />
            <circle className="cls-5" cx="634.15" cy="535.13" r="1.75" />
            <circle className="cls-5" cx="614.17" cy="537.52" r="1.98" />
            <circle className="cls-5" cx="612.97" cy="528.39" r="1.55" />
            <circle className="cls-5" cx="591.46" cy="521.28" r="1.25" />
            <circle className="cls-5" cx="605.99" cy="533.37" r="1.76" />
            <circle className="cls-5" cx="603.18" cy="514.67" r="1.05" />
            <circle className="cls-5" cx="621.9" cy="507.39" r=".62" />
            <circle className="cls-5" cx="604.43" cy="493.5" r=".72" />
          </motion.g>
          <motion.g id="fleur_8" data-name="fleur 8" variants={flowersAnim}>
            <path
              className="cls-2"
              d="m447.87,443.33c-6.19-9.91-16.82-16.07-27.81-21.4-5.08,3.73-7.22,10.41-7.26,16.71-.04,6.3,1.68,12.45,2.88,18.64"
            />
            <path
              className="cls-2"
              d="m422.92,458.52c-1.35-4.13-2.13-8.45-2.32-12.79-.22-5.24.61-10.91,4.61-14.41,10.01,4.53,17.02,13.69,23.59,22.5"
            />
            <path
              className="cls-2"
              d="m442.41,457.7c-1.34-3.56-4.44-6.41-8.71-8.51-2.32,2.67-3.42,6.36-2.93,9.87.14,1.03.42,2.08.24,3.11"
            />
            <path
              className="cls-2"
              d="m432.97,513.8c-.14-8.19-.27-16.42.89-24.53s3.69-16.16,8.38-22.54c6.17.26,11.62,3.83,17.33,6.19,2.87,1.19,5.87,2.09,8.65,3.5s5.37,3.43,6.74,6.22"
            />
            <path
              className="cls-2"
              d="m475.71,496.89c-3.58-3.1-7.18-6.22-11.2-8.72s-8.51-4.4-14.28-6.43c-3.3,3.98-6.7,8.19-7.73,13.25-.75,3.68-.16,7.49.43,11.2.66,4.17,1.32,8.34,1.99,12.51"
            />
            <path
              className="cls-2"
              d="m467.19,503.52c-.15-.96-.66-1.82-1.24-2.59-2.56-3.46-6.68-5.71-11.39-7.15-2.16,4.11-4.39,8.58-3.7,13.17.45,3.01,2.12,5.67,3.75,8.24"
            />
            <path
              className="cls-2"
              d="m467.67,572.59c-7.2-15.56-6.46-34.55,1.62-49.08,7.18,1.36,13.92,4.42,20.49,7.63,5.16,2.52,10.72,5.66,12.53,11.11"
            />
            <path
              className="cls-2"
              d="m501.48,551.83c-6.64-7.07-15.11-12.41-24.98-14.85-1.27,3.51-2.14,7.19-2.22,10.92-.16,7.69,2.99,15.01,6.09,22.04"
            />
            <path
              className="cls-2"
              d="m498.16,558.1c-2.64-4.18-7.05-7.19-13.02-9.52-.92,5.84-.51,11.89,1.21,17.55"
            />
            <path
              className="cls-2"
              d="m510.85,598.13c-10.01-9.65-15.66-23.65-15.24-36.86,3.27.96,3.89.92,6.26,1.12,4.1.35,8.18,1.04,12.17,2.06,5.43,1.38,10.69,3.37,15.72,5.84"
            />
            <path
              className="cls-2"
              d="m509.79,574.88c2,8.74,2.06,14.03,7.75,20.95,1.13,1.38,2.36,2.75,2.92,4.45"
            />
            <path
              className="cls-2"
              d="m510.54,574.4c3.82-.6,7.71-1.2,11.55-.68,4.14.56,8.07,2.41,12.25,2.51"
            />
            <path
              className="cls-2"
              d="m528.01,592.79c-2.35-.87-4.04-2.94-5.31-5.1s-2.2-5.45-3.59-7.25c1.35-1.04,3.08-.5,4.79-.5s3.39.39,5.05.79c1.48.35,3.05.76,4.12,1.85"
            />
            <path
              className="cls-2"
              d="m550.75,602.31c-7.71-2.18-14.4-7.73-18.39-14.88,4.47-4.66,10.7-7.6,17.14-8.08,2.19-.16,4.53,0,6.32,1.27"
            />
            <path
              className="cls-2"
              d="m541.54,589.98c2.07-2.13,4.94-3.38,7.88-3.83s5.94-.17,8.86.43"
            />
            <path
              className="cls-2"
              d="m542.33,590.8c2.41,3.24,8.51,6.83,12.48,7.61"
            />
          </motion.g>
          <motion.g id="fleur_9" data-name="fleur 9" variants={flowersAnim}>
            <path
              className="cls-5"
              d="m422.75,640.42c2.31,3.66,4.51,7.61,6.2,11.95.96,2.46,1.76,5.21,1.65,7.51-.11,2.3-1.46,3.81-2.99,2.56-.79-.64-1.47-1.85-2.04-3.06-.84-1.78-1.54-3.7-2.05-5.64-.36,2.46-.78,5.06-2.05,6.21-.47.43-1.23.51-1.75-.43-.23-.41-.34-.91-.43-1.38-.78-4.2.01-7.74.95-10.78.8-2.6,1.72-5.06,2.52-6.95Z"
            />
            <path
              className="cls-5"
              d="m423.82,642.6c4.47,1.65,9.05,3.61,13.49,6.22,2.52,1.48,5.11,3.29,6.65,5.3s1.5,4.1-.63,4c-1.1-.05-2.5-.65-3.82-1.31-1.94-.98-3.87-2.16-5.66-3.48,1.44,2.3,2.93,4.73,2.72,6.49-.08.65-.64,1.19-1.73.72-.47-.2-.92-.55-1.33-.89-3.6-3.05-5.46-6.51-6.84-9.65-1.18-2.68-2.17-5.31-2.85-7.39Z"
            />
            <path
              className="cls-5"
              d="m414.64,580.18c.04,3.69-.05,7.57-.48,11.59-.25,2.28-.64,4.74-1.33,6.54-.69,1.8-1.72,2.55-2.07,1.04-.18-.78-.16-1.96-.08-3.12.11-1.7.32-3.46.63-5.18-.85,1.84-1.76,3.77-2.65,4.27-.33.18-.7,0-.68-.93,0-.4.09-.84.19-1.24.81-3.61,2.15-6.17,3.42-8.28,1.09-1.8,2.18-3.46,3.07-4.69Z"
            />
            <path
              className="cls-5"
              d="m413.8,577.05c4.63-.72,9.51-1.2,14.55-1.02,2.86.1,5.95.47,8.2,1.54s3.18,3.01,1.29,3.98c-.98.5-2.47.66-3.92.7-2.14.07-4.35-.05-6.5-.37,2.3,1.39,4.71,2.88,5.33,4.59.23.63-.01,1.41-1.17,1.53-.5.05-1.05-.05-1.56-.16-4.52-1-7.72-3.25-10.36-5.44-2.25-1.87-4.32-3.79-5.86-5.36Z"
            />
            <path
              className="cls-5"
              d="m420.4,641.62c4.45-1.58,9.18-2.99,14.21-3.79,2.85-.45,5.98-.7,8.42-.1,2.44.59,3.73,2.27,2.04,3.57-.87.67-2.32,1.11-3.74,1.43-2.1.48-4.31.79-6.51.9,2.55.88,5.22,1.85,6.16,3.38.35.56.26,1.36-.86,1.69-.49.14-1.05.16-1.58.15-4.67-.08-8.27-1.63-11.3-3.22-2.59-1.36-5.01-2.8-6.84-4.01Z"
            />
            <path
              className="cls-5"
              d="m414.6,576.96c3.98,2.53,7.93,5.44,11.38,9.04,1.96,2.05,3.83,4.48,4.45,6.96.62,2.48-.51,4.83-2.77,4.39-1.17-.23-2.38-1.11-3.46-2.06-1.6-1.4-3.06-3.02-4.32-4.76.37,2.79.71,5.76-.44,7.69-.42.71-1.32,1.24-2.25.54-.41-.3-.71-.76-.97-1.21-2.32-3.97-2.52-8.14-2.38-11.87.12-3.19.42-6.29.77-8.73Z"
            />
            <path
              className="cls-2"
              d="m433.66,578.83c5.35-2.36,11.25-3.46,17.08-3.17,1.95.1,4.99-.87,6.57.27s2.53,3.37,1.66,5.12c-.37.73-1.01,1.31-1.74,1.7-3,1.62-6.89.18-10.02,1.54,1.44,3.39,5.48,4.77,8.03,7.43.68.7,1.29,1.62,1.14,2.59-.1.67-.56,1.24-1.11,1.62-1.91,1.33-4.52.59-6.67-.29-5.75-2.36-13.03-5.69-18.02-9.4"
            />
            <path
              className="cls-2"
              d="m427.86,590.4c6.07,2.16,11.58,5.89,15.83,10.74,1.03,1.18,3.29,3.32,2.55,4.7-.51.95-1.67,1.34-2.73,1.54-4.54.81-9.43-.6-12.83-3.71.92,2.24,1.69,4.67,1.21,7.04s-2.61,4.56-5.02,4.3c-1.5-.16-2.78-1.21-3.6-2.49s-1.22-2.75-1.62-4.2c-1-3.62-1.11-7.52-2.99-10.85"
            />
            <path
              className="cls-1"
              d="m416.91,594.12c1.77,3.95,2.03,10.37,2.76,14.63.41,2.42.65,4.91.03,7.3-.19.75-.59,1.46-1.23,1.89-1.53,1.03-3.02-.07-3.93-1.28-1.11-1.5-1.78-3.32-1.88-5.19-.55,2.18-2.46,3.7-4.25,5.05-.55.41-1.16.85-1.85.79-1.29-.1-1.77-1.73-1.92-3.02-.28-2.41-.56-4.9.15-7.22.66-2.15.55-7.73.45-9.97-.18-3.82,7.92-13.29,8.57-12.36"
            />
            <path
              className="cls-2"
              d="m448.04,595.39c1.01,1.01,2.06,2.83,2.66,4.13.17.36.29.85-.03,1.09-.15.11-.35.12-.53.13-.81.04-1.64.16-2.36.55s-1.3,1.09-1.38,1.9c-.06.63.17,1.33-.15,1.88"
            />
            <path
              className="cls-2"
              d="m440.71,638.99c2.62-.47,5.14,0,7.51-1.2s4.96-.62,7.61-.83,5.55.71,7.01,2.93c1.17,1.78,1.24,4.12.57,6.13s-2.03,3.74-3.54,5.23c-1.46,1.44-3.28,2.76-5.33,2.72"
            />
            <path
              className="cls-2"
              d="m441.48,652.64c3.12-1.67,6.95-2.37,10.23-1.04s5.67,5.06,4.74,8.47c-.64,2.37-2.64,4.12-4.76,5.37-.4.23-.81.46-1.12.81-.92,1.04-1.49,3.01-2.36,4.09"
            />
            <path
              className="cls-2"
              d="m434.43,652.31c1.02,2.98,7.71,10.28,11.22,14.07.96,1.03,1.97,2.09,2.4,3.44s.04,3.06-1.23,3.68c-2.01.96-4.02-1.32-6.19-1.82-.14,1.28-.27,2.55-.41,3.83-.26,2.4-.72,5.13-2.78,6.38-2.14,1.3-5.11.19-6.56-1.85s-1.69-4.7-1.54-7.19c.1-1.72.36-3.44.78-5.11.47-1.89,1.14-3.77,1.2-5.72.1-3.08-1.33-5.99-2.72-8.74"
            />
            <path
              className="cls-2"
              d="m429.91,668.63c-1.62,2.37-2.46,6.26-4.09,9.22-.44.79-.9,1.62-1.65,2.12s-1.88.58-2.49-.09c-1.2-1.31.37-4.18-1.26-4.89-.38-.16-.83-.11-1.23,0-2.78.76-4.74,4.02-7.6,3.74-1.77-.17-3.17-1.81-3.44-3.57s.4-3.55,1.43-5,2.4-2.62,3.72-3.81c3.12-2.81,4.87-9.87,7.2-13.08"
            />
            <path
              className="cls-2"
              d="m431.39,681.23c-.68.81.28,2.27-.19,3.94-.36,1.29-1.76,2.17-3.1,2.29-.65.06-1.3-.07-1.92-.27-2.07-.66-3.82-2.05-5.4-3.53-2.08-1.95-3.96-4.11-5.6-6.44"
            />
            <path
              className="cls-2"
              d="m426.34,615.07c-.2.88-.06,1.8-.02,2.71s-.05,1.88-.63,2.58-1.79.91-2.37.22c-.17-.2-.27-.45-.45-.63s-.52-.26-.69-.07c-.06.07-.09.16-.12.24-.33.99-.61,2.02-1.21,2.87s-1.62,1.5-2.65,1.33c-1.28-.21-2.05-1.56-2.32-2.82s-.71-3.51-1.23-4.69"
            />
            <path
              className="cls-2"
              d="m315.21,541.67l-1.5-1.65c2.67,4.51,7.81,7.13,12.99,7.95s10.47.07,15.64-.78c5.82-.95,11.69-2.05,17.08-4.44,5.39-2.4,10.33-6.23,13-11.5"
            />
            <path
              className="cls-2"
              d="m347.5,616.52c4.49,1.92,7.97,5.82,9.93,10.29s2.48,9.48,2.11,14.35"
            />
            <path
              className="cls-2"
              d="m391.6,418.63c-.57,3.72-2.2,7.27-4.64,10.12-2.11,2.47-4.78,4.39-7.5,6.17-4.34,2.85-8.87,5.42-13.54,7.67"
            />
            <path
              className="cls-2"
              d="m367.35,442.21c.09,2.86-1.15,5.58-2.4,8.15-1.65,3.41-3.37,6.81-5.66,9.83s-5.21,5.64-8.71,7.08c-.76.31-1.6.57-2.4.38-1.17-.27-1.96-1.47-2.09-2.67s.26-2.39.75-3.49c2.98-6.8,9.21-11.53,15.19-15.94,1.75-1.29,3.5-2.59,5.32-3.34Z"
            />
            <path
              className="cls-2"
              d="m354.08,451.91c-.81,2.31-5.19,3.69-7.55,4.33s-4.85.57-7.29.67c-5.8.23-11.57,1.49-16.94,3.7-4.26,3.18,14.9-8.47,21.76-13.6,1.8-1.35,3.59-2.74,5.61-3.74,4.98-2.48,10.89-2.32,16.32-1.11"
            />
            <path
              className="cls-2"
              d="m372.03,531.96c-.14-3.31,1.35-4.15,4.59-4.82s6.62.49,9.41,2.27,5.14,4.15,7.69,6.27c6.69,5.55,14.82,9.35,23.37,10.9-5.94-1.61-11.92-3.23-18.04-3.86-3.32-.34-6.68-.38-9.96-1.01-6.84-1.31-11.55-7.15-17.06-9.75Z"
            />
            <path
              className="cls-2"
              d="m380.16,526.96c4.68-3.8,10.09-9.79,16.08-9.09,2.58.3,4.97,1.47,7.22,2.78,6.18,3.59,12.19,8.48,19.33,8.66-5.3-.65-10.76-.85-15.92.54-5.04,1.36-11.18,4.33-16.26,3.15"
            />
            <path
              className="cls-2"
              d="m360.63,637.75c2.41,1.49,4.35,3.71,5.52,6.29,2.36,5.21,1.45,11.37,3.43,16.74.76,2.05,1.94,4,2.15,6.17-1.49-2.8-3-5.61-4.87-8.16-.85-1.15-1.77-2.25-2.49-3.49-.8-1.39-1.33-2.92-1.78-4.46-.9-3.11-1.48-6.3-1.74-9.53-.09-1.15-.15-2.31-.22-3.57Z"
            />
            <path
              className="cls-2"
              d="m360.14,643.12c.97,2.52,3.02,14.96,3.12,17.66.13,3.42-.39,7.12,1.45,10-.44-2.12-2.23-3.63-3.71-5.22-.55-.59-1.08-1.22-1.55-1.88-.86-1.2-1.57-2.52-2.08-3.91-.57-1.54-.9-3.17-.84-4.81.08-1.99.74-3.89,1.25-5.81.89-3.37.55-11.32,2.85-11.78"
            />
          </motion.g>

          <motion.g id="fleur_14" data-name="fleur 14" variants={flowersAnim}>
            <path
              className="cls-2"
              d="m241.62,257.06c-11.39,4.03-24.25,3.74-35.45-.78"
            />
            <path
              className="cls-2"
              d="m185.12,225.4c-8.71,1.39-17.25-2.58-25.69-5.16-8.99-2.75-18.44-3.98-27.83-3.62"
            />
            <path
              className="cls-2"
              d="m159.04,183.33c-2.36-14.41-25.76-25-43.78-31.15"
            />
            <path
              className="cls-2"
              d="m134.05,216.57c-9.76-1.65-20.25,4.55-29.96,2.62-1.49-.3-2.99-.79-4.09-1.83s-1.7-2.73-1.1-4.12c.8-1.87,3.19-2.36,5.23-2.44,4.55-.18,9.13.48,13.44,1.93,6.03,2.02,12.4,4.75,16.49,3.84Z"
            />
            <path
              className="cls-2"
              d="m210.89,257.17c-3.37-.31-6.78-.23-10.12-.78s-6.71-1.86-8.91-4.43c-.63-.73-1.07-2.01-.26-2.52.33-.21.76-.18,1.15-.13,4.38.58,8.1,3.35,12.11,5.2,2.49,1.14,5.11,1.94,6.03,2.67Z"
            />
            <path
              className="cls-2"
              d="m213.01,259.42c-6.05,4.37-12.27,8.83-19.46,10.83-.98.27-2.03.5-3.01.23s-1.86-1.19-1.74-2.2c.13-1.09,1.25-1.74,2.24-2.21,5.44-2.61,10.94-5.23,16.82-6.57,1.61-.37,3.29-.64,5.15-.06Z"
            />
            <path
              className="cls-2"
              d="m203.46,212.55c-.44-2.71-4.4-4.63-6.23-6.68-2.72-3.03-5.87-5.66-9.33-7.8-4.68-2.89-10.15-5.07-13.18-9.66-.72-1.09-1.25-2.61-.43-3.62.94-1.16,2.8-.68,4.13,0,5.27,2.67,9.7,6.77,13.43,11.35s9.07,9.23,11.61,16.41Z"
            />
            <path
              className="cls-2"
              d="m118.88,153.38c-9.78-2.36-19.11-6.56-27.35-12.33-2.87-2.01-5.73-4.37-6.96-7.64-.66-1.74-.62-4.07.95-5.06,1.01-.63,2.3-.5,3.47-.25,6.41,1.39,11.81,5.69,16.31,10.46s8.39,10.12,13.58,14.83Z"
            />
            <path
              className="cls-2"
              d="m138.52,161.46c-8.08-5.03-15.77-11.7-18.76-20.72-.46-1.4-.48-3.44.95-3.79,1.09-.27,2.03.77,2.67,1.69,5.82,8.31,11.51,16.7,15.13,22.82Z"
            />
            <path
              className="cls-2"
              d="m160.7,190.13c-2.36-3.61-6.77-8.38-10.7-10.17-2.76-1.25-6.15-1.5-8.16-3.77-1.12-1.27-1.62-3.07-1.3-4.74.05-.25.12-.5.28-.69.14-.17.34-.28.53-.37,1.54-.7,3.38-.44,4.88.32s2.73,1.98,3.85,3.24c2.68,3,5,6.43,8.33,8.68"
            />
            <path
              className="cls-2"
              d="m187.19,178.92c-3.6-2.92-7.72-5.13-11.35-8.02s-6.85-6.67-7.65-11.24c-.19-1.09-.1-2.44.87-2.96.42-.22.92-.23,1.39-.16,2.35.33,4.13,2.25,5.61,4.11,4.55,5.71,8.3,12.06,11.12,18.27Z"
            />
            <path
              className="cls-2"
              d="m191.18,180.15c-.17-4.92-3.57-9.17-4.84-13.93-1.34-5.02-2.23-10.58.05-15.25.51-1.04,1.2-2.04,2.21-2.61s2.39-.6,3.25.16c.48.42.75,1.02.98,1.62,2.72,7.18.15,15.13-.26,22.79-.23,4.27-1.17,14.04-1.4,7.21Z"
            />
            <path
              className="cls-2"
              d="m153.79,218.73c-10.18-4.54-20.37-9.07-30.55-13.61-2.98-1.33-4.49-3.63-7.11-5.56-1.26-.93-2.49-2.77-1.38-3.88.58-.57,1.5-.56,2.3-.47,8.23.98,14.91,6.78,21.56,11.72,6.15,4.57,11.2,9.57,15.17,11.79Z"
            />
            <path
              className="cls-2"
              d="m166.19,222.13c-8.91,1.94-17.29,12.37-27.73,12.87-.78.04-1.74-.05-2.07-.76-.16-.35-.11-.75-.03-1.12.49-2.1,2.15-3.78,4.05-4.81s4.04-1.5,6.15-1.96c6.26-1.36,12.52-2.73,19.63-4.22Z"
            />
            <path
              className="cls-2"
              d="m236.97,252.36c-10.63-6.42-20.96-14.14-27.24-24.86-5.06-8.64-7.14-18.63-10.42-28.09s-8.28-19.01-16.98-23.96"
            />
          </motion.g>
          <motion.g id="fleur_11" data-name="fleur 11" variants={flowersAnim}>
            <ellipse
              className="cls-2"
              cx="73.71"
              cy="439.41"
              rx="5.5"
              ry="14"
            />
            <path
              className="cls-2"
              d="m67.92,442.51c-14.54,3.64-29.63,5.06-44.59,4.19-1.12-.07-2.51-.34-2.82-1.42-.16-.57.05-1.16.26-1.71.48-1.27.96-2.55,1.44-3.82.15-.4.3-.85.15-1.25-.12-.32-.41-.54-.69-.74-2.5-1.75-5.78-3.4-6.04-6.45-.21-2.48,1.92-4.66,4.3-5.38s4.93-.33,7.39.07c3.83.62,7.65,1.24,11.48,1.85,9.84,1.59,19.69,3.18,29.36,5.6"
            />
            <path
              className="cls-2"
              d="m43.94,428.84c-5.6-3.99-13.09-7.86-19.04-12.75-1.76-1.45-3.67-3.79-2.48-5.74.99-1.62,3.33-1.53,5.21-1.27s4.26.24,5.13-1.44c1.11-2.15-1.39-4.89-.31-7.06.76-1.52,2.87-1.78,4.49-1.3,2.29.67,4.13,2.33,5.89,3.95,3.61,3.31,7.22,6.61,10.83,9.92,5.89,5.4,11.93,10.98,15.37,18.19"
            />
            <path
              className="cls-2"
              d="m52.96,413.29c-1.02-6.03-2.72-11.95-5.06-17.61-.4-.96-.82-1.94-.81-2.97s.57-2.15,1.56-2.44c1.93-.58,3.35,2.03,5.33,2.4,3.34.61,4.81-5.1,8.21-5.32,1.31-.08,2.54.75,3.29,1.82s1.12,2.36,1.47,3.62c2.25,8.06,3.19,16.6,6.76,24.17,2.09,4.45,1.02,7.89.65,8.56"
            />
            <path
              className="cls-2"
              d="m73.28,416.02c-2.59-9.4-1.03-20.42,3.07-29.26.61-1.3,1.37-2.66,2.67-3.27,2.18-1.01,5.07,1.19,4.67,3.56-.24,1.47-1.44,3.03-.61,4.27.59.89,1.88.94,2.91.7s2.08-.69,3.13-.48c1.45.29,2.37,1.82,2.47,3.3s-.45,2.9-.98,4.28c-2.15,5.54-4.31,11.08-6.46,16.62-1.78,4.59-3.61,9.25-6.67,13.11"
            />
            <path
              className="cls-2"
              d="m80.03,437.16c12.6-1.44,25.4-1.13,37.91.91,1.12.18,2.28.39,3.24,1s1.7,1.72,1.53,2.84c-.13.86-.74,1.57-1.39,2.15-1.28,1.17-2.8,2.09-4.43,2.69-.89.32-1.85.59-2.48,1.29-.91,1.02.45,2.59.83,3.9s.21,1.63.03,2.99c-.3,2.38-3.41,4.91-5.81,4.86s-4.61-1.24-6.71-2.4c-7.79-4.32-15.58-8.63-23.37-12.95"
            />
            <path
              className="cls-2"
              d="m110.41,425.27c.77.79.35,2.16.82,3.16.31.68,1,1.11,1.68,1.4s1.42.49,2.05.9c.46.3.88.76.88,1.3,0,.69-.61,1.2-1.17,1.6-.38.28-.76.55-1.18.76-.51.26-1.08.42-1.5.81s-.61,1.12-.2,1.52"
            />
            <path
              className="cls-5"
              d="m89.92,467.61c-2.87-1.35-4.23-4.79-3.01-7.73,2.65,1.66,3.82,4.72,3.01,7.73h0Z"
            />
            <path
              className="cls-5"
              d="m68.76,475.6c-.91-3.82.06-7.83,2.09-11.08.25-.42.56-.76.42-1.38,1.3.42,1.05,2.35,1.06,3.38-.24,3.33-1.24,6.57-3.57,9.09h0Z"
            />
            <path
              className="cls-5"
              d="m52.12,461.44c.28-3.26,2.84-5.84,5.79-6.89,1-.33,2.06-.51,3.2-.32-.65.96-1.32,1.67-1.99,2.34-2.14,2.03-4.22,3.68-7.01,4.87h0Z"
            />
            <path
              className="cls-5"
              d="m49.4,448.67c-.83,3.27-5.17,4.81-7.87,2.77,2.27-2.1,4.79-2.97,7.87-2.77h0Z"
            />
            <path
              className="cls-5"
              d="m66.42,437.78c-9.91,1.67-20.1,1.28-29.84-1.19,9.88-1.69,20.12-1.26,29.84,1.19h0Z"
            />
            <path
              className="cls-5"
              d="m61.06,429.77c-5.64-.66-9.98-4.71-13.51-8.88,5.65.61,10,4.7,13.51,8.88h0Z"
            />
            <path
              className="cls-5"
              d="m67.08,419.87c-1.28-2.29-2.53-5.26-3.62-7.58-1.06-2.35-2.5-5.21-3.41-7.68,5.46,2.55,8.63,9.45,7.03,15.26h0Z"
            />
            <path
              className="cls-5"
              d="m76.54,415.66c-.11-2.67-.92-4.56-.45-7.28.41-2.75,2.97-5.1,5.76-5.1-1.32,1.97-2.46,3.45-2.81,5.65-.41,2.12-.47,5.18-2.5,6.73h0Z"
            />
            <path
              className="cls-5"
              d="m87.6,427.66c3.66-3.48,7.75-6,12.53-7.64-2.03,4.79-7.35,8.01-12.53,7.64h0Z"
            />
            <path
              className="cls-5"
              d="m101.15,432.83c1.77-2.65,5.52-3.37,8.13-1.53-2.18,2.21-5.3,2.78-8.13,1.53h0Z"
            />
            <path
              className="cls-5"
              d="m88.83,444.1c5.67-3.35,14.15-.92,17.16,4.94-5.52-2.32-11.22-3.99-17.16-4.94h0Z"
            />
            <path
              className="cls-2"
              d="m89.67,450.14c4.64,3.5,9.9,8.02,14.55,11.52.89.67,1.81,1.37,2.29,2.37s.38,2.38-.53,3.01c-.59.41-1.37.42-2.09.42-1.44-.01-2.88-.02-4.32-.03-1.04,0-2.23.06-2.85.89-.34.46-.42,1.05-.48,1.62-.25,2.65-.22,5.62-2.08,7.53-.36.37-.79.69-1.3.76-.9.13-1.7-.52-2.34-1.16-2.35-2.37-4.19-5.21-5.84-8.12-3.24-5.7-5.82-11.77-7.69-18.06"
            />
            <path
              className="cls-2"
              d="m75.76,453.14c.1,2.2.19,4.4.48,6.58.86,6.59,3.4,12.87,4.22,19.46.35,2.85.36,5.84-.84,8.45-.2.44-.47.89-.92,1.07-.62.24-1.3-.15-1.82-.57-1.71-1.4-2.93-3.38-3.43-5.53-.14-.61-.23-1.25-.58-1.77-.75-1.09-2.39-1.16-3.6-.63s-2.16,1.51-3.25,2.26-2.51,1.26-3.72.73c-1.09-.49-1.69-1.73-1.75-2.92s.31-2.36.68-3.5c2.82-8.71,5.11-19.43,8.47-26.13"
            />
            <path
              className="cls-2"
              d="m66.07,460.27c-2.82,6.51-7.03,13.68-12.47,18.24-.69.58-1.51,1.14-2.4,1.01-1.37-.2-1.95-1.89-1.92-3.27.04-1.55.49-3.06.54-4.61s-.39-3.26-1.69-4.11c-1.34-.88-3.1-.57-4.66-.23-1.45.32-2.9.64-4.35.95-1.14.25-2.58.39-3.24-.57-.42-.62-.32-1.48.06-2.12s.98-1.12,1.57-1.58c4.07-3.16,8.14-6.32,12.22-9.48,2.01-1.56,4.02-3.12,6.24-4.36,3.74-2.08,7.94-3.18,12.08-4.25"
            />
            <path
              className="cls-2"
              d="m43.94,457.84c-1.28-1.39-5.18,1.38-7.76,2.19-.65.2-1.48.36-1.9-.18-.18-.23-.23-.54-.23-.84-.02-.82.24-1.63.7-2.3.36-.51.85-.96.95-1.57.14-.84-.56-1.61-1.34-1.95s-1.65-.36-2.48-.56-1.69-.62-2-1.41c-.48-1.23.67-3.88,1.93-4.28"
            />
            <path
              className="cls-2"
              d="m24.19,424.94c.59-.67-.01-1.76.31-2.59.39-1.01,2.03-1.36,1.97-2.44-.02-.4-.29-.73-.54-1.04-.28-.35-.57-.71-.68-1.15s.03-.96.42-1.18"
            />
            <path
              className="cls-2"
              d="m79.79,425.41c4.56-5.43,9.45-11.99,14.3-17.77,1.21-1.44,2.5-2.89,4.21-3.66s3.96-.66,5.2.75c1.64,1.87.72,4.87-.81,6.83-.56.71-1.2,1.42-1.36,2.31-.3,1.66,1.28,3.13,2.91,3.58s3.37.24,5.03.51,3.44,1.3,3.68,2.97c.28,1.95-1.61,3.48-3.33,4.44-2.39,1.33-4.9,2.43-7.4,3.53-7.35,3.23-15.04,6.53-23.06,6.1"
            />
            <circle className="cls-5" cx="73.71" cy="446.94" r="1.25" />
            <circle className="cls-5" cx="175.21" cy="384.49" r="1.25" />
            <circle className="cls-5" cx="70.78" cy="442.9" r="1" />
            <circle className="cls-5" cx="74.96" cy="431.34" r=".39" />
            <circle className="cls-5" cx="76.63" cy="439.33" r="1" />
            <circle className="cls-2" cx="180.13" cy="387.34" r="1" />
            <path
              className="cls-2"
              d="m178.43,396.73c-1,.75-2.46.35-2.87-.83-.86-2.48-2.87-4.66-4.78-6.58-1.91-1.93-3.82-3.86-5.72-5.78-.59-.6-1.23-1.31-1.14-2.15.07-.68.62-1.21,1.14-1.65,3.75-3.2,8.2-5.57,12.95-6.9,3.87-1.09,8.12-1.47,11.77.62,1.64.94,2.97,2.38,3.62,4.16,1.74,4.78-1.91,9.04-5.55,12.03-2.98,2.45-6.17,4.64-9.42,7.09Z"
            />
            <path
              className="cls-2"
              d="m175.29,499.61c-8.61-6.48-13.14-17.47-15.94-27.87s-3.53-21.22-5.05-31.89c-1.11-7.82-2.65-15.76-1.34-23.55,1.81-10.79,8.9-19.98,16.84-27.51"
            />
            <path
              className="cls-5"
              d="m157.2,470.33c-6.93-18.66-22.35-35.42-20.97-56.33.77,5.02,2.19,9.79,4,14.41,5.36,13.98,14.85,26.82,16.97,41.92h0Z"
            />
            <path
              className="cls-5"
              d="m165.4,482.19c-5.9-11.33-4-26.58,4.53-36.09-4.65,11.54-6.2,23.76-4.53,36.09h0Z"
            />
            <path
              className="cls-2"
              d="m168.46,388.01c-1.02.02-1.99.48-2.79,1.12s-1.44,1.18-2.07,1.98c-.59.75-1.17,1.51-1.58,2.36-.43.9-.65,1.88-.87,2.85-.03.13-.06.28-.01.41s.2.23.95.96c3.55-1.4,5.69-4.42,8.61-6.88"
            />
            <path
              className="cls-2"
              d="m162.08,480.48c-6.37-10.31-13.59-21.38-19.17-32.13-3.51-6.77-6.7-13.71-10.53-20.3-5.55-9.54-12.51-18.45-15.83-28.97-.86-2.74-1.47-5.67-.84-8.47.82-3.64,3.81-6.66,7.39-7.72s7.62-.24,10.64,1.96c1.88,1.38,3.37,3.23,4.68,5.16,2.84,4.19,4.97,8.83,6.87,13.52,1.66,4.08,3.17,8.26,3.81,12.62.56,3.78.47,7.63.67,11.45.5,9.46,2.86,18.83,7.23,26.85.99-8.23,3.34-16.29,6.94-23.76,1.35-2.8,3.05-5.69,5.88-6.99,1.41-.65,3.15-.82,4.47,0,1.43.87,2.04,2.62,2.44,4.24,1.86,7.42,1.73,15.34-.39,22.7-1.94,6.75-5.48,12.93-7.77,19.57s-4.31,15.25-1.09,21.49"
            />
            <path
              className="cls-2"
              d="m162.08,397.69c2.14-.5,9.58-3.73,10.81-4.96"
            />
            <circle className="cls-5" cx="71.28" cy="435.42" r=".5" />
            <circle className="cls-5" cx="182.78" cy="382.74" r=".5" />
            <path
              className="cls-2"
              d="m188.73,511.04c-6.81.81-13.43,3.17-19.22,6.85-2.97,1.88-5.71,4.11-8.71,5.94-4.34,2.66-9.2,4.47-14.22,5.32-4.44.75-9.99.17-11.95-3.88-1.47-3.06-.04-6.9,2.51-9.14s5.96-3.21,9.28-3.9c13.45-2.8,27.35-2.15,42.31-1.19Z"
            />
            <path
              className="cls-5"
              d="m177.21,511.78c-7.45,5.05-17.06,7.07-25.91,5.4,8.55-2.51,17.07-4.27,25.91-5.4h0Z"
            />
          </motion.g>

          <motion.g id="fleur_13" data-name="fleur 13" variants={flowersAnim}>
            <path
              className="cls-5"
              d="m52.47,272.93c9.84,7,23,9.05,34.49,5.38,5.09-1.62,10.35-4.98,11.3-10.25.59-3.25-.6-6.55-1.97-9.56-6.53-14.43-18.18-27.14-33.24-31.89,4.13,6.61,8.68,12.96,13.6,19-6.11-5.79-14.76-8.77-23.12-7.97,8.22,3.97,15.6,9.66,21.55,16.6-6.59-3.55-14.54-2.91-21.99-2.19,2.28,2.08,5.55,2.51,8.5,3.4,3.98,1.2,7.65,3.4,10.59,6.35-1.99,0-3.83-.96-5.74-1.53-4.7-1.39-10.09-.18-13.75,3.1,5.55.92,10.9,3.07,15.56,6.23-7.45-.32-14.9-.64-22.35-.96,2.44,2.52,5.44,4.49,6.56,4.29Z"
            />
            <path
              className="cls-3"
              d="m50.16,270.81c-2.67,3.92-5.46,7.78-8.8,10.97-3.34,3.19-7.28,5.72-11.57,6.63-1.38.29-3.14.2-3.73-1.24-.45-1.1.07-2.38.68-3.39,2.46-4.09,6.29-6.8,10.01-9.38,3.35-2.33,6.7-4.67,13.42-3.58Z"
            />
            <path
              className="cls-3"
              d="m55.1,271.86c-7.68-1.82-18.38-3.85-26.07-2.07,4-.48,8.09-.99,11.73-2.72,1.84-.88,3.53-2.05,5.44-2.76,4.18-1.56,12.35-.97,16.62.32"
            />
            <path
              className="cls-3"
              d="m47.29,261.67c-9.04-3.84-19.32-2.61-29.08-3.64-1.22-.13-2.47-.3-3.55-.88-.42-.22-.81-.51-1.05-.92-.55-.97.08-2.26,1.03-2.83s2.13-.61,3.24-.63c7.37-.14,15.05-.23,21.73,2.89,4.91,2.29,9.09,6.22,14.76,6.9-7.64-3.92-15.32-7.85-23.4-10.75-5.29-1.9-10.92-3.44-15.17-7.12-1.22-1.06-2.35-2.36-2.66-3.94-.06-.32-.09-.67.07-.96.29-.52,1.02-.56,1.62-.5,8.36.77,15.35,6.36,22.12,11.32,5.73,4.19,11.68,8.08,17.82,11.65.17.1.37.27.31.45s-.42-.08-.22-.12"
            />
            <path
              className="cls-3"
              d="m49.17,262.1c-1.6-1.43-4-1.35-6.13-1.1-4.34.52-8.63,1.47-12.79,2.82-4.52,1.47-9.1,3.46-13.83,3-1.53-.15-3.39-.99-3.31-2.52.07-1.48,1.84-2.16,3.29-2.45,10.83-2.12,22.07-2.1,32.77.24Z"
            />
            <path
              className="cls-3"
              d="m63.99,258.55c-5.24.86-10.86,1.38-15.62-.95-2.13-1.04-3.95-2.6-5.75-4.13-4.63-3.95-9.25-7.91-13.88-11.86-1.76-1.51-3.53-3.02-5.16-4.67-.68-.69-1.31-1.92-.53-2.5.47-.35,1.13-.15,1.67.07,9.52,3.9,17.79,10.31,25.46,17.17,3.59,3.21,10.3,5.67,13.81,6.87Z"
            />
            <path
              className="cls-3"
              d="m61.64,253.35c-4.23-6.63-16.29-11.67-25.18-15.05-4.94-1.88-10.08-3.8-13.8-7.57-1.04-1.05-1.95-2.77-.97-3.87.77-.86,2.14-.66,3.25-.35,15.02,4.2,27.4,16.68,36.69,26.84Z"
            />
            <path
              className="cls-3"
              d="m63.88,240.45c-2.24-4.7-10.93-5.81-17.02-7.35-5.77-1.46-11.38-3.49-16.91-5.69-4.37-1.73-9.17-4.07-10.63-8.54-.12-.36-.21-.76-.09-1.12.13-.41.5-.69.86-.92,3.01-1.92,6.87-2.2,10.35-1.41s6.67,2.57,9.67,4.51c8.81,5.72,16.42,13.08,23.76,20.51Z"
            />
            <path
              className="cls-3"
              d="m68.66,237.47c-2.65-4.61-8.71-7.03-13.01-10.15-4.37-3.17-9.69-5.69-12-10.58-.25-.53-.44-1.21-.05-1.65.22-.24.56-.33.88-.36,2.16-.21,4,1.46,5.48,3.04,6.35,6.76,12.8,15.99,18.7,19.7Z"
            />
            <path
              className="cls-3"
              d="m60.54,230.43c-4.37-8.77-5.89-14.63-8.96-23.94-.58-1.77-5.2-7.5-3.61-8.47,1.37-.83,3.14-.05,4.42.91,3.71,2.75,6.25,6.85,7.87,11.17s-2.34,8.66-1.56,13.21c.41,2.4,2.52,7.49,1.84,7.12Z"
            />
            <path
              className="cls-2"
              d="m169.22,285.21c-9.76-.63-19.19-2.43-28.45-5.58s-17.96-9.17-22.21-17.98c-.43-.89-.82-1.85-.7-2.83.24-1.95,2.5-3.13,4.46-2.88s3.61,1.5,5.12,2.76c10.59,8.86,19.77,20.9,33.27,23.81,6.07,1.31,20.93,4.2,8.51,2.71Z"
            />
            <path
              className="cls-2"
              d="m230.62,291.95c-4.2,3.54-9.63,5.59-15.12,5.72-1.32.03-2.79-.11-3.69-1.08-.2-.22-.38-.49-.41-.79-.06-.54.32-1.02.73-1.38.92-.79,2.06-1.26,3.21-1.64,3.25-1.09,6.7-1.59,15.27-.84Z"
            />
          </motion.g>
          <motion.g id="fleur_10" data-name="fleur 10" variants={flowersAnim}>
            <path
              className="cls-2"
              d="m300.94,406.26c-4.1,7.42-4.79,16.58-3.32,24.93,1.55,8.8,5.52,17.04,6.92,25.86"
            />
            <path
              className="cls-2"
              d="m278.3,440c-1.27,2.34-3.11,4.42-4.65,6.36-7.59,9.58-18.89,16.13-30.98,17.95"
            />
            <path
              className="cls-2"
              d="m265.35,470.03c.3,5.71.85,14.65,3.26,19.83,1.5,3.21,3.73,6.01,6.07,8.67,2.73,3.1,5.63,6.05,8.69,8.83"
            />
            <path
              className="cls-2"
              d="m256.2,526.74c-7.2,14.28-23.04,24.06-38.57,27.89-15.53,3.83-31.82,2.85-47.71,1.07"
            />
            <path
              className="cls-2"
              d="m225.84,552.05c-6.15,4.77-11.37,11.39-15.97,17.66s-7.91,13.65-8.55,21.41"
            />
            <path
              className="cls-2"
              d="m210.86,556c-7.27,2.76-15.4-1.84-20.92-7.32"
            />
            <path
              className="cls-2"
              d="m263.33,581.87c4.51,19.17-.97,35.75-13.42,51.01"
            />
            <path
              className="cls-2"
              d="m273.38,609.97c3.35,5.83,10.79,7.9,17.52,8.09,1.89.05,4.58-.37,6.37-.99,1.47-.51,2.85-1.39,4.41-1.48s3.34,1.17,3.01,2.69"
            />
            <path
              className="cls-2"
              d="m291.64,641.98c.66,1.61.2,4.68-.04,6.4s-.79,3.38-1.33,5.03c-.18.54-.38,1.12-.84,1.46"
            />
            <circle className="cls-2" cx="311.31" cy="660.58" r="2.5" />
            <circle className="cls-2" cx="306.24" cy="663.95" r="2.81" />
            <circle className="cls-2" cx="311.81" cy="666.1" r="2" />
            <circle className="cls-2" cx="293.72" cy="658.12" r="3.02" />
            <circle className="cls-2" cx="286.37" cy="655.39" r="2.69" />
            <circle className="cls-2" cx="287.62" cy="661.26" r="1.81" />
            <ellipse
              className="cls-2"
              cx="303.85"
              cy="619.42"
              rx="3"
              ry="2.38"
            />
            <ellipse
              className="cls-2"
              cx="308.77"
              cy="617.01"
              rx="2.53"
              ry="1.87"
            />
            <ellipse
              className="cls-2"
              cx="304.37"
              cy="613.48"
              rx="2.63"
              ry="3"
            />
            <circle className="cls-2" cx="248.31" cy="634.96" r="4.06" />
            <circle className="cls-2" cx="201.33" cy="593.89" r="3.87" />
            <circle className="cls-2" cx="164.68" cy="553.93" r="5.24" />
            <circle className="cls-2" cx="190.49" cy="546.56" r="2.12" />
            <circle className="cls-2" cx="239.54" cy="464.31" r="3.64" />
            <circle className="cls-2" cx="285.56" cy="508.1" r="3.87" />
            <path
              className="cls-2"
              d="m304.32,455.79c.55,3.86,3.66,5.57,7.02,7.02"
            />
            <circle className="cls-2" cx="315.19" cy="465.01" r="2.94" />
            <ellipse
              className="cls-2"
              cx="317.66"
              cy="454.04"
              rx="2.13"
              ry="1.75"
            />
            <circle className="cls-2" cx="322.65" cy="443.3" r="2.44" />
            <path
              className="cls-2"
              d="m312.75,453.05c-3.66-.66-7.12-2.33-9.78-6.29,3.3,1.15,6.91,1.42,10.34.77,1.84-.34,3.75-1.04,4.81-2.58"
            />
          </motion.g>
        </motion.g>
      </motion.svg>
    </FlowerWrapper>
  );
};

export default Flowers;

const FlowerWrapper = styled.div`
  .cls-1 {
    stroke-linecap: round;
  }

  .cls-1,
  .cls-2 {
    stroke-width: 3px;
  }

  .cls-1,
  .cls-2,
  .cls-3,
  .cls-4 {
    fill: none;
    stroke: #0045fa;
    stroke-miterlimit: 10;
  }

  .cls-5 {
    fill: #0045fa;
  }

  .cls-3 {
    stroke-width: 2px;
  }
  svg {
    height: 90vh;
    @media (max-width: 992px) {
      width: 100vw;
      height: auto;
    }
  }
  g {
    transform-origin: center;
  }
`;
