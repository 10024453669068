import React from "react";
import { Jules } from "./ServiceItems";
import jules from "../img/logo.svg";
import Carousel from "../Carousel";
import { Link } from "react-router-dom";
import { useStateContext } from "../useGlobalContext";
import { motion } from "framer-motion";
import carousel1 from "../img/carousel1.jpeg";
import carousel2 from "../img/carousel2.jpeg";
import carousel3 from "../img/carousel3.jpeg";
import styled from "styled-components";
import AdminTextEdit from "./AdminTextEdit";
import AdminImageEdit from "./AdminImageEdit";
import { TbExternalLink } from "react-icons/tb";

const ServiceWebDev = () => {
  const { french } = useStateContext();
  const backofficeData = [
    { image: carousel1 },
    { image: carousel2 },
    { image: carousel3 },
  ];
  const item = { img: jules, class: "Logo de Jules", content: null };
  return (
    <Section id={french ? `developpement-web` : "web-development"}>
      <motion.h2>Développement web</motion.h2>
      <div className="webcontainer">
        {french ? (
          <p className="static">
            Je développe ton site avec NextJS, un framework utilisé par les
            géants de l'industrie (ChatGPT, Spotify, Wall Street Journal...) qui
            est à la fine pointe de la technologie d'aujourd'hui. C'est la
            Ferrari des sites web. Avec des vitesse de chargement très rapide,
            il rend l'expérience très agréable, pour vous et vos clients.
          </p>
        ) : (
          <p className="static">
            I develop your site with NextJS, a framework used by the giants of
            the undustry (ChatGPT, Spotify, Wall Street Journal) that is at the
            cutting edge of today's technology. It's the Ferrari of websites.
            With very fast loading speeds, it makes the experience very
            enjoyable, for you and your customers.
          </p>
        )}
        <div className="overlay">
          <a
            href="https://www.nextjs.org/showcase"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              {french ? "Visiter" : "Visit"} Next.org <TbExternalLink />
            </span>
          </a>
          <div className="ifoverlay">
            <iframe
              className="nextjs-iframe"
              onClick={(e) => e.preventDefault()}
              src={"https://www.nextjs.org/showcase"}
              title="NextJS Showcase"
            ></iframe>
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "1px",
                zIndex: 5,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: "100%",
                  width: "100%",
                  height: "100px",
                  background:
                    "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)",
                  zIndex: 5,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="webcontainer">
        <AdminTextEdit
          newContent={
            french
              ? "<p>J'inclus un système de gestion de contenu intégré au site. Tu pourras changer le contenu du site à ta guise, sans passer par 1000 et une configurations. Tu auras simplement à cliquer sur la petite roulette pour changer la photo ou le texte. Essaie-le toi-même!</p>"
              : "<p>I include a content management system integrated into the site. You will be able to change the content of the site as you wish, without going through 1000 and one configurations. You will simply have to click on the little wheel to change the photo or text. Try it yourself!</p>"
          }
        />
        <div className="edit">
          <AdminImageEdit />
        </div>
      </div>
      <div className="webcontainer">
        {french ? (
          <p className="static">
            Tu auras également accès à un backoffice aussi simple ou complexe
            qu'il le faut. Au minimum, tu pourras ajouter, modifier ou supprimer
            des articles de blog, ajouter des photos dans une galerie et gérer
            des utilisateurs avec 2 niveaux de permissions. Au maximum... il n'y
            a pas de maximum ;) Tout sera personnalisé selon tes besoins.
          </p>
        ) : (
          <p className="static">
            You will also have access to a back office as simple or complex as
            necessary. At a minimum, you will be able to add, modify or delete
            blog articles, add photos to a gallery and manage users with 2
            levels of permissions. At most... there is no maximum ;) Everything
            will be customized according to your needs.
          </p>
        )}
        <Carousel data={backofficeData} />
      </div>
      <div className="webcontainer">
        {french ? (
          <p className="static">
            Mes tarifs sont compétitifs et transparents. Je fonctionne sur un
            tarif horaire de 40$ CAD. Je te donnerai une estimation du nombre
            d'heures nécessaires pour réaliser ton projet. Tu pourras suivre
            l'avancement du projet en temps réel et tu ne paieras que pour le
            temps réellement travaillé.
          </p>
        ) : (
          <p className="static">
            My rates are competitive and transparent. I operate on an hourly
            rate of $40 CAD. I will give you an estimate of the number of hours
            needed to complete your project. You will be able to follow the
            progress of the project in real time and you will only pay for the
            time actually worked.
          </p>
        )}
        <iframe
          title="lottie animation"
          src="https://lottie.host/embed/67df9772-4dea-46bb-91c5-17e086070664/nuSjB8sf0d.json"
        ></iframe>
      </div>
      <div className="closing-container">
        <Jules item={item} index={null} />
        <motion.div className="closing">
          {french ? (
            <p>
              Si tu as des questions contact moi et il me fera un grand plaisir
              d'y répondre. C'est un métier que j'adore, donc en parler et
              expliquer en détails les différents aspects, j'aime bien ça :)
            </p>
          ) : (
            <p>
              If you have any questions contact me and it will be my pleasure to
              answer them. It's a job I love, so I enjoy talking about it and
              explaining in detail the different aspects of it :)
            </p>
          )}
          <Link to={"/contact"} className="contact">
            <span>{french ? "Contactez moi!" : "Contact me!"}</span>
          </Link>
        </motion.div>
      </div>
    </Section>
  );
};

export default ServiceWebDev;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  padding-top: 27vh;
  margin-top: 10vh;
  gap: 20vh;
  color: var(--first-color);
  h2 {
    padding: 1rem;
    padding-left: 5vw;
    z-index: 15;
    width: fit-content;
    padding-top: 8vh;
    color: var(--fifth-color);
    font-size: var(--medium);
    margin-bottom: var(--medium);
    background: #f7f7f7;
    border-bottom-right-radius: 8px;
    position: sticky;
    top: 8rem;
  }
  .webcontainer {
    padding: 0 5vw;
    width: 100%;
    height: 100%;
    min-height: 60vh;
    margin-bottom: 10vh;
    gap: 5vw;
    padding-right: 10vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .edit {
      display: flex;
      flex-direction: column;
      width: 50vw;
      height: fit-content;
      gap: 5vw;
      position: relative;
      background: white;
    }
    .static {
      width: 30vw;
      height: fit-content;
      padding-top: 3vh;
      color: rgb(57, 57, 57);
      font-size: var(--small);
      margin-bottom: var(--small);
      background: #f7f7f7;
      z-index: 1;
      flex-shrink: 0;
      position: sticky;
      top: 44vh;
    }
    .overlay {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

      position: relative;
      width: 50vw;
      height: 50vh;
      overflow: scroll;
      a {
        position: absolute;
        width: fit-content;
        height: fit-content;
        padding: 8px 16px;
        border-radius: 4px;
        right: 4px;
        bottom: 4px;
        background: #f7f7f7;
        z-index: 1;
        overflow: hidden;
        span {
          display: flex;
          align-items: center;
          gap: 4px;
          z-index: 10;
          color: black;
          position: relative;
        }

        :hover:after {
          scale: 1 1;
          transform-origin: right;
        }
        :after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: #dfdfdf;
          z-index: 0;
          transition: scale 0.3s ease;
          scale: 0 1;
          transform-origin: left;
        }
      }
      .ifoverlay {
        border-radius: 8px;
        position: absolute;
        width: 50vw;
        height: 50vh;
        overflow: scroll;
        z-index: 0;
      }
    }

    iframe {
      width: 50vw;
      height: 50vh;
      border: 0;
      z-index: 0;
      flex-shrink: 0;
      border-radius: 8px;
      background: white;
      #animation {
        background: white;
      }
      pointer-events: none;
    }
    .nextjs-iframe {
      height: 500vh;
    }
  }
  .webcontainer:nth-child(even) {
    flex-direction: row-reverse;
    @media (max-width: 992px) {
      flex-direction: column;
    }
  }
  .closing-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .closing {
      width: 100%;
      max-width: 40vw;
      flex-grow: 0;
      display: flex;
      flex-direction: column;
      margin: 0;
      align-items: center;
      gap: 5vh;

      .contact {
        margin: 0 auto;
        position: relative;
        padding: 8px 64px;
        border-radius: 999px;
        color: white;
        font-size: var(--small);
        overflow: hidden;
        background: var(--main-color);
        span {
          z-index: 10;
          color: white;
          position: relative;
        }

        :hover:after {
          scale: 1 1;
          transform-origin: right;
        }
        :after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: #a0744d;
          z-index: 0;
          transition: scale 0.3s ease;
          scale: 0 1;
          transform-origin: left;
        }
      }
    }
  }

  @media (max-width: 992px) {
    padding: 0 5vw;
    padding-top: 15vh;
    gap: 5vh;
    h2 {
      top: 16vh;
      padding-bottom: 2vh;
      width: 100%;
      margin-bottom: 0;
      text-align: center;
      position: static;
    }
    .webcontainer {
      flex-direction: column;
      align-items: center;
      gap: 10vh;
      padding: 0 5vw;
      .edit {
        width: 90vw;
      }
      .static {
        width: 90vw;
        position: static;
      }
      iframe {
        width: 90vw;
        height: 50vh;
        top: 10.5rem;
      }

      .overlay {
        width: 90vw;
        .nextjs-iframe,
        .ifoverlay {
          width: 90vw;
        }
      }
    }
    .closing-container {
      flex-direction: column-reverse;
      gap: 10vh;
      .closing {
        max-width: 90vw;
        padding-bottom: 0;
      }
    }
  }
`;
