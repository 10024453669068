import React, { useMemo, useState } from "react";
import styled from "styled-components";

// Importing core components
import QuillEditor from "react-quill";

// Importing styles
import "react-quill/dist/quill.snow.css";

const Quill = ({ value, setContent }) => {
  const [editorHtml, setEditorHtml] = useState("");

  useMemo(() => {
    setEditorHtml(value);
  }, [value]);

  useMemo(() => {
    setContent(editorHtml);
  }, [editorHtml]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ["bold", "italic", "underline", "blockquote"],
          [{ color: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link"],
          ["clean"],
        ],
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    []
  );

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "clean",
  ];

  return (
    <Section>
      <QuillEditor
        theme="snow"
        value={editorHtml}
        formats={formats}
        modules={modules}
        onChange={setEditorHtml}
      />
    </Section>
  );
};

export default Quill;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 100%;
  height: fit-content;
  .quill {
    width: 100%;
    height: 300px;
  }
`;
