import React, { useRef } from "react";
import Shop from "../components/Shop";
import styled from "styled-components";
import { useStateContext } from "../useGlobalContext";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";

const WhySection = () => {
  const { french } = useStateContext();
  const isCpu = useMediaQuery({ minWidth: 992 });
  const ref = useRef(null);

  return (
    <WhyWrapper ref={ref}>
      <StickyWrapper>
        {isCpu && (
          <div className="shop">
            <Shop />
          </div>
        )}
        <div className="text">
          <motion.h2
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ amount: 0.4, once: true }}
          >
            {french ? "Pourquoi? " : "Why?"}
          </motion.h2>
          {!isCpu && (
            <aside className="shop">
              <Shop />
            </aside>
          )}
          {french ? (
            <motion.p
              initial={{ opacity: 0 }}
              whileInView={{
                opacity: 1,

                transition: { ease: "easeInOut", type: "tween" },
              }}
              viewport={{ amount: 0.4, once: true }}
            >
              « Nous pensons que les dernières années ont été difficiles pour
              les petites et moyennes entreprises. Celles-ci représentent le
              cœur de notre société, de notre quotidien et il est primordial de
              les voir prospérer. Nous avons nous-mêmes travaillé au sein de
              nombreuses compagnies, créer la nôtre et connaissons bien la
              réalité, ainsi que les difficultés auxquelles nous faisons face.
              Contribuer en partie à votre succès et vous aider à vous
              développer serait pour nous la plus grande des satisfactions. »{" "}
              <br />
              <br />
              Marine et Robert
            </motion.p>
          ) : (
            <motion.p
              initial={{ opacity: 0 }}
              whileInView={{
                opacity: 1,
                transition: { ease: "easeInOut", type: "tween" },
              }}
              viewport={{ amount: 0.4, once: true }}
            >
              "We think the last couple years were very difficult for small and
              medium sized businesses. They represent the heart of our society
              and daily life so it’s essential to see them thrive. We’ve worked
              within several businesses and created our own, so we're well aware
              of the hardships we can face. Contributing in small part to your
              success and helping you develop your business would mean a lot to
              us."
              <br />
              <br />
              Marine and Robert{" "}
            </motion.p>
          )}
        </div>
      </StickyWrapper>
    </WhyWrapper>
  );
};

export default WhySection;

const WhyWrapper = styled(motion.section)`
  background: #f7f7f7;
  margin: 10vh 0;
  height: 100vh;
  width: 100vw;
  padding: 0 5vw;
  @media (max-width: 992px) {
    height: fit-content;
  }
`;
const StickyWrapper = styled.div`
  top: 3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: space-evenly;
  }
  .shop {
    flex: 1.3;
    padding-top: 20vh;
    @media (max-width: 992px) {
      padding-top: 5vh;
    }
  }
  .text {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    height: fit-content;
    @media (max-width: 992px) {
      align-items: center;
    }
    h2 {
      position: relative;
      margin-bottom: var(--large);
      color: var(--fifth-color);
      text-transform: uppercase;
      @media (max-width: 992px) {
        font-size: 2rem;
      }
    }
    p {
      width: 45vw;
      line-height: 150%;
      font-style: italic;
      @media (max-width: 992px) {
        text-align: start;
        padding-top: 0;
        font-size: 1rem;
        width: 90vw;
      }
    }
  }
`;
