import React, { useEffect } from "react";
import styled from "styled-components";
import jules from "../img/logo.svg";
import { motion } from "framer-motion";

function Item({ item, index }) {
  useEffect(() => {
    const handleMouseMove = (e) => {
      const btn = e.currentTarget;
      const rect = btn.getBoundingClientRect();
      const h = rect.width / 2;

      const x = e.clientX - rect.left - h;
      const y = e.clientY - rect.top - h;

      const r1 = Math.sqrt(x * x + y * y);
      const r2 = (1 - r1 / h) * r1;

      const angle = Math.atan2(y, x);
      const tx = Math.round(Math.cos(angle) * r2 * 100) / 100;
      const ty = Math.round(Math.sin(angle) * r2 * 100) / 100;

      const op = r2 / r1 + 0.25;

      btn.style.setProperty("--tx", `${tx}px`);
      btn.style.setProperty("--ty", `${ty}px`);
      btn.style.setProperty("--opacity", `${op}`);
    };

    const handleMouseLeave = (e) => {
      const btn = e.currentTarget;
      btn.style.setProperty("--tx", "0px");
      btn.style.setProperty("--ty", "0px");
      btn.style.setProperty("--opacity", "0.25");
    };

    const gravityButtons = document.querySelectorAll(".icons-container");
    gravityButtons.forEach((btn) => {
      btn.addEventListener("mousemove", handleMouseMove);
      btn.addEventListener("mouseleave", handleMouseLeave);
    });

    // Clean up event listeners when the component unmounts
    return () => {
      gravityButtons.forEach((btn) => {
        btn.removeEventListener("mousemove", handleMouseMove);
        btn.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, []);

  return (
    <ItemStyle>
      <div className="icons-container">
        <img src={item.img} alt={item.class} className={item.class} />
      </div>
      <h4 className="numbered">{`0${index + 1}`}</h4>
      <p className="content">{item.content}</p>
    </ItemStyle>
  );
}

export default Item;

export function Jules() {
  useEffect(() => {
    const handleMouseMove = (e) => {
      const btn = e.currentTarget;
      const rect = btn.getBoundingClientRect();
      const h = rect.width / 2;

      const x = e.clientX - rect.left - h;
      const y = e.clientY - rect.top - h;

      const r1 = Math.sqrt(x * x + y * y);
      const r2 = (1 - r1 / h) * r1;

      const angle = Math.atan2(y, x);
      const tx = Math.round(Math.cos(angle) * r2 * 100) / 100;
      const ty = Math.round(Math.sin(angle) * r2 * 100) / 100;

      const op = r2 / r1 + 0.25;

      btn.style.setProperty("--tx", `${tx}px`);
      btn.style.setProperty("--ty", `${ty}px`);
      btn.style.setProperty("--opacity", `${op}`);
    };

    const handleMouseLeave = (e) => {
      const btn = e.currentTarget;
      btn.style.setProperty("--tx", "0px");
      btn.style.setProperty("--ty", "0px");
      btn.style.setProperty("--opacity", "0.25");
    };

    const gravityButtons = document.querySelectorAll(".icons-container");
    gravityButtons.forEach((btn) => {
      btn.addEventListener("mousemove", handleMouseMove);
      btn.addEventListener("mouseleave", handleMouseLeave);
    });

    // Clean up event listeners when the component unmounts
    return () => {
      gravityButtons.forEach((btn) => {
        btn.removeEventListener("mousemove", handleMouseMove);
        btn.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, []);
  return (
    <JulesContainer className="icons-container">
      <img src={jules} alt={"Logo de Jules"} />
    </JulesContainer>
  );
}

const JulesContainer = styled.div`
  display: grid;
  place-items: center;
  width: 40vw;
  height: 40vw;
  border-radius: 50%;
  z-index: 5;

  @media (max-width: 992px) {
    height: auto;
    width: 90vw;
  }
  & > img {
    font: inherit;
    max-width: min(15rem, 50vw);
    aspect-ratio: 1;
    background: none;
    transform: translate(var(--tx, 0), var(--ty, 0));
    cursor: default;
    transition: all 0.2s ease-out;

    &:focus-visible {
      outline: 2px solid #fff;
      outline-offset: 2px;
      opacity: 1;
    }
  }
  .marine-icon,
  .robert-icon {
    max-width: min(20rem, 60vw);
  }
`;

const ItemStyle = styled(motion.div)`
  position: relative;
  border-radius: 1rem;
  padding: 1rem;
  height: 80vh;
  width: 50vw;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  .content {
    width: min(400px, 80vw);
  }
  @media (max-width: 992px) {
    height: min(30rem, 90vh);
    width: 90vw;
  }

  .numbered {
    font-size: 20rem;
    position: absolute;
    top: 50%;
    left: -10%;
    opacity: 0.03;
    z-index: 0;
    cursor: default;
    @media (max-width: 992px) {
      font-size: 15rem;
      top: 50%;
      left: -5rem;
    }
  }

  .icons-container {
    display: grid;
    place-items: center;
    height: 60vh;
    aspect-ratio: 1;
    border-radius: 50%;
    z-index: 5;

    @media (max-width: 992px) {
      height: auto;
      width: 90vw;
    }
    & > img {
      font: inherit;
      max-width: min(15rem, 50vw);
      aspect-ratio: 1;
      background: none;
      transform: translate(var(--tx, 0), var(--ty, 0));
      cursor: default;
      transition: all 0.2s ease-out;

      &:focus-visible {
        outline: 2px solid #fff;
        outline-offset: 2px;
        opacity: 1;
      }
    }
    .marine-icon,
    .robert-icon {
      max-width: min(20rem, 60vw);
    }
  }
`;
