import React from "react";
import styled from "styled-components";
import logo from "../img/logo-jules.svg";
import logoText from "../img/logo-text-black.svg";
import PhoneNav from "./PhoneNav";
import { Link, useLocation } from "react-router-dom";
import { motion, useScroll, useTransform } from "framer-motion";
import { useStateContext } from "../useGlobalContext";
import { useMediaQuery } from "react-responsive";

const Nav = () => {
  const { pathname } = useLocation();
  const isPhone = useMediaQuery({ maxWidth: 992 });
  const { scrollY } = useScroll();
  const background = useTransform(
    scrollY,
    [0, 100],
    ["rgba(255,255,255, 0)", "#f7f7f7"]
  );
  const { french, setFrench } = useStateContext();

  return (
    <NavWrapper
      style={{
        top: "0vh",
        display: pathname === "/business-card" ? "none" : "flex",
        background: isPhone ? "#f7f7f7" : background,
      }}
    >
      <Link to={"/"} className="logo">
        <img
          className="logoDesktop"
          src={logo}
          alt="Logo de la compagnie Jules, une agence digitale pour le développement de sites web opérant à Sutton, QC."
        />
        <img
          src={logoText}
          className="logoPhone"
          alt="Logo de la compagnie Jules, une agence digitale pour le développement de sites web opérant à Sutton, QC."
        />
      </Link>
      {isPhone ? (
        <PhoneNav />
      ) : (
        <motion.ul className="mainMenu">
          <motion.li>
            <Link to="/">{french ? "Accueil" : "Home"}</Link>
            {pathname === "/" ? (
              <Line className="underline" layoutId="underline" />
            ) : null}
          </motion.li>
          <motion.li>
            <Link to="/about">{french ? "À propos" : "About"}</Link>
            {pathname === "/about" ? (
              <Line className="underline" layoutId="underline" />
            ) : null}
          </motion.li>
          <motion.li>
            <Link to="/projects">Portfolio</Link>
            {pathname === "/projects" ? (
              <Line className="underline" layoutId="underline" />
            ) : null}
          </motion.li>
          <motion.li>
            <Link to="/services">Services</Link>
            {pathname === "/services" ? (
              <Line className="underline" layoutId="underline" />
            ) : null}
          </motion.li>
          <motion.li>
            <Link to="/contact">Contact</Link>
            {pathname === "/contact" ? (
              <Line className="underline" layoutId="underline" />
            ) : null}
          </motion.li>
          <li>
            <div className="language">
              <p
                onClick={() => setFrench(true)}
                className={french ? `selected` : ""}
              >
                FR
              </p>
              <p className="line">|</p>
              <p
                onClick={() => setFrench(false)}
                className={french ? `` : "selected"}
              >
                EN
              </p>
            </div>
          </li>
        </motion.ul>
      )}
    </NavWrapper>
  );
};

export default Nav;

const NavWrapper = styled(motion.nav)`
  --margin: 1.618rem;
  width: 100vw;
  position: fixed;
  height: fit-content;
  color: var(--second-text-color);
  z-index: 50;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  padding: var(--margin) calc(var(--margin) * 1.618);
  @media (max-width: 992px) {
    justify-content: space-between;
    padding: 0 5vw;
    padding-top: 2vh;
  }
  .logo {
    width: 10rem;
    height: 10rem;
    padding: 16px;
    border-radius: 9999px;
    position: absolute;
    background: #f7f7f7;
    top: 1rem;
    left: 5vw;
    img {
      width: 100%;
      height: 100%;
    }
    .logoPhone {
      display: none;
    }
    @media (max-width: 992px) {
      width: 8rem;
      height: auto;
      .logoDesktop {
        display: none;
      }
      .logoPhone {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .mainMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 60vw;
    margin-left: auto;
    li {
      font-size: max(20px, 1.5vw);
      list-style: none;
      position: relative;
      padding: 0;
      line-height: unset;
      transition: all 0.2s ease-in-out;
    }
    :hover > li {
      opacity: 0.5;
    }
    :hover > li:hover {
      opacity: 1;
    }
    @media (max-width: 992px) {
      margin-right: 0;
    }
  }
  .language {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    cursor: pointer;
    p {
      padding: 0 0.2rem;
      font-size: max(20px, 1.5vw);
      font-weight: 300;
    }
    .selected {
      color: var(--main-color);
    }
  }
`;

const Line = styled(motion.div)`
  height: 0.3vh;
  background: var(--main-color);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  @media (max-width: 950px) {
    bottom: -40%;
    height: 0.4vh;
  }
`;
