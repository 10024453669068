import React, { useRef } from "react";
import styled from "styled-components";
import Bibli from "../components/Bibli";
import { motion } from "framer-motion";
import { useStateContext } from "../useGlobalContext";
import { useMediaQuery } from "react-responsive";

const WhatSection = () => {
  const { french } = useStateContext();
  const isCpu = useMediaQuery({ minWidth: 992 });
  const ref = useRef(null);

  return (
    <WhatWrapper ref={ref}>
      {isCpu && (
        <div className="bibli">
          <Bibli />
        </div>
      )}
      <div className="text">
        <motion.h2
          initial={{ opacity: 0, y: 500 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { ease: "easeInOut", type: "tween" },
          }}
          viewport={{ amount: 0.4, once: true }}
        >
          {french ? "Quoi?" : "What?"}
        </motion.h2>
        {!isCpu && (
          <aside className="bibli">
            <Bibli />
          </aside>
        )}
        {french ? (
          <motion.p
            initial={{ opacity: 0, y: -400 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { ease: "easeInOut", type: "tween" },
            }}
            viewport={{ amount: 0.4, once: true }}
          >
            Nous développons votre site internet, rapide et adapté pour votre
            téléphone, votre ordinateur et aux standards d’aujourd’hui. Des
            besoins en communication visuelle? Nous créons également des
            supports de communication imprimés (flyers, dépliants, brochures,
            affiches, cartes de visite) ou numériques (design de logos,
            templates pour médias sociaux).
          </motion.p>
        ) : (
          <motion.p
            initial={{ opacity: 0, y: -400 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{
              amount: 0.4,
              once: true,
              transition: { ease: "easeInOut", type: "tween" },
            }}
          >
            We develop your website, fast, responsive, adequate to today’s
            standards. Need some visuals? We also create printed communication
            tools (flyers, brochures, business cards, posters) or numeric
            communication tools (logo design, social media templates)
          </motion.p>
        )}
      </div>
    </WhatWrapper>
  );
};

export default WhatSection;

const WhatWrapper = styled(motion.section)`
  height: fit-content;
  width: 100vw;
  padding: 10vh 5vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 992px) {
    height: fit-content;
    flex-direction: column;
    align-items: center;
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: sticky;
    top: 20vh;
    @media (max-width: 992px) {
      align-items: center;
      justify-content: space-evenly;
    }
  }
  h2 {
    width: fit-content;
    margin-bottom: var(--large);
    text-align: center;
    color: var(--fifth-color);
    text-transform: uppercase;
    @media (max-width: 992px) {
      text-align: center;
      font-size: 2rem;
      padding-bottom: 10vh;
    }
  }
  p {
    position: sticky;
    top: 20vh;
    width: 45vw;
    font-size: 1.2rem;
    line-height: 150%;
    @media (max-width: 992px) {
      font-size: 1rem;
      text-align: start;
      width: 90vw;
    }
  }
`;
