import robert from "../img/icons/robert.svg";
import react from "../img/icons/react.svg";
import computerPhone from "../img/icons/computer-phone.svg";
import rocket from "../img/icons/rocket.svg";

import marine from "../img/icons/marine.svg";
import open from "../img/icons/open.svg";
import tools from "../img/icons/tools.svg";
import assist from "../img/icons/assist.svg";

import vision from "../img/icons/vision.svg";
import collab from "../img/icons/collab.svg";
import socials from "../img/icons/socials.svg";
import likes from "../img/icons/likes.svg";

export const servicesContent = {
  fr: [
    [
      {
        title: "Approche artistique",
        content:
          "Je m'inspire de votre vision pour créer des designs uniques qui reflètent votre personnalité ou celle de votre entreprise.",
        img: marine,
        class: "icons marine-icon",
      },
      {
        title: "Processus collaboratif",
        content:
          "Je suis ouverte à vos suggestions et vous invite à participer à chaque étape de la création pour que le résultat final soit le plus proche de vos attentes.",
        img: open,
        class: "icons open-icon",
      },
      {
        title: "Accompagnement sur mesure",
        content:
          "Je m'engage à vous aider à mettre en valeur votre projet en vous fournissant les outils nécessaires pour diffuser votre travail.",
        img: tools,
        class: "icons tools-icon",
      },
      {
        title: "Suivi après-vente",
        content:
          "Nous nous mettons à votre disposition pour vous assister, même après la livraison de votre projet, pour assurer votre satisfaction.",
        img: assist,
        class: "icons assist-icon",
      },
    ],
    [
      {
        title: "Approche stratégique",
        content:
          "Nous intégrons votre vision et vos objectifs dans une stratégie de gestion de communauté sur mesure, qui renforce votre marque et votre engagement envers vos clients.",
        img: vision,
        class: "icons vision-icon",
      },
      {
        title: "Collaboration continue",
        content:
          "Nous valorisons vos idées et suggestions, en vous invitant à participer à chaque étape du processus, pour que nos efforts correspondent le plus possible à vos attentes.",
        img: collab,
        class: "icons collab-icon",
      },
      {
        title: "Support personnalisé",
        content:
          "Nous nous engageons à renforcer votre présence en ligne, en vous fournissant les outils et conseils nécessaires pour interagir efficacement avec votre communauté.",
        img: socials,
        class: "icons socials-icon",
      },
      {
        title: "Suivi après-vente",
        content:
          "Nous restons à votre disposition pour vous assister, même après la mise en œuvre de votre stratégie de gestion de communauté, afin de garantir son efficacité et votre satisfaction.",
        img: likes,
        class: "icons likes-icon",
      },
    ],
  ],
  en: [
    [
      {
        title: "Artistic Approach",
        content:
          "I draw inspiration from your vision to create unique designs that reflect your personality or that of your company.",
        img: marine,
        class: "icons marine-icon",
      },
      {
        title: "Collaborative Process",
        content:
          "I am open to your suggestions and invite you to participate in each step of the creation so that the final result is as close as possible to your expectations.",
        img: open, // Copying the img value from the fr array
        class: "icons open-icon", // Copying the class value from the fr array
      },
      {
        title: "Customized Support",
        content:
          "I commit to helping you enhance your project by providing you with the necessary tools to showcase your work.",
        img: tools, // Copying the img value from the fr array
        class: "icons tools-icon", // Copying the class value from the fr array
      },
      {
        title: "After-sales Follow-up",
        content:
          "We are at your disposal to assist you, even after the delivery of your project, to ensure your satisfaction.",
        img: assist, // Copying the img value from the fr array
        class: "icons assist-icon", // Copying the class value from the fr array
      },
    ],
    [
      {
        title: "Strategic Approach",
        content:
          "We integrate your vision and goals into a tailored community management strategy, which strengthens your brand and your commitment to your customers.",
        img: vision, // Copying the img value from the fr array
        class: "icons vision-icon", // Copying the class value from the fr array
      },
      {
        title: "Continuous Collaboration",
        content:
          "We value your ideas and suggestions, inviting you to participate at each stage of the process, so that our efforts align as closely as possible with your expectations.",
        img: collab, // Copying the img value from the fr array
        class: "icons collab-icon", // Copying the class value from the fr array
      },
      {
        title: "Personalized Support",
        content:
          "We commit to enhancing your online presence, providing you with the necessary tools and advice to interact effectively with your community.",
        img: socials, // Copying the img value from the fr array
        class: "icons socials-icon", // Copying the class value from the fr array
      },
      {
        title: "After-sales Follow-up",
        content:
          "We remain at your disposal to assist you, even after the implementation of your community management strategy, to ensure its effectiveness and your satisfaction.",
        img: likes, // Copying the img value from the fr array
        class: "icons likes-icon", // Copying the class value from the fr array
      },
    ],
  ],
};
