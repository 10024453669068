import React, { useRef } from "react";
import Megaphone from "../components/Megaphone";
import jules from "../img/jules-profil.svg";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useStateContext } from "../useGlobalContext";
import { motion } from "framer-motion";

const HowSection = () => {
  const { french, anim, setAnim, talk } = useStateContext();
  const isCpu = useMediaQuery({ minWidth: 992 });
  const ref = useRef(null);

  const julesAnim = {
    show: {
      x: [0, 0, 0, 0, 0, isCpu ? -180 : -65, isCpu ? -180 : -65],
      transition: { duration: 5, repeat: Infinity, repeatType: "mirror" },
    },
    press: {
      x: [0, isCpu ? -180 : -70],
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <HowWrapper ref={ref}>
      <StickyWrapper
        whileInView={() => setAnim(true)}
        onViewportLeave={() => setAnim(false)}
        viewport={{ amount: 0.5 }}
      >
        {isCpu && (
          <aside className="mega">
            <div className="jules">
              <motion.img
                src={jules}
                alt=""
                animate={anim ? (talk ? "press" : "show") : ""}
                variants={julesAnim}
              />
            </div>
            <Megaphone />
          </aside>
        )}
        <div className="text">
          <motion.h2
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ amount: 0.4, once: true }}
          >
            {french ? "Comment?" : "How?"}
          </motion.h2>
          {!isCpu && (
            <aside className="mega">
              <div className="jules">
                <motion.img
                  src={jules}
                  alt=""
                  animate={anim ? (talk ? "press" : "show") : ""}
                  variants={julesAnim}
                />
              </div>
              <Megaphone />
            </aside>
          )}
          {french ? (
            <motion.p
              initial={{ opacity: 0 }}
              whileInView={{
                opacity: 1,

                transition: { ease: "easeInOut", type: "tween" },
              }}
              viewport={{ amount: 0.4, once: true }}
            >
              Avec la technologie qui continue de se déplacer à vitesse grand V,
              inutile de vous dire qu’avoir une présence sur internet amène une
              nouvelle visibilité. Web designer et graphique designer, nous vous
              aidons à avoir une meilleure communication, pour présenter les
              services et les produits de votre entreprise. Votre identité
              visuelle a une grande importance, en ligne ou imprimée sur des
              supports papier, elle reflètera votre image. Créatifs dans l'âme,
              nous serons à l'écoute pour faire naître votre projet.
            </motion.p>
          ) : (
            <motion.p
              initial={{ opacity: 0 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { ease: "easeInOut", type: "tween" },
              }}
              viewport={{ amount: 0.4, once: true }}
            >
              Technology is moving as fast as we can blink. Needless to say,
              having a presence on the web gives you a newfound visibility. Web
              designer and graphic designer, we help you develop better
              communication, to present your business' services and products.
              Your visual identity is crucial, online or printed on paper, it
              represents your company's image. Creatives at heart, we will
              support your project all the way.{" "}
            </motion.p>
          )}
        </div>
      </StickyWrapper>
    </HowWrapper>
  );
};

export default HowSection;

const HowWrapper = styled(motion.section)`
  height: 100vh;
  width: 100vw;
  padding: 10vh 5vw;
  margin: 10vh 0;

  @media (max-width: 992px) {
    height: fit-content;
    overflow: hidden;
  }
`;
const StickyWrapper = styled(motion.div)`
  top: 10vh;
  height: fit-content;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
  .mega {
    position: relative;
    margin-right: 5vw;
    @media (max-width: 992px) {
      margin-right: 10vw;
    }
    .jules {
      position: absolute;
      top: 10%;
      left: calc(100% + 13vw);
      @media (max-width: 992px) {
        top: 25%;
        width: 20vw;
        left: calc(100% + 15vw);
      }
      img {
        width: 25vw;
        @media (max-width: 992px) {
          width: 35vw;
        }
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 992px) {
      align-items: center;
    }
  }
  h2 {
    width: fit-content;
    text-align: start;
    text-transform: uppercase;
    color: var(--fifth-color);
    margin-bottom: var(--large);
    @media (max-width: 992px) {
      font-size: 2rem;
      text-align: center;
    }
  }
  p {
    width: 45vw;
    font-size: 1.2rem;
    line-height: 150%;
    @media (max-width: 992px) {
      font-size: 1rem;
      text-align: start;
      width: 90vw;
    }
  }
`;
