export const pageAnim = {
  show: {
    opacity: [0, 1],
    transition: { duration: 0.5, ease: "easeOut", staggerChildren: 0.3 },
  },
};

export const pageElementAnim = {
  show: {
    opacity: [0, 1],
    transition: { duration: 0.5, delay: 0.5, ease: "easeOut" },
  },
};
