import React from "react";
import styled from "styled-components";
import logo from "../img/logo-jules.svg";
import { BsInstagram, BsFacebook } from "react-icons/bs";

const BusinessCards = () => {
  return (
    <Wrapper>
      <Container>
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="links">
          <a href="https://www.instagram.com/j.cesardesign">
            <BsInstagram className="icons" /> @j.cesardesign
          </a>
          <a href="https://www.facebook.com/j.cesardesign">
            <BsFacebook className="icons" /> @j.cesardesign
          </a>
          <a href="mailto:contact@julesdigital.com">allo@julesdigital.com</a>
          <a href="tel:+13434222680">+1 (343) 322-2680</a>
        </div>
      </Container>
    </Wrapper>
  );
};

export default BusinessCards;

const Wrapper = styled.section`
  width: 100vw;
  min-height: 100vh;
  padding: 1.618rem;
  background: var(--second-color);
  padding: calc(1.618rem * 1.618rem);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;

  gap: 1.618rem;
  .logo {
    width: 12rem;
    margin-bottom: 1.618rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .links {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 1.618rem;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      gap: 0.618rem;
      min-width: fit-content;
    }
    .icons {
      font-size: 1.618rem;
      color: var(--main-text-color);
    }
  }
`;
