import React, { useState } from "react";
import Phone from "../components/Phone";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { AnimatePresence, motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
import { HiOutlineMail } from "react-icons/hi";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { useStateContext } from "../useGlobalContext";

const PageThree = () => {
  const { french } = useStateContext();
  const [menuIndex, setMenuIndex] = useState(0);
  const [menuOpen, setMenuOpen] = useState(0);
  const isCpu = useMediaQuery({ minWidth: 992 });

  const iconSize = isCpu ? 50 : 35;
  const contacts = [
    {
      icon: <HiOutlineMail size={iconSize} />,
      title: french ? "Courriel" : "Email",
      link: "mailto:allo@julesdigital.com",
      displayLink: "allo@julesdigital.com",
    },
    {
      icon: <HiOutlineDevicePhoneMobile size={iconSize} />,
      title: french ? "Téléphone" : "Phone",
      link: "tel:+13433222680",
      displayLink: "+1 (343) 322-2680",
    },
    {
      icon: <FaFacebookF size={iconSize} />,
      title: "Facebook",
      link: "https://www.facebook.com/j.cesardesign",
      displayLink: "@j.cesardesign",
    },
    {
      icon: <FaInstagram size={iconSize} />,
      title: "Instagram",
      link: "https://www.instagram.com/j.cesardesign/",
      displayLink: "@j.cesardesign",
    },
  ];
  return (
    <WrapperThree>
      <Helmet>
        <title>Contact | JULES Agence Digitale</title>
        <meta
          name="description"
          content="Contactez JULES Agence Digitale. Notre équipe à Sutton est prête à vous aider sur vos projets de développement web, gestion de communauté et design graphique."
        />
        <link rel="canonical" href="https://www.julesdigital.com/contact" />
      </Helmet>

      <div className="phone">
        <Phone />
      </div>
      <div className="menu" onMouseEnter={() => setMenuIndex(menuOpen)}>
        <div
          className="reset-menu"
          onMouseLeave={() => setMenuIndex(menuOpen)}
        />
        {contacts.map((contact, index) => {
          const t = 0.5;
          return (
            <div className="contact-container" key={index}>
              <AnimatePresence>
                {menuOpen === index ? (
                  <motion.div
                    className="contact-info"
                    initial={{ filter: "blur(50px)", opacity: 0 }}
                    animate={{
                      filter: ["blur(50px", "blur(0px)"],
                      opacity: [0.1, 1],
                      transition: { delay: t, duration: t },
                    }}
                    exit={{
                      filter: "blur(50px)",
                      opacity: 0.1,
                      transition: { duration: t },
                    }}
                  >
                    <h2>{contact.title}</h2>
                    <a href={contact.link} target="_blank" rel="noreferrer">
                      {contact.displayLink}
                    </a>
                    {contact.link2 && (
                      <a href={contact.link2} target="_blank" rel="noreferrer">
                        {contact.displayLink2}
                      </a>
                    )}
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </div>
          );
        })}
        {contacts.map((contact, index) => {
          return (
            <ContactMenu
              key={index}
              className="contact"
              $angle={isCpu ? `${80 - index * 30}deg` : `${index * 30}vw`}
              $delay={index * 0.2}
              $length={`${index * 25}vw`}
            >
              <motion.div
                className={menuOpen === index ? "icon open" : "icon"}
                onMouseEnter={() => setMenuIndex(index)}
                onClick={() => setMenuOpen(index)}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.5, delay: (index + 1) * 0.2 },
                }}
              >
                {contact.icon}
                {menuIndex === index ? (
                  <motion.div
                    layoutId="outline"
                    className={
                      menuIndex === index && menuOpen === index
                        ? "outline open-outline"
                        : "outline"
                    }
                  />
                ) : null}
              </motion.div>
            </ContactMenu>
          );
        })}
      </div>
    </WrapperThree>
  );
};

export default PageThree;

const WrapperThree = styled(motion.div)`
  --base-width: min(50vw, 50vh);
  height: 97vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5vw;
  padding-top: 15vh;
  h1 {
    font-size: 3rem;
    color: var(--fifth-color);
  }
  .phone {
    height: 80vh;
    position: fixed;

    z-index: -1;
    bottom: 0;
    right: -5vw;
    @media (max-width: 992px) {
      top: 12vh;
      height: 30vh;
      bottom: auto;
      right: auto;
      left: auto;
    }
  }
  .menu {
    width: var(--base-width);
    aspect-ratio: 1;
    position: fixed;
    bottom: -5%;
    left: 5vw;
    border-radius: 50%;
    @media (max-width: 992px) {
      right: auto;
      left: auto;
      bottom: 10vh;
      aspect-ratio: 2;
    }

    .reset-menu {
      width: calc(var(--base-width) + 42vh);
      aspect-ratio: 1;
      border-radius: 50%;
      position: absolute;
      top: -20vh;
      left: -20vh;
    }
  }
  .contact-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 15%;
    align-items: start;
    justify-content: center;
    @media (max-width: 992px) {
      margin-top: 0%;
      top: auto;
      bottom: 20vh;
    }
  }
  .contact-info {
    z-index: 10;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 30vw;
    h2 {
      color: var(--fifth-color);
      margin-bottom: var(--medium);
    }
    a {
      font-size: 1.5rem;
      display: block;
    }
    @media (max-width: 992px) {
      top: 0;
      width: 90vw;
      h2 {
        font-size: 6vh;
      }
      a {
        font-size: 3vh;
      }
    }
  }
`;

const ContactMenu = styled(motion.div)`
  @property --angle {
    syntax: "<angle>";
    initial-value: -40deg;
    inherits: true;
  }
  --icon-width: 10vh;
  position: absolute;
  left: calc(50%);
  top: calc(50%);
  animation: rotate 1s ease;
  animation-delay: ${(props) => props.$delay}s;
  @keyframes rotate {
    from {
      --angle: -40deg;
    }
  }
  --angle: ${(props) => props.$angle};
  --length: ${(props) => props.$length};
  --radius: 40vh;
  @media (max-width: 992px) {
    --radius: 30vw;
    --icon-width: 8vh;
    left: -25%;
    bottom: 0;
    top: auto;
  }
  .icon {
    cursor: pointer;
    display: grid;
    place-items: center;
    position: absolute;
    left: calc(50% - var(--icon-width) / 2);
    top: calc(50% - var(--icon-width) / 2);
    background: var(--sixth-color);
    border-radius: 50%;
    width: var(--icon-width);
    height: var(--icon-width);
    color: white;
    transition: background 0.5s ease;
    translate: calc(cos(var(--angle)) * var(--radius))
      calc(sin(var(--angle) * -1) * var(--radius));
    @media (max-width: 992px) {
      translate: var(--length) 0;
    }
  }
  .outline {
    width: calc(var(--icon-width) + 10px);
    height: calc(var(--icon-width) + 10px);
    position: inherit;
    left: calc(50% - (var(--icon-width) + 10px) / 2);
    top: calc(50% - (var(--icon-width) + 10px) / 2);
    border-radius: 50%;
    transition: outline 0.5s ease;
    z-index: -1;
    border: 3px solid var(--sixth-color);
  }
  .open-outline {
    border: 3px solid var(--fifth-color);
  }

  .open {
    background: var(--fifth-color);
  }
`;
