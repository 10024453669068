import React from "react";
import Nav from "./components/Nav";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import Legal from "./pages/Legal";
import NotFound from "./pages/NotFound";
import BusinessCards from "./pages/BusinessCards";
import GlobalStyles from "./GlobalStyles";
import ScrollTop from "./ScrollTop";
import { Route, Routes, useLocation } from "react-router-dom";
import { StateContext } from "./useGlobalContext";

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <GlobalStyles />
      <ScrollTop />
      <StateContext>
        <Nav />
        <Routes location={location} key={location.pathname}>
          <Route path="*" element={<NotFound />}></Route>
          <Route path="/business-card" element={<BusinessCards />}></Route>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/projects" element={<Projects />}></Route>
          <Route path="/services" element={<Services />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/legal" element={<Legal />}></Route>
        </Routes>
        {location.pathname !== "/projects" && <Footer />}
      </StateContext>
    </div>
  );
}

export default App;
