import React, { useEffect } from "react";

import Ai from "../components/Ai";

import styled from "styled-components";
import { motion } from "framer-motion";
import { pageAnim } from "../Animation";
import { Helmet } from "react-helmet-async";
import flower from "../img/coquelicot.svg";
import { useStateContext } from "../useGlobalContext";

const Home = () => {
  const { setFrench } = useStateContext();
  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;
    if (userLanguage.includes("fr")) {
      setFrench(true);
    }
  }, [setFrench]);

  return (
    <HomeWrapper initial="hidden" animate="show" variants={pageAnim}>
      <Helmet>
        <title>JULES Agence Digitale</title>
        <meta
          name="description"
          content="Découvrez JULES Agence Digitale, votre partenaire à Sutton et dans les Cantons-de-l'Est pour vous aider avec du développement web, de la gestion de communauté et du design graphique."
        />
        <link rel="canonical" href="https://www.julesdigital.com/" />
      </Helmet>
      <Ai />
      <div className="flower-container">
        <img src={flower} alt="flower" />
      </div>
    </HomeWrapper>
  );
};

export default Home;

const HomeWrapper = styled(motion.main)`
  height: 97vh;
  width: 100vw;
  .flower-container {
    width: 30vw;
    height: 50vh;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: -1;
    &.flower-container:hover > img {
    }
    img {
      transition: rotate 1s ease-in-out;
      position: absolute;
      left: -5vw;
      bottom: 0;
      height: 50vh;
      transform-origin: center bottom;
      animation: rotate ease-in-out infinite;
      animation-duration: 6s;
      rotate: 40deg;
      @keyframes rotate {
        50% {
          rotate: 36deg;
        }
      }
      @media (max-width: 992px) {
        rotate: 15deg;
        @keyframes rotate {
          50% {
            rotate: 10deg;
          }
        }
      }
    }
  }
`;
