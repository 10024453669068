import React from "react";
import { useStateContext } from "../useGlobalContext";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NotFound = () => {
  const { french } = useStateContext();
  return (
    <NotFoundWrapper>
      <h1>Oops! Page introuvable!</h1>
      <Link to="/">
        <button>{french ? "Accueil" : "Home"}</button>
      </Link>
    </NotFoundWrapper>
  );
};

export default NotFound;

const NotFoundWrapper = styled.section`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 1.5rem;
    text-align: center;
    width: 100vw;
  }
  button {
    font-size: 1.5rem;
  }
`;
