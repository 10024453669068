import React from "react";
import styled from "styled-components";

const Legal = () => {
  return (
    <LegalWrapper>
      <p>
        <strong>Mention légale</strong> :
        <br />
        <br />
        L'éditeur du site : <br /> <br /> Jules Agence Digitale <br />
        30 rue Levavasseur 35800 Dinard <br /> email: marine@julesdigital.com{" "}
        <br /> Responsable éditorial : Marine Ngo <br /> Webmaster : Robert
        Boulanger
        <br />
        <br />
        Hébergement : <br />
        <br /> Vercel Inc. 440 N Barranca Ave #4133 Covina, CA 91723,
        privacy@vercel.com
        <br />
        <br />
        <strong>Collecte de données personnelles </strong>:
        <br />
        <br />
        Sur le site https://www.julesdigital.com, le proprietaire du site ne
        collecte des informations personnelles relatives à l'utilisateur que
        pour le besoin de certains services proposés par le site
        https://www.julesdigital.com. L'utilisateur fournit ces informations en
        toute connaissance de cause, notamment lorsqu'il procède par lui-même à
        leur saisie. Il est alors précisé à l'utilisateur du site
        https://www.julesdigital.com l’obligation ou non de fournir ces
        informations. Conformément aux dispositions des articles 38 et suivants
        de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux
        fichiers et aux libertés, tout utilisateur dispose d’un droit d’accès,
        de rectification, de suppression et d’opposition aux données
        personnelles le concernant. Pour l’exercer, adressez votre demande à
        https://www.julesdigital.com par email : marine@julesdigital.com ou en
        effectuant sa demande écrite et signée, accompagnée d’une copie du titre
        d’identité avec signature du titulaire de la pièce, en précisant
        l’adresse à laquelle la réponse doit être envoyée. Aucune information
        personnelle de l'utilisateur du site https://www.julesdigital.com n'est
        publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue
        sur un support quelconque à des tiers. Les bases de données sont
        protégées par les dispositions de la loi du 1er juillet 1998 transposant
        la directive 96/9 du 11 mars 1996 relative à la protection juridique des
        bases de données.
        <br />
        <br />
        <br />
        <strong>Propriété intellectuelle</strong> :
        <br />
        <br />
        Toutes les images, textes, graphismes, logos, icônes, sons, vidéos et
        autres contenus présents sur ce site sont la propriété intellectuelle
        exclusive de Jules Agence Digitale ou de ses partenaires et sont
        protégés par les lois françaises et internationales relatives à la
        propriété intellectuelle.
        <br />
        <br />
        Toute reproduction, représentation, modification, publication,
        transmission, dénaturation, totale ou partielle du site ou de son
        contenu, par quelque procédé que ce soit, et sur quelque support que ce
        soit est interdite, sauf autorisation expresse et préalable de JULES
        Agence Digitale.
        <br />
        <br />
        Toute exploitation non autorisée du site ou de son contenu, des
        informations qui y sont divulguées engagerait la responsabilité de
        l'utilisateur et constituerait une contrefaçon sanctionnée par les
        articles L 335-2 et suivants du Code de la Propriété Intellectuelle.
      </p>
    </LegalWrapper>
  );
};

export default Legal;

const LegalWrapper = styled.div`
  padding: 15vh 5vw;
`;
