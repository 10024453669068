import React, { useRef } from "react";
import Item from "./ServiceItems";
import { motion, useScroll, useTransform } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

const ServicesSection = ({ items }) => {
  const isCpu = useMediaQuery({ minWidth: 992 });
  const scrollRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["0 0.9", "0.98 1"],
    margin: "100px",
  });

  function Title({ item, index, scrollRef }) {
    const lineX = useTransform(
      scrollYProgress,

      [index * (1 / items.length), (index + 1) * (1 / items.length)],
      [0, 1]
    );
    return (
      <div
        style={{ display: "flex", alignItems: "center", height: "fit-content" }}
      >
        <h4>{`0${index + 1}`} </h4>
        <motion.div
          style={{
            width: isCpu ? "25vw" : "60vw",
            height: "1px",
            scaleX: isCpu ? lineX : 1,
            marginLeft: "1vw",
            transformOrigin: "left",
            background: "var(--sixth-color)",
            transition: "all 0.3s ease",
          }}
        />
      </div>
    );
  }
  return (
    <Section>
      <div className="section-title">
        {items.map((item, index) => (
          <div key={index}>
            <Title item={item} index={index} />
            <h4 style={{ marginBottom: "2vh" }}>{item.title}</h4>
          </div>
        ))}
      </div>
      <div className="section-text" ref={scrollRef}>
        {items.map((item, index) => {
          return (
            <div key={index}>
              <Item item={item} index={index} />
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default ServicesSection;

const Section = styled(motion.div)`
  display: flex;
  width: 100vw;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 5vw;
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
  .section-title {
    position: sticky;
    top: 30vh;
    padding-top: 12vh;
    padding-bottom: 3vh;
    width: 40vw;
    h4 {
      font-size: var(--small);
      color: var(--second-text-color);
    }
    @media (max-width: 992px) {
      width: fit-content;
      position: static;
    }
  }
`;
