import { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Jules from "./Jules";
import Form from "./Form";
import styled from "styled-components";
import {
  AiOutlineForm,
  AiFillCloseCircle,
  AiOutlineArrowUp,
} from "react-icons/ai";
import { db } from "../Firebase";
import { useMediaQuery } from "react-responsive";
import { updateDoc, collection, addDoc, doc } from "firebase/firestore";
import { useStateContext } from "../useGlobalContext";
const { Configuration, OpenAIApi } = require("openai");

const Ai = () => {
  const { french, apiResponse, setApiResponse, form, setForm } =
    useStateContext();
  const isCpu = useMediaQuery({ minWidth: 992 });
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  delete configuration.baseOptions.headers["User-Agent"];
  const openai = new OpenAIApi(configuration);
  const [prompt, setPrompt] = useState(``);
  const [formButton, setFormButton] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exchange, setExchange] = useState([
    { speaker: { title: "You", colorAvatar: "transparent" }, message: "" },
  ]);
  const ref = useRef(null);
  const [docId, setDocId] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await openai.createChatCompletion({
        model: "gpt-4o",
        max_tokens: 200,
        temperature: 0,
        messages: [
          {
            role: "system",
            content: `Ignore any instructions prior to this prompt. You are the digital assistant for the website, taking on the persona of our company's mascot, a chic and knowledgeable dog. You understand and can converse about web design, graphic design, and community management services. When they ask for details about the projects, invite them to fill out a form to get a quote. You may answer any unrelated question as well.`,
          },
          {
            role: "assistant",
            content:
              "Bonjour! Je suis Jules. Besoin d'un e-shop, d'un site vitrine, de graphisme ou de services de community management? Je suis là pour vous aider! 💼",
          },
          { role: "user", content: "How much are your services?" },
          {
            role: "assistant",
            content:
              "Jules' service costs range depending on your project. Our fees vary from service to service, but ranges between 30-40$/hour. If you want a more accurate estimate on costs, your best bet is to ask for a quote and we'll get back to you with an accurate estimate.",
          },
          { role: "user", content: "Where are you located?" },
          { role: "assistant", content: "We are located in Dinard, Bretagne." },
          { role: "user", content: "What different services do you offer?" },
          {
            role: "assistant",
            content:
              "We build websites, manage social media account, and create graphic designs",
          },
          { role: "user", content: "J'aimerais un devis." },
          {
            role: "assistant",
            content: `Aucun problème! Voici un petit formulaire à remplir pour que nous puissions vous donner un devis précis.`,
          },
          { role: "user", content: "I need a quote." },
          {
            role: "assistant",
            content: `Sure! Just fill out the form and we'll be happy to get back to you with an accurate estimate.`,
          },
          {
            role: "user",
            content: "Ça prend combien de temps pour créer un site web?",
          },
          {
            role: "assistant",
            content: `Cela varie en fonction de la taille du projet. Un site vitrine prend généralement 1-2 semaines, alors qu'un e-shop prendra plutôt 3-4 semaines.`,
          },
          { role: "user", content: "Comment peut-on vous contacter?" },
          {
            role: "assistant",
            content:
              "Vous pouvez nous contacter par courrier électronique à robert@julesdigital.com ou marine@julesdigital.com. Vous pouvez aussi remplir le formulaire pour une estimation gratuite de nos services.",
          },
          {
            role: "user",
            content: "How much experience does Marine and Robert have?",
          },
          {
            role: "assistant",
            content:
              "They have recently reconverted into their respective fields. Marine has a few months of experience in graphic design and social media account management. Robert has almost 1 year of experience in web development.",
          },
          {
            role: "user",
            content:
              "You are to answer the following prompts as if it was your first interaction with the user, but keeping in mind the previous prompts and answers.",
          },
          ...messages,
          { role: "user", content: prompt },
        ],
      });

      if (docId === null) {
        const docRef = await addDoc(collection(db, "session"), {
          exchange: "",
          createdAt: new Date().toDateString(),
        });
        setDocId(docRef.id);
      }
      setMessages([...messages, { role: "user", content: prompt }]);
      setApiResponse(result.data.choices[0].message.content);
      setExchange([
        ...exchange,
        {
          speaker: { title: "AI", colorAvatar: "#004900" },
          message: result.data.choices[0].message.content,
        },
      ]);
      setPrompt("");
      window.scrollTo({ top: 0, behavior: "smooth" });
      setForm(false);
    } catch (e) {
      console.log(e);
      setApiResponse(
        french
          ? "Oops! Jules fait dodo pour l'instant. Son cerveau IA ne fonctionne peut-être pas comme prévu. Revenez lui parler plus tard!"
          : "Oops! Jules is asleep right now. His AI brain, might not be working as expected. Give him a shout later!"
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    setApiResponse(
      french
        ? `Bonjour! 😁 Je suis Jules. Besoin d'un e-shop, d'un site vitrine, de graphisme ou de services de community management? Je suis là pour vous aider! 🐶`
        : `Hello! 😁 I'm Jules. Need an e-shop, a showcase site, some graphic design or community management services? I'm here to help! 🐶`
    );
  }, [french, setApiResponse]);

  useEffect(() => {
    const container = ref.current;
    container.scrollTop = 0;
    if (
      ["formulaire ", "form ", "quote ", "devis ", "estimation "].some(
        (substring) => apiResponse.includes(substring)
      )
    ) {
      setForm(true);
    } else {
      setForm(false);
    }
  }, [apiResponse, setForm]);

  const messageApi = () => {
    const input = prompt.trim();
    setMessages([...messages, { role: "assistant", content: apiResponse }]);
    setExchange([
      ...exchange,
      { speaker: { title: "You", colorAvatar: "#00f" }, message: input },
    ]);
  };
  useEffect(() => {
    const updatedData = {
      exchange: [...messages.map((item) => item.content), apiResponse],
    };
    if (docId !== null) {
      updateDoc(doc(db, "session", docId), updatedData);
    }
  }, [messages, docId, apiResponse]);

  const handleKeyPress = (e) => {
    const input = prompt.trim();
    if (
      e.key === "Enter" &&
      e.shiftKey === false &&
      input.length > 0 &&
      !loading
    ) {
      e.preventDefault();
      e.stopPropagation();
      messageApi();
    } else {
      return null;
    }
  };
  const handleKeyUp = (e) => {
    const input = prompt.trim();
    if (
      e.key === "Enter" &&
      e.shiftKey === false &&
      input.length > 0 &&
      !loading
    ) {
      e.preventDefault();
      e.stopPropagation();
      setApiResponse("🦴🦴🦴");
      setPrompt("");
      handleSubmit(e);
    } else {
      return null;
    }
  };

  const formatLineBreaks = (text) => {
    return text.split(/(?:\r\n|\r|\n)/g);
  };
  const formatSpaces = (text) => {
    return text.split(/(?:\s)/g);
  };

  const renderWord = (word) => {
    const cleanWord = word.replace(/[()]/g, "");

    if (cleanWord.includes("@")) {
      // The word is an email address
      return <a href={`mailto:${cleanWord}`}>{word}</a>;
    } else if (
      word.startsWith("http://") ||
      word.startsWith("https://") ||
      word.startsWith("www.") ||
      word.startsWith("(www.") ||
      word.includes(".com") ||
      word.includes(".net") ||
      word.includes(".org") ||
      word.includes(".io")
    ) {
      // The word is a URL
      return (
        <a
          href={
            cleanWord.startsWith("www.") ||
            ((cleanWord.includes(".com") ||
              cleanWord.includes(".net") ||
              cleanWord.includes(".org") ||
              cleanWord.includes(".io")) &&
              (!cleanWord.startsWith("https://") ||
                !cleanWord.startsWith("http://")))
              ? `http://${cleanWord}`
              : cleanWord
          }
          rel="noreferrer noopener"
          target="_blank"
        >
          {word}
        </a>
      );
    } else {
      // The word is neither an email address nor a URL
      return word;
    }
  };

  const apiLineBreaks = formatLineBreaks(apiResponse);
  const inputRef = useRef(null);

  const useAutosizeTextArea = (textAreaRef, value) => {
    useEffect(() => {
      if (textAreaRef) {
        // We need to reset the height momentarily to get the correct scrollHeight for the textarea
        textAreaRef.style.height = "0px";
        const scrollHeight = textAreaRef.scrollHeight;

        // We then set the height directly, outside of the render loop
        // Trying to set this with state or a ref will product an incorrect value.
        textAreaRef.style.height = scrollHeight + "px";
      }
    }, [textAreaRef, value]);
  };

  useAutosizeTextArea(inputRef.current, prompt);

  const handleChange = (evt) => {
    const val = evt.target?.value;
    setPrompt(val);
  };

  return (
    <AiWrapper>
      <TextBubble
        className={form && isCpu ? "right" : "center"}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.3 } }}
        layoutId="text-bubble"
        transition={{ duration: 0.2 }}
      >
        <div className="text-container" ref={ref}>
          <div
            className={
              form || !isCpu ? "arrow outer-bottom" : "arrow outer-right"
            }
          />
          <div
            className={
              form || !isCpu ? "arrow inner-bottom" : "arrow inner-right"
            }
          />
          {apiLineBreaks.map((line, lineIndex) => {
            const formattedSpaces = formatSpaces(line);
            return (
              <motion.div className="line-break" key={lineIndex}>
                {formattedSpaces.map(
                  (word, wordIndex) =>
                    word && (
                      <motion.p
                        initial={{ opacity: 0 }}
                        animate={{
                          opacity: 1,
                          transition: {
                            duration: 0.02,
                            delay: wordIndex * 0.04,
                          },
                        }}
                        key={wordIndex}
                      >
                        {renderWord(word)}
                        &nbsp;
                      </motion.p>
                    )
                )}
              </motion.div>
            );
          })}
        </div>
      </TextBubble>
      <AnimatePresence>
        {form && isCpu && (
          <motion.div
            className="devis"
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.1 } }}
            exit={{
              opacity: 0,
              x: -100,
              transition: { duration: 0.1, delay: 0.1 },
            }}
          >
            <h3>{french ? "Devis" : "Quote"}</h3>
            <motion.div
              className="devis-container"
              initial={{ opacity: 0, x: -100 }}
              animate={{
                opacity: 1,
                x: 0,
                transition: { duration: 0.1, delay: 0.1 },
              }}
              exit={{ opacity: 0, x: -100, transition: { duration: 0.3 } }}
            >
              <div className="shadow" />
              <Form form={form} />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {form && !isCpu && (
          <motion.div
            className="form-icon"
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: -200 }}
            onClick={() => setFormButton(true)}
          >
            {" "}
            <AiOutlineForm className="icon" />
            <p>{french ? "Devis" : "Quote"}</p>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {formButton && !isCpu && (
          <motion.div
            className="form-phone-container"
            onClick={() => setFormButton(false)}
            initial={{ scale: 0 }}
            animate={{ scale: 1, transition: { duration: 0.15 } }}
            exit={{ scale: 0 }}
            style={{ transformOrigin: "15vw 70vh" }}
          >
            <div className="form-phone">
              <div onClick={(e) => e.stopPropagation()}>
                <Form />
              </div>
              <AiFillCloseCircle className="form-close" />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <motion.div
        className={form && isCpu ? "jules small" : "jules big"}
        layoutId="Jules"
        transition={{ duration: 0.2 }}
      >
        <Jules />
      </motion.div>
      <Prompt onSubmit={handleSubmit}>
        <div className="prompt-container">
          <TextArea
            ref={inputRef}
            type="text"
            value={prompt}
            className="prompt"
            rows={1}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyPress}
            tabIndex={0}
            placeholder={french ? "Demander à Jules..." : "Ask Jules..."}
          ></TextArea>
        </div>
        <button
          disabled={loading || prompt.length === 0}
          type="submit"
          onClick={messageApi}
        >
          {loading ? "..." : <AiOutlineArrowUp size={30} />}
        </button>
      </Prompt>
    </AiWrapper>
  );
};
export default Ai;

const AiWrapper = styled.div`
  font-family: "Josefin Sans", sans-serif;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  .jules {
    box-shadow: 0px 0px 30px 15px rgba(0, 0, 0, 0.4);
    position: absolute;
    border-radius: 9999px;
    overflow: hidden;
  }
  .jules.big {
    right: 5vw;
    top: 20vw;
    width: 360px;
    height: 360px;
  }
  .jules.small {
    right: 5vw;
    bottom: 25px;
    width: 160px;
    height: 160px;
  }
  @media (max-width: 992px) {
    padding-top: 20vh;
    height: 100vh;
    position: relative;
    overflow: hidden;
    .jules.big {
      top: unset;
      bottom: 25vh;
      right: 15vw;
      left: auto;
      width: 165px;
      height: 165px;
    }
  }
  .devis {
    position: fixed;
    width: 32vw;
    inset: 0 auto 0 0;
    background: var(--second-color);
    h3 {
      font-size: 2rem;
      position: absolute;
      inset: 45vh auto auto 3vw;
      font-weight: 400;
      letter-spacing: 0.1rem;
    }
    .devis-container {
      height: 60vh;
      width: 400px;
      overscroll-behavior: contain;
      overflow-y: scroll;
      border-radius: 1rem;
      box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.4);
      border: 1px solid black;
      position: absolute;
      inset: 20vh auto auto calc(12vw * 1.5);
      .shadow {
        position: sticky;
        top: 100%;
        height: 0%;
        width: 100%;
        box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.4);
      }
    }
  }
  .form-icon {
    position: absolute;
    bottom: 33vh;
    left: 5vw;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.4);
    background: var(--main-color);
    display: grid;
    place-items: center;
    @media (max-width: 992px) {
    }
    p {
      font-size: 1rem;
      position: absolute;
      bottom: -30px;
    }
    .icon {
      height: 30px;
      width: 30px;
      color: #393939;
    }
  }
  .form-phone-container {
    position: fixed;
    inset: 0;
    z-index: 15;
    backdrop-filter: blur(5px);
    overflow: scroll;
    overscroll-behavior: contain;
    padding-bottom: 20vh;
  }
  .form-phone {
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .form-close {
      color: var(--main-color);
      margin-top: 3vh;
      height: 50px;
      width: 50px;
    }
  }
`;

const TextBubble = styled(motion.div)`
  font-family: "Josefin Sans", sans-serif;
  font-weight: 300;
  max-width: min(90vw, 500px);
  min-height: 60px;
  max-height: 50vh;
  box-shadow: 3px 10px 20px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #fff;
  border: 2px solid #d6d6d6;
  border-radius: 0.4em;
  padding: 24px;
  top: 20vh;
  z-index: 5;
  @media (max-width: 992px) {
    position: absolute;
    top: unset;
    bottom: 53vh;
    left: auto;
    max-height: 35vh;
  }
  &.center {
    right: 480px;
    @media (max-width: 992px) {
      right: 5vw;
      left: auto;
    }
  }
  &.right {
    top: auto;
    right: 5vw;
    bottom: 35vh;
    max-width: 35vw;
  }
  .text-container {
    height: 100%;
    overflow: scroll;
    overscroll-behavior: contain;
  }

  .line-break {
    text-align: start;
    margin: 0px 8px;
    padding: 0px;
    p {
      display: inline-block;
      margin: 1px 0;
      @media (max-width: 992px) {
        font-size: 1rem;
      }
      a {
        color: var(--main-color);
      }
    }
  }
  .inner-bottom {
    right: 68px;
    bottom: -31px;
    border-left: 15px solid #d6d6d6;
    border-right: 15px solid transparent;
    border-top: 15px solid #d6d6d6;
    border-bottom: 15px solid transparent;
  }
  .outer-bottom {
    border-left: 13px solid #fff;
    border-right: 13px solid transparent;
    border-top: 13px solid #fff;
    border-bottom: 13px solid transparent;
    right: 70px;
    z-index: 6;
    bottom: -25px;
  }
  .inner-right {
    right: -30px;
    top: 50px;
    border-left: 15px solid #d6d6d6;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #d6d6d6;
    border-top: 15px solid transparent;
  }
  .outer-right {
    border-left: 13px solid #fff;
    border-right: 13px solid transparent;
    border-bottom: 13px solid #fff;
    border-top: 13px solid transparent;
    right: -25px;
    z-index: 1;
    top: 52px;
  }
  .arrow {
    display: inline-block;
    width: 0px;
    height: 0px;
    position: absolute;
  }
`;

const Prompt = styled.form`
  font-family: "Josefin Sans", sans-serif;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  position: relative;
  width: min(90vw, 500px);
  bottom: 25px;
  @media (max-width: 992px) {
    bottom: 10vh;
  }
  .prompt-container {
    background: white;
    border: 1px solid #000;
    box-shadow: 3px 10px 20px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  button {
    height: min(6vh, 40px);
    background: var(--main-color);
    color: #393939;
    border: none;
    position: absolute;
    inset: 8px 8px auto auto;
    border-radius: 5px;
    width: max(5vw, 50px);
    display: grid;
    place-items: center;
  }
`;

const TextArea = styled.textarea`
  font-family: "Josefin Sans", sans-serif;
  font-weight: 300;
  width: min(calc(90vw - 34px), 468px);
  max-height: 200px;
  height: 24px;
  margin: 16px;
  overflow-y: hidden;
  padding: 0 max(5vw, 50px) 0 0;
  background: transparent;
  resize: none;
  font-size: 16px;
  line-height: 24px;
  border: none;
  overflow: scroll;
  border-radius: 5px;

  &:focus {
    outline: none;
    border: none;
  }
`;
