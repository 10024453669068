import React, { useState } from "react";
import Form from "../components/Form";
import ServicesSection from "../components/ServicesSections";
import { servicesContent } from "../Data/ServiceContent";
import styled from "styled-components";
import { pageAnim, pageElementAnim } from "../Animation";
import {
  motion,
  useScroll,
  useTransform,
  AnimatePresence,
} from "framer-motion";
import { useStateContext } from "../useGlobalContext";
import { Link } from "react-router-dom";
import { Link as Scroll } from "react-scroll";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet-async";
import { AiOutlineForm, AiFillCloseCircle } from "react-icons/ai";
import ServiceWebDev from "../components/ServiceWebDev";

const Services = () => {
  const { french } = useStateContext();
  const [formButton, setFormButton] = useState(false);
  const [layout, setLayout] = useState(false);
  const serviceContent = french ? servicesContent.fr : servicesContent.en;
  const { scrollYProgress } = useScroll();

  const scaleX = useTransform(
    scrollYProgress,
    [0, 0.03, 0.062, 0.442, 0.68, 1],
    [0, 0, 0.25, 0.538, 0.668, 1]
  );
  const titleY = useTransform(scrollYProgress, [0, 0.2], [0, 50]);
  const textY = useTransform(scrollYProgress, [0, 0.2], [0, 100]);
  const isCpu = useMediaQuery({ minWidth: 992 });
  const subNav = french
    ? ["Développement web", "Graphisme", "Community Management"]
    : ["Web development", "Graphic Design", "Community Management"];

  return (
    <ServicesWrapper animate="show" variants={pageAnim}>
      <Helmet>
        <title>Services | JULES Agence Digitale</title>
        <meta
          name="description"
          content="Explorez nos services à JULES Agence Digitale à Sutton et dans les Cantons-de-l'Est, incluant le développement web, la gestion de communauté et le design graphique."
        />
        <link rel="canonical" href="https://www.julesdigital.com/services" />
      </Helmet>
      <motion.h1 style={{ y: titleY }}>Services</motion.h1>
      {french ? (
        <motion.p
          onViewportLeave={() => setLayout(true)}
          onViewportEnter={() => setLayout(false)}
          viewport={{ amount: 1 }}
          variants={pageElementAnim}
          className="intro-text"
          style={{ y: textY }}
        >
          Nous sommes passionnés par la création de designs uniques pour les
          entreprises et adorons travailler en étroite collaboration avec les
          entrepreneurs pour comprendre leurs besoins et leurs objectifs. Si
          vous recherchez un concepteur de sites web et/ou une graphiste pour
          créer un design personnalisé pour votre entreprise, n'hésitez pas à
          nous{" "}
          <Link id="contact" to="/contact">
            <span>contacter</span>
          </Link>{" "}
          pour discuter de vos besoins.
        </motion.p>
      ) : (
        <motion.p
          onViewportLeave={() => setLayout(true)}
          onViewportEnter={() => setLayout(false)}
          viewport={{ amount: 1 }}
          variants={pageElementAnim}
          className="intro-text"
          style={{ y: textY }}
        >
          We are passionate about the creation of unique designs for small
          companies and love working closely with entrepreneurs to understand
          their needs and objectives. If you're looking for a web designer
          and/or a graphic designer to create personnalized designs for your
          business, don't hesitate to{" "}
          <Link id="contact" to="/contact">
            <span>contact </span>
          </Link>
          us to discuss your needs.
        </motion.p>
      )}
      <motion.ul
        layout="position"
        className="sub-nav"
        style={{
          paddingLeft: !layout ? (isCpu ? 0 : "5vw") : isCpu ? "20vw" : "5vw",
        }}
      >
        <motion.div
          style={{
            width: "100vw",
            height: "5px",
            scaleX: isCpu ? scaleX : 0,
            transformOrigin: "left",
            position: "absolute",
            background: "var(--main-color)",
            top: "calc(7rem)",
            left: 0,
          }}
        />
        {subNav.map((item, index) => (
          <motion.li key={index} layout>
            <ScrollLink
              to={`${item.toLowerCase().replace(/\s+/g, "-")}`}
              className="sub-nav-link"
              activeClass="active"
              spy={true}
              duration={1500}
              offset={window.innerHeight * (isCpu ? 0.3 : -0.1)}
            >
              {item}
              <div className="highlight" />
            </ScrollLink>
          </motion.li>
        ))}
      </motion.ul>
      <div className="sections-container">
        <div className="sticky-form">
          <ServiceForm>
            <motion.div
              className="form-icon"
              onClick={() => setFormButton(true)}
            >
              {" "}
              <AiOutlineForm className="icon" />
              <p>{french ? "Devis" : "Quote"}</p>
            </motion.div>

            <AnimatePresence>
              {formButton && (
                <motion.div
                  className="form-phone-container"
                  onClick={() => setFormButton(false)}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, transition: { duration: 0.15 } }}
                  exit={{ scale: 0 }}
                  style={{ transformOrigin: "85vw 90vh" }}
                >
                  <div className="form-phone">
                    <div onClick={(e) => e.stopPropagation()}>
                      <Form />
                    </div>
                    <AiFillCloseCircle className="form-close" />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </ServiceForm>
        </div>

        <ServiceWebDev />
        {serviceContent.map((items, index) => (
          <motion.div
            key={index}
            className="sections-wrapper"
            id={`${subNav[index + 1].toLowerCase().replace(/\s+/g, "-")}`}
          >
            <div className="sections">
              <h2>{subNav[index + 1]}</h2>
              <ServicesSection items={items} />
            </div>
          </motion.div>
        ))}
      </div>
    </ServicesWrapper>
  );
};

export default Services;

const ServicesWrapper = styled(motion.section)`
  width: 100vw;
  height: 200vh;
  background: transparent;
  height: fit-content;
  margin-top: 10vh;
  padding: 15vh 0vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 992px) {
    padding-top: 10vh;
  }
  #contact {
    text-decoration: underline;
    color: var(--fifth-color);
  }
  h1 {
    font-size: var(--large);
    color: var(--fifth-color);
    text-transform: uppercase;
    text-align: center;
    width: 100vw;
  }
  .intro-text {
    width: 60vw;
    padding-bottom: 15vh;
    font-size: var(--small);
    @media (max-width: 992px) {
      width: 90vw;
      font-size: 1rem;
      margin-bottom: 0;
      padding-bottom: 5vh;
    }
  }
  .sub-nav {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    place-items: center;
    background: #f7f7f7;
    padding: 2vh 5vw;
    padding-top: 6rem;
    list-style: none;
    position: sticky;
    z-index: 20;
    top: -2vh;

    li {
      line-height: 16px;
      height: 2.5rem;
      width: 30vw;
      display: flex;
      justify-content: center;
    }
    @media (max-width: 992px) {
      padding-top: 3.5rem;
      padding-left: 5vw;
      top: 4vh;
      margin-bottom: 25vh;
      li {
        width: fit-content;
      }
    }
  }
  .sections-container {
    width: 100vw;
    min-height: 100vh;
    .sticky-form {
      position: sticky;
      top: 65vh;
      margin-right: 2.5vw;
      z-index: 30;

      @media (max-width: 992px) {
        top: 78vh;
      }
    }
  }
  .sections-wrapper {
    .sections {
      padding: 10vh 0;
      min-height: 100vh;
      height: fit-content;
      background: #f7f7f7;

      &:last-child {
        padding-bottom: 0;
      }

      h2 {
        z-index: 15;
        width: 40vw;
        padding: 5vh 5vw;
        padding-top: 12vh;
        color: var(--fifth-color);
        font-size: var(--medium);
        margin-bottom: var(--medium);
        background: #f7f7f7;
        z-index: 1;
        position: sticky;
        top: 8rem;
      }
      @media (max-width: 992px) {
        width: 100vw;
        position: static;
        h2 {
          top: 16vh;
          padding-bottom: 2vh;
          width: 100vw;
          position: static;
          text-align: center;
        }
      }

      h3 {
        color: var(--second-text-color);
        font-size: var(--small);
        margin-bottom: var(--small);
      }
    }
  }
`;

const ServiceForm = styled(motion.div)`
  position: absolute;
  top: 20vh;
  right: 0;
  z-index: 40;
  @media (max-width: 992px) {
    top: 5vh;
    right: 0;
  }
  .form-icon {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: outline 0.15s ease-in-out;
    :hover {
      outline: 3px solid var(--main-color);
      outline-offset: 3px;
      cursor: pointer;
    }
    p {
      font-size: 1rem;
      position: absolute;
      bottom: -30px;
    }
    .icon {
      height: 30px;
      width: 30px;
      color: #393939;
    }
  }
  .form-phone-container {
    position: fixed;
    inset: 0;
    z-index: 40;
    backdrop-filter: blur(5px);
    overflow: scroll;
    overscroll-behavior: contain;
    padding-bottom: 20vh;
  }
  .form-phone {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 992px) {
      margin-top: 15vh;
    }
    .form-close {
      color: var(--main-color);
      margin-top: 3vh;
      height: 50px;
      width: 50px;
    }
  }
`;

const ScrollLink = styled(Scroll)`
  position: relative;
  color: var(--second-text-color);
  width: fit-content;
  height: 100%;
  padding: 0 20px;
  background: #f7f7f7;
  border-radius: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 992px) {
    font-size: 0.8rem;
    padding: 0 10px;
    text-align: center;
    width: 29vw;
  }
  .highlight {
    position: absolute;
    height: calc(100% + 10px);
    width: calc(100% + 10px);
    background: var(--main-color);
    top: -5px;
    left: -5px;
    z-index: -1;
    border-radius: 5rem;
    opacity: 0.2;
    transition: opacity 0.15s ease-out;
    @media (max-width: 992px) {
      height: calc(100% + 3px);
      width: calc(100% + 3px);
      top: -1.5px;
      left: -1.5px;
    }
  }
  &.active .highlight,
  &.sub-nav-link:hover&.active .highlight {
    opacity: 1;
  }
  &.sub-nav-link:hover .highlight {
    opacity: 0.5;
  }
`;
