import React from "react";
import { useFormik } from "formik";
import styled from "styled-components";
import { BsCheck2Circle } from "react-icons/bs";
import * as emailjs from "emailjs-com";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useStateContext } from "../useGlobalContext";

const Form = ({ form }) => {
  const { french } = useStateContext();
  const formik = useFormik({
    initialValues: {
      user_name: "",
      user_last_name: "",
      user_phone: "",
      user_email: "",
      user_budget: 0,
      user_message: "",
    },
    // Validation
    validationSchema: Yup.object({
      user_name: Yup.string().required(
        french ? "Champ obligatoire" : "Required field"
      ),
      user_email: Yup.string()
        .email(french ? "Courriel invalide" : "Invalid email")
        .required(french ? "Champ obligatoire" : "Required field"),
      user_budget: Yup.string()
        .required(french ? "Champ obligatoire" : "Required field")
        .oneOf(
          ["350€-700€", "700€-1400€", "1400€-2100€", "2100€-3500€", "3500€+"],
          french ? "Option invalide" : "Invalid option"
        ),
      user_message: Yup.string()
        .required(french ? "Champ obligatoire" : "Required field")
        .min(
          25,
          french
            ? "Ce champ doit contenir un minimum de 25 caractères"
            : "This filed requires a minimum of 25 characters."
        ),
    }),
    onSubmit: (values) => {
      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          values,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          () => {
            Swal.fire({
              icon: "success",
              title: french
                ? `Merci d'avoir pris le temps de remplir le formulaire!

                Nous vous contacterons sous peu pour vous faire part du devis.`
                : `Thank you for taking the time to fill the form. 
                
                We will be in contact with you shortly with an estimate.`,
              confirmButtonColor: "#C38D5E",
              confirmButtonText: french ? `Fermer` : `Close`,
            });
            formik.resetForm();
          },
          (error) => {
            console.log(error.text);
            Swal.fire({
              icon: "error",
              title: french
                ? "Oops! Une erreur s'est produite. Veuillez recommencer."
                : "Oops! An error has occured. Please try again.",
            });
            formik.resetForm();
          }
        );
    },
  });
  return (
    <FormWrapper className={form && "form"}>
      <FormStyle onSubmit={formik.handleSubmit} className={form && "home"}>
        {/* --- Name --- */}

        <div
          className={
            formik.touched.user_name && formik.errors.user_name
              ? "bad-labels name"
              : formik.touched.user_name
              ? "good-labels name"
              : "untouched-labels name"
          }
        >
          <label htmlFor="user_name">
            {french ? "Prénom" : "First Name"}{" "}
            {formik.touched.user_name && !formik.errors.user_name ? (
              <BsCheck2Circle size={20} />
            ) : (
              "*"
            )}
          </label>
          <input
            id="user_name"
            name="user_name"
            type="text"
            placeholder={french ? "Prénom..." : "First Name"}
            {...formik.getFieldProps("user_name")}
          />
          <p>
            {formik.errors.user_name}
            <span>|</span>
          </p>
        </div>

        {/* --- Last Name --- */}

        <div className="untouched-labels last-name">
          <label htmlFor="user_last_name">{french ? "Nom" : "Last Name"}</label>
          <input
            id="user_last_name"
            name="user_last_name"
            type="text"
            placeholder={french ? "Nom..." : "Last Name..."}
            {...formik.getFieldProps("user_last_name")}
          />
          <p>
            <span>|</span>
          </p>
        </div>

        {/* --- Email --- */}

        <div
          className={
            formik.touched.user_email && formik.errors.user_email
              ? "bad-labels email"
              : formik.touched.user_email
              ? "good-labels email"
              : "untouched-labels email"
          }
        >
          <label htmlFor="user_email">
            {french ? "Courriel" : "Email"}{" "}
            {formik.touched.user_email && !formik.errors.user_email ? (
              <BsCheck2Circle size={20} />
            ) : (
              "*"
            )}
          </label>
          <input
            id="user_email"
            name="user_email"
            type="email"
            placeholder={french ? "Courriel..." : "Email..."}
            {...formik.getFieldProps("user_email")}
          />
          <p>
            {formik.errors.user_email}
            <span>|</span>
          </p>
        </div>

        {/* --- Phone --- */}

        <div className="untouched-labels phone">
          <label htmlFor="user_phone">{french ? "Téléphone" : "Phone"}</label>
          <input
            id="user_phone"
            name="user_phone"
            type="text"
            placeholder={french ? "Téléphone..." : "Phone..."}
            {...formik.getFieldProps("user_phone")}
          />
          <p>
            <span>|</span>
          </p>
        </div>

        {/* --- budget --- */}

        <div
          className={
            formik.touched.user_budget && formik.errors.user_budget
              ? "bad-labels budget"
              : formik.touched.user_budget
              ? "good-labels budget"
              : "untouched-labels budget"
          }
        >
          <label htmlFor="user_budget">
            Budget
            {formik.touched.user_budget && !formik.errors.user_budget ? (
              <BsCheck2Circle size={20} />
            ) : (
              "*"
            )}
          </label>
          <select
            name="user_budget"
            id="user_budget"
            {...formik.getFieldProps("user_budget")}
          >
            <option value={null}>
              {french ? "-- Sélectionner --" : "-- Select --"}
            </option>
            <option value={"700€-1400€"}>1000-2000$</option>
            <option value={"1400€-2100€"}>2000-5000$</option>
            <option value={"2100€-3500€"}>5000$-9999$</option>
            <option value={"3500€+"}>10000$+</option>
          </select>
          <p>
            {formik.errors.user_budget}
            <span>|</span>
          </p>
        </div>

        {/* --- Comments --- */}

        <div
          className={
            formik.touched.user_message && formik.errors.user_message
              ? "bad-labels comments"
              : formik.touched.user_message
              ? "good-labels comments"
              : "untouched-labels comments"
          }
        >
          <label htmlFor="user_message">
            {french
              ? "Description de votre projet"
              : "Description of your project"}{" "}
            {formik.touched.user_message && !formik.errors.user_message ? (
              <BsCheck2Circle size={20} />
            ) : (
              "*"
            )}
          </label>
          <textarea
            id="user_message"
            type="text"
            name="user_message"
            placeholder="Description..."
            {...formik.getFieldProps("user_message")}
          />
          <p>
            {formik.errors.user_message}
            <span>|</span>
          </p>
        </div>

        {/* --- Button --- */}

        <button
          type="submit"
          disabled={!formik.isValid}
          className={formik.isSubmitting ? "loading formButton" : "formButton"}
        >
          {formik.isSubmitting ? (
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : french ? (
            "Envoyer"
          ) : (
            "Send"
          )}
        </button>
      </FormStyle>
    </FormWrapper>
  );
};

export default Form;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scale: 0.7;
  @media (max-width: 992px) {
    scale: 1;
  }
  &.form {
    scale: 1;
  }
`;

const FormStyle = styled.form`
  background: var(--main-color);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 1vw 3vw;
  grid-template-areas:
    "name lastName"
    "email email"
    "budget phone"
    "comments comments"
    "button button";

  border: 1px solid #374151;
  border-radius: 1rem;
  font-size: calc(16px + 0.4vw);
  width: 35vw;
  padding: 2vw;
  height: fit-content;
  &.home {
    width: 400px;
    font-size: 16px;
    grid-gap: 8px;
    padding: 16px;
    border: none;
  }
  @media (max-width: 992px) {
    width: min(90vw, 500px);
    grid-gap: 8px;
  }
  p {
    width: fit-content;
    font-size: 1rem;
    padding: 0;
    span {
      color: transparent;
    }
    @media (max-width: 992px) {
      font-size: 0.6rem;
    }
  }
  div {
    display: grid;
    margin-bottom: 1vh;
  }
  .name {
    grid-area: name;
  }
  .last-name {
    grid-area: lastName;
  }
  .phone {
    grid-area: phone;
  }
  .email {
    grid-area: email;
    input {
      width: 100%;
    }
  }
  .budget {
    grid-area: budget;
    select {
      padding: 8px;
      font-size: 1rem;
      border-radius: 0.5rem;
    }
  }
  .comments {
    grid-area: comments;
    textarea {
      font-family: "League Spartan", serif;
      resize: none;
    }

    p {
      top: 12rem;
    }
  }

  .noError {
    opacity: 0;
  }
  input,
  textarea,
  select {
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    font-size: calc(14px + 0.4vw);
  }
  #user_message {
    height: 10rem;
    margin-bottom: 0;
  }
  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
    width: 100%;
  }
  select {
    border-radius: 1px solid #374151;
    margin-top: 0.2rem;
    padding: 0.5rem;
    option {
      padding: 0.5rem;

      font-size: calc(14px + 0.4vw);
    }
  }

  .untouched-labels {
    width: 100%;
    :focus {
      border: solid 1px var(--fourth-color);
    }
    p {
      color: transparent;
    }
    input,
    textarea {
      border: 1px solid #bfbfbf;
    }
  }
  .good-labels {
    width: 100%;
    label {
      color: var(--fifth-color);
    }
    p {
      color: transparent;
    }
    input,
    textarea {
      border: 1px solid var(--fifth-color);
    }
  }
  .bad-labels {
    width: 100%;
    label {
      color: #bb3237;
      font-weight: bold;
    }
    p {
      color: #bb3237;
      font-weight: 400;
    }
    input,
    textarea {
      border: 2px solid #bb3237;
    }
  }

  .formButton {
    grid-area: button;
    height: fit-content;
    width: 15rem;
    color: var(--text-color);
    background-color: var(--second-color);
    padding: 2vh 0;
    margin: 5vh auto;
    border-radius: 2rem;
    border: 1px solid var(--text-color);
    font-size: 1.3rem;
    display: grid;
    place-items: center;
    :disabled {
      color: gray;
      background-color: lightgray;
      opacity: 0.7;
      cursor: not-allowed;
    }
    :enabled {
      :hover {
        background-color: var(--main-color);
        color: white;
        transition: 0.2s;
        cursor: pointer;
      }
    }
    .lds-ring {
      display: inline-block;
      position: relative;
      width: 2.5vh;
      height: 2.5vh;
    }
    .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 2.5vh;
      height: 2.5vh;
      border: 2px solid #fff;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }
    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .loading {
    background-color: var(--main-color);
    grid-area: button;
  }
`;
