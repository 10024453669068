import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

const Carousel = ({ data }) => {
  // State and Ref initialization
  const [currentImg, setCurrentImg] = useState(0);
  const [carouselSize, setCarouselSize] = useState({ width: 0, height: 0 });
  const carouselRef = useRef(null);

  // useEffect to get the initial carousel size
  useEffect(() => {
    let elem = carouselRef.current;
    let { width, height } = elem.getBoundingClientRect();
    if (carouselRef.current) {
      setCarouselSize({
        width,
        height,
      });
    }
  }, []);

  return (
    <CarouselWrapper>
      <CarouselContainer>
        <ImageContainer
          ref={carouselRef}
          currentImg={currentImg}
          carouselSize={carouselSize}
        >
          {data.map((v, i) => (
            <div key={i} className="relative shrink-0 w-full h-full">
              <img
                className="pointer-events-none"
                alt={`carousel-image-${i}`}
                fill
                src={v.image || "https://random.imagecdn.app/500/500"}
              />
            </div>
          ))}
        </ImageContainer>
      </CarouselContainer>

      {/* Navigation buttons */}
      <NavigationContainer>
        <NavigationButton
          disabled={currentImg === 0}
          onClick={() => setCurrentImg((prev) => prev - 1)}
        >
          {"<"}
        </NavigationButton>
        <NavigationButton
          disabled={currentImg === data.length - 1}
          onClick={() => setCurrentImg((prev) => prev + 1)}
        >
          {">"}
        </NavigationButton>
      </NavigationContainer>
    </CarouselWrapper>
  );
};

export default Carousel;

const CarouselWrapper = styled.div`
  width: 50vw;
  height: 50vh;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  @media (max-width: 992px) {
    width: 100vw;
    min-height: 40vh;
    box-shadow: none;
  }
`;

const CarouselContainer = styled.div`
  width: 50vw; /* Equivalent to w-80 */
  height: 50vh; /* Equivalent to h-60 */
  overflow: hidden;
  position: relative;
  @media (max-width: 992px) {
    width: 100vw;
    height: 40vh;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  transition: all 0.3s ease;
  left: ${(props) => -props.currentImg * props.carouselSize.width}px;
  div {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem; /* Equivalent to mt-3 */
`;

const NavigationButton = styled.button`
  border: 1px solid;
  padding: 0.5rem 1rem; /* Equivalent to px-4 py-2 */
  width: fit-content;
  height: fit-content;
  background: #2a2a2a;
  font-weight: bold;
  cursor: pointer;
  :hover {
    background: #1a1a1a;
    color: white;
  }
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;
