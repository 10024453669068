import React, { createContext, useContext, useState } from "react";
const GlobalContext = createContext();

export const StateContext = ({ children }) => {
  const [french, setFrench] = useState(false);
  const [anim, setAnim] = useState(false);
  const [talk, setTalk] = useState(false);
  const [flowerAnim, setFlowerAnim] = useState(false);
  const [apiResponse, setApiResponse] = useState(``);
  const [form, setForm] = useState(false);
  const [menu, setMenu] = useState(false);
  const languageToggle = () => {
    setFrench(!french);
  };
  const talkToggle = () => {
    setTalk(!talk);
  };
  return (
    <GlobalContext.Provider
      value={{
        french,
        setFrench,
        languageToggle,
        anim,
        setAnim,
        talk,
        setTalk,
        talkToggle,
        flowerAnim,
        setFlowerAnim,
        apiResponse,
        setApiResponse,
        form,
        setForm,
        menu,
        setMenu,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useStateContext = () => useContext(GlobalContext);
