import React, { useState } from "react";
import { TbSettings, TbSettingsCancel } from "react-icons/tb";
import styled from "styled-components";

const AdminImageEdit = () => {
  const [imageUrl, setImageUrl] = useState(
    "https://scontent-yyz1-1.xx.fbcdn.net/v/t39.30808-6/432778870_793423986150003_8323173987164016262_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=kaFa_pv9-8QQ7kNvgEk5Y4v&_nc_ht=scontent-yyz1-1.xx&cb_e2o_trans=t&oh=00_AYBnuo8nQowJBeiVrklkU_CePK9RvefHSSo8nFwDR2NOsg&oe=667426BB"
  );
  const [editable, setEditable] = useState(false);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImageUrl(reader.result);
      setEditable(false);
    };
  };

  return editable ? (
    <Section>
      <img
        src={imageUrl ?? "https://placehold.co/600x400"}
        alt="Beautiful landscape photo"
      />
      <button className="admin" onClick={() => setEditable(false)}>
        <TbSettingsCancel className="" />
      </button>
      <div className="file-upload-container">
        <input type="file" id="file-upload" onChange={handleImageUpload} />
        <label htmlFor="file-upload" className="file-upload-trigger">
          Choisir une image
        </label>
      </div>
    </Section>
  ) : (
    <Section>
      <img src={imageUrl ?? "https://placehold.co/600x400"} alt="Test photo" />

      <button className="admin" onClick={() => setEditable(true)}>
        <TbSettings />
      </button>
    </Section>
  );
};

export default AdminImageEdit;

const Section = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #f7f7f7;
  img {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    width: 50vw;
    object-fit: cover;
    border-radius: 8px;
  }
  .file-upload-container {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }

  .file-upload-container input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }

  .file-upload-trigger {
    display: inline-block;
    padding: 10px 20px;
    background: var(--main-color);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .file-upload-trigger:hover {
    background: #a97a51;
  }
  .admin {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: #f7f7f7;
    padding: 8px;
    width: 40px;
    height: 40px;
    color: black;
  }
  @media (max-width: 992px) {
    img {
      width: 90vw;
    }
  }
`;
