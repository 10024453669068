import React, { useState } from "react";
import Adminquill from "./AdminQuill";
import styled from "styled-components";
import { TbSettingsCancel, TbSettings } from "react-icons/tb";
import parse from "html-react-parser";

const AdminTextEdit = ({ newContent }) => {
  const [editable, setEditable] = useState(false);
  const [initialContent, setInitialContent] = useState(
    newContent ?? "<p><-- Ajouter du contenu ici --></p>"
  );
  const [content, setContent] = useState(
    newContent ?? "<p><-- Ajouter du contenu ici --></p>"
  );

  return editable ? (
    <Section>
      <div className="container">
        <Adminquill value={content} setContent={setContent} />
        <button
          className="admin"
          onClick={() => {
            setContent(initialContent);
            setEditable(false);
          }}
        >
          <TbSettingsCancel className="text-3xl mt-2 ml-2" />
        </button>
      </div>

      <button
        className="confirm"
        onClick={() => {
          setInitialContent(content);
          setEditable(false);
        }}
      >
        Confirmer
      </button>
    </Section>
  ) : (
    <Section>
      <div className="container">
        {parse(content)}
        <button className="admin" onClick={() => setEditable(true)}>
          <TbSettings />
        </button>
      </div>
    </Section>
  );
};

export default AdminTextEdit;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: fit-content;
  height: fit-content;
  position: sticky;
  top: 44vh;
  .container {
    display: flex;
    flex-direction: column;
    height: fit-content;
    position: relative;
    width: fit-content;
    max-width: 50rem;
    margin-bottom: 0;
    p {
      width: 30vw;
      height: fit-content;
      padding-top: 3vh;
      color: rgb(57, 57, 57);
      font-size: var(--small);
      margin-bottom: var(--small);
      background: #f7f7f7;
      z-index: 1;
      flex-shrink: 0;
    }
  }

  img {
    width: 100%;
    object-fit: cover;
  }
  .admin {
    position: absolute;
    top: 100%;
    left: 90%;
    padding-bottom: 1rem;
    margin-left: auto;
    background: #f7f7f7;
    padding: 8px;
    width: 40px;
    height: 40px;
    color: black;
  }
  .confirm {
    margin: 0 auto;
    margin-top: 20%;
    min-width: fit-content;
    font-size: 1rem;
    width: fit-content;
    height: fit-content;
    padding: 16px;
    border-radius: 8px;
    background: var(--main-color);
    :hover {
      color: white;
      background: #b68458;
    }
  }
  @media (max-width: 992px) {
    position: static;
    .container {
      p {
        width: 90vw;
      }
    }
  }
`;
