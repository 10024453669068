import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useStateContext } from "../useGlobalContext";

const Footer = () => {
  const { pathname } = useLocation();
  const { french } = useStateContext();
  return (
    <FooterWrapper
      style={{
        display: pathname === "/business-card" ? "none" : "flex",
      }}
    >
      <p>Jules Agence Digitale ® {new Date().getFullYear()} </p>
      <p>
        <Link to="/legal">{french ? "Mentions légales" : "Legal Notice"}</Link>
      </p>
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled.footer`
  position: fixed;
  bottom: 0;
  height: 3vh;
  padding: 0 1vw;
  width: 100vw;
  z-index: 10;
  background: transparent;

  flex-direction: row;
  @media (max-width: 992px) {
    position: unset;
    height: 3vh;
  }
  p {
    font-size: 0.8rem;
    color: gray;
    padding-right: 2vw;
    @media (max-width: 992px) {
      font-size: 0.6rem;
      text-align: center;
    }
  }
`;
