import React, { useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { useStateContext } from "../useGlobalContext";
import { motion, AnimatePresence } from "framer-motion";

const PhoneNav = () => {
  const { pathname } = useLocation();
  const { french, setFrench, menu, setMenu } = useStateContext();
  const menuItems = [
    {
      path: "/",
      name: french ? "Accueil" : "Home",
    },
    {
      path: "/about",
      name: french ? "À propos" : "About",
    },
    {
      path: "/projects",
      name: "Portfolio",
    },
    {
      path: "/services",
      name: "Services",
    },
    {
      path: "/contact",
      name: "Contact",
    },
  ];
  const menuHandler = () => {
    setMenu(!menu);
  };
  const openMenu = {
    hidden: { scale: 0 },
    show: {
      scale: 1,
      transition: {
        duration: 0.3,
        staggerChildren: 0.1,
        when: "beforeChildren",
      },
    },
    leave: {
      scale: 0,
      transition: {
        duration: 0.2,
      },
    },
  };
  const listOpen = {
    hidden: { y: -30, opacity: 0 },
    show: { y: 0, opacity: 1, transition: { duration: 0.2 } },
    leave: { opacity: 0, transition: { duration: 0.1 } },
  };

  useEffect(() => {
    setMenu(false);
  }, [pathname, setMenu]);
  return (
    <NavWrapper style={{ touchAction: menu ? "none" : "auto" }}>
      <AnimatePresence>
        {menu && (
          <MenuWrapper
            onClick={() => setMenu(false)}
            initial={{ opacity: 0 }}
            animate={{
              backdropFilter: "blur(10px)",
              opacity: 1,
              transition: { duration: 0.2 },
            }}
            exit={{ opacity: 0 }}
          >
            <Menu
              initial="hidden"
              animate="show"
              exit="leave"
              variants={openMenu}
              style={{ transformOrigin: "95vw 5vh" }}
            >
              <motion.li id="language" variants={listOpen}>
                <h2
                  onClick={() => setFrench(true)}
                  className={french ? `selected` : ""}
                >
                  FR
                </h2>
                <h2 className="line">|</h2>
                <h2
                  onClick={() => setFrench(false)}
                  className={french ? `` : "selected"}
                >
                  EN
                </h2>
              </motion.li>
              {menuItems.map((item, index) => (
                <motion.li
                  key={index}
                  variants={listOpen}
                  className={index === 4 ? "last" : ""}
                >
                  <Link to={item.path}>{item.name}</Link>
                </motion.li>
              ))}
            </Menu>
          </MenuWrapper>
        )}
      </AnimatePresence>

      <motion.div className="mainMenu" onClick={() => menuHandler()}>
        <HiOutlineMenuAlt3
          size={40}
          color="#5B412F"
          style={{
            opacity: menu ? "0" : "1",
            transition: "0.1",
            position: "absolute",
            top: "2vh",
            right: "5vw",
          }}
        />
        <IoMdClose
          size={40}
          color="#5B412F"
          style={{
            opacity: menu ? "1" : "0",
            transition: "0.1",
            position: "absolute",
            top: "2vh",
            right: "5vw",
          }}
        />
      </motion.div>
    </NavWrapper>
  );
};

export default PhoneNav;

const NavWrapper = styled.div`
  z-index: 50;
  color: var(--second-text-color);
  .mainMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-color);
    width: 20vw;
    cursor: pointer;
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
  }
`;

const Menu = styled(motion.ul)`
  position: absolute;
  inset: 0 0 10vh 10vw;
  border-bottom-left-radius: 0.5rem;
  box-shadow: 0px 0px 32px black;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  #language {
    position: absolute;
    padding-bottom: 0;
    top: 5vh;
    left: 5vw;
    h2 {
      font-size: 1.5rem;
      font-weight: 400;
    }
    display: flex;
    align-items: end;
    justify-content: start;
    .selected {
      color: var(--main-color);
    }
  }
  li {
    list-style: none;
    font-size: 2rem;
    padding-bottom: 10vh;
  }
  .last {
    border-bottom: none;
    padding-bottom: 5vh;
  }
`;
const MenuWrapper = styled(motion.div)`
  position: absolute;
  inset: 0;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(5px);
`;
